import React, { useState } from "react";
import { Modal, Box, Typography, Stack, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { leaveActions } from "../../store";
import { fetchOthersLeave, deleteUnpaid } from "../../services/leaveServices";
import { useNavigate } from "react-router-dom";

const DeleteUnpaidModal = ({ style }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [notes, setNotes] = useState('');

    const deleteUnpaidModal = useSelector((state) => state.leave.deleteUnpaidModal);
    const unpaidToDelete = useSelector((state) => state.leave.unpaidToDelete);


    const resetData = () => {
        setNotes('');
    }

    const unpaidDeleteHandler = async () => {
        try {
            const data = {
                leave_id: unpaidToDelete,
                notes: notes,
            }
            dispatch(leaveActions.setLoader({ value: true }));
            const response = await deleteUnpaid(data);
            const othersLeave = await fetchOthersLeave();
            dispatch(leaveActions.setOthersLeave({ othersLeave: othersLeave.data.allLeaves }));
            dispatch(leaveActions.setFilteredOthersLeave({ filteredOthersLeave: othersLeave?.data?.allLeaves.filter((leave) => leave.status === "pending") }));
            dispatch(leaveActions.setLoader({ value: false }));
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'success', message: response?.data?.msg || 'unpaid days deleted !!' } }));
            dispatch(leaveActions.setdeleteUnpaidModalClose());
            resetData();
        }
        catch (error) {
            dispatch(leaveActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={deleteUnpaidModal}
            onClose={() => {
                dispatch(leaveActions.setdeleteUnpaidModalClose());
                resetData();
            }}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Delete unpaid days</Typography>

                    <TextField
                        FormHelperTextProps={{ sx: { display: 'flex', justifyContent: 'flex-end' } }}
                        helperText={notes.length < 5 && notes.length > 0 ? 'min 5 and max 500 chars' : `${notes.length}/500`}
                        error={notes.length < 5 && notes.length > 0}
                        id="notes"
                        label="Notes"
                        variant="outlined"
                        value={notes}
                        required
                        multiline
                        rows={4}
                        onChange={(e) => {
                            if (e.target.value.length <= 500) {
                                setNotes(e.target.value);
                            }
                        }}
                    />

                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button
                            onClick={() => {
                                dispatch(leaveActions.setdeleteUnpaidModalClose());
                                resetData();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button disabled={notes.length < 5} onClick={unpaidDeleteHandler}>Yes, Delete</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default DeleteUnpaidModal;