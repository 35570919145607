import { Stack, Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import EmptyPage from '../EmptyPage';
import Dropdowns from "./Dropdowns";
import ContributorLoader from './ContributorLoader';
import { fetchAllContributors } from '../../services/contributorDetailServices';
import { fetchAllProjects } from '../../services/projectSummaryServices'
import { contributorDetailActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import ContributorDetailsTable from "./ContributorDetailsTable";
import { useTheme } from "@emotion/react";
import FilterTasksModal from "./FilterTasksModal";
import { useNavigate } from "react-router-dom";

function ContributorDetails() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const contributorDataFetchedRef = useRef(false);
    const contributorData = useSelector((state) => state.contributorDetail.contributorData);
    const selectedComparedTo = useSelector((state) => state.contributorDetail.selectedComparedTo);
    const selectedSnapshotDate = useSelector((state) => state.contributorDetail.selectedSnapshotDate);

    const fetchContributors_fetchProjects = async () => {
        try {
            dispatch(contributorDetailActions.setLoader({ value: true }));
            const response = await fetchAllContributors();
            dispatch(contributorDetailActions.setContributors({ contributors: response.data.contributors }));
            const response1 = await fetchAllProjects();
            dispatch(contributorDetailActions.setProjects({ projects: response1.data.projects }));
            dispatch(contributorDetailActions.setLoader({ value: false }));
            dispatch(contributorDetailActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data fetched !!!' } }));
        }
        catch (error) {
            dispatch(contributorDetailActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(contributorDetailActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (contributorDataFetchedRef.current) return;
        contributorDataFetchedRef.current = true;
        dispatch(contributorDetailActions.resetAllData());
        dispatch(contributorDetailActions.resetFilters());
        fetchContributors_fetchProjects();
    });

    return (
        <Stack rowGap={2}>
            <Dropdowns />
            < Stack alignItems='center'>
                {(!selectedComparedTo) && <EmptyPage text="Select a contributor and project to view details" />}
                {(contributorData.length === 0 && selectedSnapshotDate && selectedComparedTo) && <EmptyPage text="No Data" />}
            </Stack>

            {contributorData.length != 0 && selectedSnapshotDate && selectedComparedTo &&
                <Box sx={{
                    backgroundColor: theme.palette.surface1.main, color: theme.palette.surface1.contrastText, minheight: '70vh', width: '100%', borderRadius: '10px',
                    padding: 2
                }}>
                    <ContributorDetailsTable />
                </Box>
            }
            <FilterTasksModal />
            <ContributorLoader />
        </Stack >
    );
}

export default ContributorDetails;