import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Stack, Button, TextField, MenuItem, Chip } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { collectionsActions } from "../../store";
import { editProject, fetchCollectionsData } from "../../services/collectionServices";
import { useNavigate } from "react-router-dom";

const EditProjectModal = () => {
    const navigate = useNavigate();
    const editProjectModal = useSelector((state) => state.collections.editProjectModal);
    const selectedProjectId = useSelector((state) => state.collections.projectsMenuSelectedProjectId);
    const collections = useSelector((state) => state.collections.collections);
    const [projectName, setProjectName] = useState('');
    const [ganttUrl, setGanttUrl] = useState('');
    const [repo, setRepo] = useState('');
    const [selectedCollection, setSelectedCollection] = useState('');
    const [duration, setDuration] = useState('');
    const [taskInter, setTaskInter] = useState('');
    const [techDiff, setTechDiff] = useState('');
    const [saveButton, setSaveButton] = useState(false);
    const [fullname, setFullname] = useState('');
    const collectionOptions = collections.filter((collection) => collection.collection_id !== 1);

    useEffect(() => {
        let projectToEdit = null;
        collections.forEach((collection) => {
            collection.projects.forEach((project) => {
                if (project.project_id === selectedProjectId) {
                    projectToEdit = project;
                }
            });
        });
        if (projectToEdit) {
            setProjectName(projectToEdit?.project_name);
            setSelectedCollection(projectToEdit?.collection_id === 1 ? '' : projectToEdit?.collection_id);
            setDuration(projectToEdit?.duration);
            setGanttUrl(projectToEdit?.gantt_url);
            setRepo(projectToEdit?.github_url);
            setTaskInter(projectToEdit?.task_interdependence.charAt(0).toUpperCase() + projectToEdit?.task_interdependence.slice(1));
            setTechDiff(projectToEdit?.technical_difficulty.charAt(0).toUpperCase() + projectToEdit?.technical_difficulty.slice(1));
            setSaveButton(false);
            setFullname(projectToEdit?.project_manager);
        }
    }, [editProjectModal]);

    const dispatch = useDispatch();
    const theme = useTheme();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
    };

    const findValue = (value) => {
        switch (value) {
            case 'Low': return 1;
            case 'Medium': return 2;
            case 'High': return 3;
            default: return 0;
        }
    }

    const editProjectHandler = async () => {
        let data = {
            collection_id: selectedCollection || 1,
            duration: duration,
            gantt_url: ganttUrl,
            github_url: repo,
            project_id: selectedProjectId,
            project_name: projectName,
            task_inter: taskInter,
            tech_diff: techDiff
        }
        console.log(data);
        try {
            dispatch(collectionsActions.setLoader({ value: true }));
            await editProject(data);
            const response = await fetchCollectionsData();
            dispatch(collectionsActions.setCollections({ collections: response.data.collections }));
            dispatch(collectionsActions.setLoader({ value: false }));
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'success', message: 'Project updated successfully !!!' } }));
            dispatch(collectionsActions.setEditProjectModalClose());
        }
        catch (error) {
            dispatch(collectionsActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={editProjectModal}
            onClose={() => dispatch(collectionsActions.setEditProjectModalClose())}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Edit Project</Typography>
                    <TextField
                        FormHelperTextProps={{ sx: { display: 'flex', justifyContent: 'flex-end' } }}
                        helperText={projectName.length < 3 && projectName.length > 0 ? 'min 3 and max 50 chars' : `${projectName.length}/50`}
                        error={projectName.length < 3 && projectName.length > 0}
                        id="project-name"
                        label="Project Name"
                        variant="outlined"
                        required
                        value={projectName}
                        onChange={(e) => {
                            if (e.target.value.length <= 50) {
                                setProjectName(e.target.value);
                                setSaveButton((e.target.value.length >= 3) && (ganttUrl?.startsWith('https://app.instagantt.com/r#projects/')) && (repo?.startsWith('https://github.com/')) && duration && taskInter && techDiff);
                            }
                            else {
                                return null;
                            }
                        }}
                    />
                    <TextField
                        id="project-manager"
                        label="Project Manager"
                        variant="outlined"
                        disabled
                        defaultValue={fullname}
                    />
                    <TextField
                        sx={{ svg: { color: theme.palette.svg.main } }}
                        id="add-to-collection"
                        label="Add to a Collection (Optional)"
                        variant="outlined"
                        select
                        value={selectedCollection}
                        SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
                        onChange={(e) => setSelectedCollection(e.target.value)}
                    >
                        {
                            collectionOptions.map((collection) => {
                                return (
                                    <MenuItem
                                        sx={{ fontWeight: 300, padding: 1.5 }}
                                        key={collection.collection_id}
                                        value={collection.collection_id}
                                    >
                                        {collection.collection_name}
                                    </MenuItem>
                                )
                            })
                        }
                    </TextField>
                    <Stack direction="row" >
                        <Stack sx={{ width: '50%' }} rowGap={2}>
                            <Typography variant="subtitle" sx={{ fontWeight: 400, color: theme.palette.secondary.main }}>Technical Difficulty *</Typography>
                            <Stack direction="row" gap={1}>
                                <Chip
                                    label="Low"
                                    onClick={
                                        (e) => {
                                            setTechDiff('Low');
                                            setSaveButton((projectName.length >= 3) && (ganttUrl?.startsWith('https://app.instagantt.com/r#projects/')) && (repo?.startsWith('https://github.com/')) && duration && taskInter && 'Low');
                                        }
                                    }
                                    sx={{ cursor: 'pointer', fontSize: '14px', fontWeight: 350 }}
                                    color={techDiff === 'Low' ? 'primary' : 'default'}
                                />
                                <Chip
                                    label="Medium"
                                    onClick={() => {
                                        setTechDiff('Medium');
                                        setSaveButton((projectName.length >= 3) && (ganttUrl?.startsWith('https://app.instagantt.com/r#projects/')) && (repo?.startsWith('https://github.com/')) && duration && taskInter && 'Medium');
                                    }}
                                    sx={{ cursor: 'pointer', fontSize: '14px', fontWeight: 350 }}
                                    color={techDiff === 'Medium' ? 'primary' : 'default'}
                                />
                                <Chip
                                    label="High"
                                    onClick={() => {
                                        setTechDiff('High');
                                        setSaveButton((projectName.length >= 3) && (ganttUrl?.startsWith('https://app.instagantt.com/r#projects/')) && (repo?.startsWith('https://github.com/')) && duration && taskInter && 'High');
                                    }}
                                    sx={{ cursor: 'pointer', fontSize: '14px', fontWeight: 350 }}
                                    color={techDiff === 'High' ? 'primary' : 'default'}
                                />
                            </Stack>
                        </Stack>
                        <Stack sx={{ width: '50%' }} rowGap={2}>
                            <Typography variant="subtitle" sx={{ fontWeight: 400, color: theme.palette.secondary.main }}>Task Interdependence *</Typography>
                            <Stack direction="row" gap={1}>
                                <Chip
                                    onClick={(e) => {
                                        setTaskInter('Low');
                                        setSaveButton((projectName.length >= 3) && (ganttUrl?.startsWith('https://app.instagantt.com/r#projects/')) && (repo?.startsWith('https://github.com/')) && duration && 'Low' && techDiff);
                                    }}
                                    color={taskInter === 'Low' ? 'primary' : 'default'}
                                    label="Low"
                                    sx={{ cursor: 'pointer', fontSize: '15px', fontWeight: 350 }}
                                />
                                <Chip
                                    onClick={(e) => {
                                        setTaskInter('Medium');
                                        setSaveButton((projectName.length >= 3) && (ganttUrl?.startsWith('https://app.instagantt.com/r#projects/')) && (repo?.startsWith('https://github.com/')) && duration && 'Medium' && techDiff);
                                    }}
                                    color={taskInter === 'Medium' ? 'primary' : 'default'}
                                    label="Medium"
                                    sx={{ cursor: 'pointer', fontSize: '15px', fontWeight: 350 }}
                                />
                                <Chip
                                    onClick={(e) => {
                                        setTaskInter('High');
                                        setSaveButton((projectName.length >= 3) && (ganttUrl?.startsWith('https://app.instagantt.com/r#projects/')) && (repo?.startsWith('https://github.com/')) && duration && 'High' && techDiff);
                                    }}
                                    color={taskInter === 'High' ? 'primary' : 'default'}
                                    label="High"
                                    sx={{ cursor: 'pointer', fontSize: '15px', fontWeight: 350 }}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" columnGap={2}>
                        <TextField
                            FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                            helperText={duration != 0 ? '' : 'duration must be greater than 0'}
                            error={duration == 0}
                            sx={{ width: '50%' }}
                            id="expected-duration"
                            label="Expected duration, in months"
                            variant="outlined"
                            required
                            value={duration}
                            onChange={(e) => {
                                if (!isNaN(+e.target.value)) {
                                    setDuration(+e.target.value);
                                    setSaveButton((projectName.length >= 3) && (ganttUrl?.startsWith('https://app.instagantt.com/r#projects/')) && (repo?.startsWith('https://github.com/')) && +e.target.value && taskInter && techDiff);
                                } else {
                                    return null;
                                }
                            }}
                        />
                        {
                            duration != 0 && duration !== '' && taskInter && techDiff &&
                            <Stack sx={{ width: '50%' }}>
                                <Typography variant="subtitle" sx={{ fontWeight: 500 }}>
                                    {`Buffer : ${duration * (findValue(taskInter) + findValue(techDiff))} days`}
                                </Typography>
                                <Typography variant="caption">Buffer = Duration * (Difficulty + Interdependence), where High, Medium
                                    and Low are 3,2 and 1.</Typography>
                            </Stack>
                        }
                    </Stack>
                    <TextField
                        FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                        helperText={ganttUrl !== '' && !ganttUrl?.startsWith('https://app.instagantt.com/r#projects/') ? 'URL must start with https://app.instagantt.com/r#projects/' : <Stack direction='row' justifyContent="space-between">
                            <Typography variant="caption">{'Link to project gantt, starting with https://app.instagantt.com/r#projects/...'}</Typography>
                            <Typography variant="caption">{`${ganttUrl.length}/100`}</Typography>
                        </Stack>}
                        id="gantt-url"
                        label="Gantt URL"
                        variant="outlined"
                        required
                        error={ganttUrl !== '' && !ganttUrl?.startsWith('https://app.instagantt.com/r#projects/')}
                        value={ganttUrl}
                        onChange={(e) => {
                            if (e.target.value.length <= 100) {
                                setGanttUrl(e.target.value);
                                setSaveButton((projectName.length >= 3) && (e.target.value?.startsWith('https://app.instagantt.com/r#projects/')) && (repo?.startsWith('https://github.com/')) && duration && taskInter && techDiff);
                            }
                            else {
                                return null
                            }
                        }}
                    />
                    <TextField
                        FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                        helperText={repo !== '' && !repo?.startsWith('https://github.com/') ? 'URL must start with https://github.com/' : <Stack direction='row' justifyContent="space-between">
                            <Typography variant="caption">{'Link to issue-repo, such as https://github.com/org/myproject-main'}</Typography>
                            <Typography variant="caption">{`${repo.length}/100`}</Typography>
                        </Stack>}
                        id="github-repo"
                        label="GitHub issue repository"
                        variant="outlined"
                        required
                        error={repo !== '' && !repo?.startsWith('https://github.com/')}
                        value={repo}
                        onChange={(e) => {
                            if (e.target.value.length <= 100) {
                                setRepo(e.target.value);
                                setSaveButton((projectName.length >= 3) && (ganttUrl?.startsWith('https://app.instagantt.com/r#projects/')) && (e.target.value?.startsWith('https://github.com/')) && duration && taskInter && techDiff);
                            } else {
                                return null
                            }
                        }}
                    />
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={() => dispatch(collectionsActions.setEditProjectModalClose())}>Cancel</Button>
                        <Button disabled={!saveButton} onClick={editProjectHandler}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal >
    );
}

export default EditProjectModal;