import { Box, Stack, Typography } from "@mui/material";

const BalanceCard = ({ bgColor, color, text, balance }) => {
    return (
        <Box sx={{ backgroundColor: bgColor, width: '25%', borderRadius: '10px', height: '130px', padding: 2 }}>
            <Stack justifyContent="center" alignItems="center" rowGap={3}>
                <Typography variant="h6" sx={{ color: color }}>{text}</Typography>
                <Typography variant="h6" sx={{ color: '#000', fontWeight: 400 }}>{balance} {balance === 1 || balance === -1 ? 'day' : 'days'} </Typography>
            </Stack>
        </Box>
    );
}

export default BalanceCard;