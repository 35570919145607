import React, { useState } from "react";
import { Modal, Box, Typography, Stack, Button, TextField, MenuItem, Autocomplete, Divider } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { leaveActions } from "../../store";
import { fetchOthersLeave, postUnpaid } from "../../services/leaveServices";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import moment from "moment";
import { firstDayOfFinancialYear, disableWeekends } from "../../Utils/leaveHelpers";

const UnpaidModal = ({ style, dateStyle, textFieldStyle }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [notes, setNotes] = useState('');
    const [saveButton, setSaveButton] = useState(false);

    const postUnpaidModal = useSelector((state) => state.leave.postUnpaidModal);
    const employees = useSelector((state) => state.leave.appliedBy);
    let fullname = useSelector((state) => state.user.fullname);
    const loggedInUser = useSelector((state) => state.user.userEmail);

    const resetData = () => {
        setSelectedEmployee(null);
        setSaveButton(false);
        setFromDate('');
        setToDate('');
        setNotes('');
    }

    const submitUnpaid = async () => {
        try {
            const data = {
                from: fromDate,
                to: toDate,
                note: notes,
                employee: selectedEmployee.email
            }
            dispatch(leaveActions.setLoader({ value: true }));
            const response = await postUnpaid(data);
            const othersLeave = await fetchOthersLeave();
            dispatch(leaveActions.setOthersLeave({ othersLeave: othersLeave.data.allLeaves }));
            dispatch(leaveActions.setFilteredOthersLeave({ filteredOthersLeave: othersLeave?.data?.allLeaves.filter((leave) => leave.status === "pending") }));
            dispatch(leaveActions.setLoader({ value: false }));
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'success', message: response?.data?.msg || 'unpaid days submitted !!' } }));
            dispatch(leaveActions.setunpaidModalClose());
            resetData();
        }
        catch (error) {
            dispatch(leaveActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={postUnpaidModal}
            onClose={() => {
                dispatch(leaveActions.setunpaidModalClose());
                resetData();
            }}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Post unpaid days</Typography>
                    <Divider />

                    <Autocomplete
                        id="employee-selection"
                        options={employees}
                        getOptionLabel={(user) => user.name}
                        style={{ width: '50%' }}
                        sx={{ svg: { color: theme.palette.svg.main } }}
                        autoHighlight={false}
                        renderInput={(params) => (
                            <TextField {...params}
                                label="Employee" variant="filled"
                                style={{ backgroundColor: theme.palette.searchInput.main }}
                                value={selectedEmployee === null ? '' : selectedEmployee}
                            />)}
                        renderOption={
                            (props, user) => (
                                <MenuItem
                                    {...props}
                                    style={{ fontWeight: 300, padding: '0.8rem' }}
                                    key={user.email + user.name + user.emp_id}
                                    value={JSON.stringify({
                                        emp_id: user.emp_id,
                                        email: user.email,
                                        name: user.name
                                    })}
                                    disabled={loggedInUser === user.email}
                                >
                                    <Typography sx={{ fontWeight: 350 }}>{user.name}</Typography>
                                </MenuItem>
                            )}
                        onChange={(e, newValue) => {
                            console.log(newValue);
                            setSelectedEmployee(newValue);
                            if (!newValue) {
                                resetData();
                            }
                        }}
                    />

                    <TextField
                        id="manager"
                        label="Manager"
                        variant="outlined"
                        disabled
                        defaultValue={fullname}
                        sx={{ width: '50%' }}
                    />

                    <Stack direction="row" columnGap={1} sx={{ width: '100%' }} alignItems="center">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={dateStyle}
                                label="Unpaid days, From"
                                format="DD-MMM-YYYY"
                                defaultValue={''}
                                value={dayjs(fromDate)}
                                required
                                slotProps={{
                                    field: {
                                        readOnly: true
                                    },
                                    textField: textFieldStyle
                                }}
                                minDate={dayjs(firstDayOfFinancialYear(moment().format('YYYY-MM-DD')))}
                                maxDate={dayjs(moment().format('YYYY-MM-DD'))}
                                disabled={!selectedEmployee}
                                shouldDisableDate={disableWeekends}
                                views={['day']}
                                onChange={(value) => {
                                    setToDate('');
                                    setSaveButton(false);
                                    setFromDate(moment(new Date(value.$d)).format("YYYY-MM-DD"));
                                }}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={dateStyle}
                                label="Unpaid days, To"
                                format="DD-MMM-YYYY"
                                defaultValue={''}
                                value={dayjs(toDate)}
                                required
                                slotProps={{
                                    field: {
                                        readOnly: true
                                    },
                                    textField: textFieldStyle
                                }}
                                minDate={dayjs(moment(fromDate).format('YYYY-MM-DD'))}
                                maxDate={dayjs(moment().format('YYYY-MM-DD'))}
                                disabled={fromDate === ''}
                                views={['day']}
                                shouldDisableDate={disableWeekends}
                                onChange={(value) => {
                                    setSaveButton((selectedEmployee) && (fromDate) && (moment(new Date(value.$d)).format("YYYY-MM-DD")) && notes.length >= 5);
                                    setToDate(moment(new Date(value.$d)).format("YYYY-MM-DD"));
                                }}
                            />
                        </LocalizationProvider>
                    </Stack>

                    <TextField
                        FormHelperTextProps={{ sx: { display: 'flex', justifyContent: 'flex-end' } }}
                        helperText={notes.length < 5 && notes.length > 0 ? 'min 5 and max 500 chars' : `${notes.length}/500`}
                        error={notes.length < 5 && notes.length > 0}
                        id="notes"
                        label="Notes"
                        variant="outlined"
                        value={notes}
                        required
                        multiline
                        rows={2}
                        onChange={(e) => {
                            if (e.target.value.length <= 500) {
                                setNotes(e.target.value);
                                setSaveButton((selectedEmployee) && (fromDate) && (toDate) && notes.length >= 5);
                            }
                        }}
                    />

                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button
                            onClick={() => {
                                dispatch(leaveActions.setunpaidModalClose());
                                resetData();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button disabled={!saveButton} onClick={submitUnpaid}>Submit</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default UnpaidModal;