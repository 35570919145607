import axios from "axios";
let baseUrl = process.env.REACT_APP_DEV_BASE_URL;

const fetchAllContributors = async () => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/task-contributors`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }))
    ]);
    return response;
}

const fetchContributorData = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/contributor-data?project_id=${data.project_uid}&contributor_id=${data.contributor_id}&snapshot_date=${data.selectedSnapshotDate}&compare_to=${data.selectedComparedTo}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }))
    ]);
    return response;
}

export {
    fetchAllContributors,
    fetchContributorData,
};