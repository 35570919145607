import { Snackbar, Alert, Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selfDirectednessActions } from "../../store";

const SelfDirectednessLoader = () => {
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.selfDirectedness.loader);
    const snackbar = useSelector((state) => state.selfDirectedness.snackbar);

    const handleSnackbarClose = () => {
        dispatch(selfDirectednessActions.setSnackbar({ value: { open: false, type: 'success', message: '' } }));
    }

    return (
        <>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert severity={snackbar.type} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Backdrop
                // sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                sx={{
                    zIndex: (theme) =>
                        Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
                }}
                open={loader}
            >
                <CircularProgress color="secondary" />
            </Backdrop>
        </>
    );
}

export default SelfDirectednessLoader;