import axios from "axios";
let baseUrl = process.env.REACT_APP_DEV_BASE_URL;

const fetchAllProjects = async () => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/all-projects`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        )
    ]);
    return response;
}

const fetchProjectSummaryData = async (project_uid, snapshot_date, compare_to_date) => {
    let token = localStorage.getItem('token');
    const url = snapshot_date === compare_to_date ? `${baseUrl}/api/project-summary?project_id=${project_uid}&snapshot_date=${snapshot_date}` : `${baseUrl}/api/project-summary?project_id=${project_uid}&snapshot_date=${snapshot_date}&compare_to=${compare_to_date}`;
    const [response] = await Promise.all([
        (await axios(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        )
    ]);
    return response;
}

const fetchSnapshotDates = async (project_uid) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/snapshot-dates/${project_uid}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        )
    ]);
    return response;
}

const fetchProjectNotes = async (project_uid) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/get-notes/${project_uid}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        )
    ]);
    return response;
}

const addNotes = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/add-note`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: data
        })
        )
    ]);
    return response;
}

const deleteNote = async (project_uid, noteId) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/note?project_id=${project_uid}&note_id=${noteId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        )
    ]);
    return response;
}

const progressEffortWise = async (project_uid, snapshot_date) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/progress/effortBased?project_id=${project_uid}&snapshot_date=${snapshot_date}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        )
    ]);
    return response;
}

const downloadProjectSummaryLogs = async (project_id) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/log?project_id=${project_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            responseType: 'blob',
        })
        )
    ]);
    return response;
}

const fetchTaskDetails = async (project_uid, snapshot_date) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/task-details?project_id=${project_uid}&snapshot_date=${snapshot_date}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        )
    ]);
    return response;
}

const checkSnapshot = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/check-snapshot`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: data
        })
        )
    ]);
    return response;
}

const uploadSnapshot = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/snapshot`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: data
        })
        )
    ]);
    return response;
}

const notifyUsers = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/notify`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: data
        })
        )
    ]);
    return response;
}

const addLogs = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/log`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: data
        })
        )
    ]);
    return response;
}

export {
    fetchAllProjects,
    fetchProjectSummaryData,
    fetchSnapshotDates,
    fetchProjectNotes,
    addNotes,
    deleteNote,
    progressEffortWise,
    downloadProjectSummaryLogs,
    fetchTaskDetails,
    checkSnapshot,
    uploadSnapshot,
    notifyUsers,
    addLogs,
};