import React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { styled } from '@mui/system';
import errorImg from '../Assets/6325257.svg'

const RootContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90vh',
    textAlign: 'center',
});

const ErrorPage = () => {
    return (
        <>
            <Button variant="contained" color="primary" component={Link} href="/" sx={{ marginLeft: 2, marginTop: 2 }}>Go Home</Button>
            <RootContainer>
                <img src={errorImg} alt='No Data Found' loading='lazy' style={{ width: '60%', height: '90%' }} />
            </RootContainer>
        </>
    );
}

export default ErrorPage;