import { Box, Stack, Button } from "@mui/material";
import React, { useEffect, useRef } from "react";
import SelfDirectednessLoader from './SelfDirectednessLoader';
import EmptyPage from '../EmptyPage';
import { useTheme } from "@emotion/react";
import SearchField from "./SearchField";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import { selfDirectednessActions } from "../../store";
import { fetchFeedbacks } from "../../services/selfDirectednessService";
import { useNavigate } from "react-router-dom";
import FeedbackTable from "./FeedbackTable";
import ViewFeedbackModal from "./ViewFeedbackModal";

function SelfDirectedness() {
    const feedbackRef = useRef(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loggedinUserEmail = useSelector((state) => state.user.userEmail);
    let manager = useSelector((state) => state.user.manager);
    let feedback = useSelector((state) => state.selfDirectedness.feedback);

    const style = { backgroundColor: theme.palette.surface1.main, width: '100%', borderRadius: '10px', textAlign: 'center', marginTop: 1, padding: 2, color: theme.palette.surface1.contrastText };

    const getFeedbacks = async () => {
        try {
            dispatch(selfDirectednessActions.setLoader({ value: true }));
            const response = await fetchFeedbacks();
            dispatch(selfDirectednessActions.setFeedback({ feedback: response.data.feedback }));
            dispatch(selfDirectednessActions.setLoader({ value: false }));
        }
        catch (error) {
            dispatch(selfDirectednessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(selfDirectednessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (feedbackRef.current) return;
        feedbackRef.current = true;
        dispatch(selfDirectednessActions.resetDates());
        getFeedbacks();
    });

    if (!manager) {
        feedback = feedback.filter((feedback) => feedback.contributor_email == loggedinUserEmail);
    }

    return (
        <Stack>
            <Box sx={style}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <SearchField />
                    {manager && <Button variant="contained" size="large" startIcon={<AddIcon />} onClick={() => navigate('/Self-directedness/create-feedback')}>Add</Button>}
                </Stack>
            </Box>
            {feedback.length === 0 && <EmptyPage text="You don't have any data now" />}
            {feedback.length !== 0 && <FeedbackTable />}
            {feedback.length !== 0 && <ViewFeedbackModal />}
            <SelfDirectednessLoader />
        </Stack>
    );
}

export default SelfDirectedness;