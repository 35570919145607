import React from "react";
import { Typography, Menu, MenuItem } from "@mui/material";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import { collectionsActions } from "../../store";
import { fetchContributorsData } from "../../services/collectionServices";
import { useNavigate } from "react-router-dom";

const CollectionMenus = (props) => {
    let { type } = props;
    const anchorCollectionName = useSelector((state) => state.collections.anchorCollectionName);
    const anchorProjectName = useSelector((state) => state.collections.anchorProjectName);
    const menuAnchor = useSelector((state) => state.collections.menuAnchor);
    const contributors = useSelector((state) => state.collections.contributors);
    const dispatch = useDispatch();
    const snapshotExist = useSelector((state) => state.collections.snapshotExist);
    let manager = useSelector((state) => state.user.manager);
    let employee = useSelector((state) => state.user.employee);
    let menuList = [];
    let anchorName = "";
    let closeMenuhandler = null;
    const navigate = useNavigate();
    switch (type) {
        case "collectionName":
            menuList = employee ? ['View Contributors'] : ['Rename Collection', 'Remove Collection', 'View Contributors'];
            anchorName = anchorCollectionName;
            closeMenuhandler = () => dispatch(collectionsActions.handleCloseCollectionNameMenu());
            break;

        case "projectName":
            menuList = employee ? [] : ['Edit Project', 'Add Snapshot', 'Remove from Collection', 'Change Collection', 'See Details'];
            anchorName = anchorProjectName;
            closeMenuhandler = () => dispatch(collectionsActions.handleCloseProjectNameMenu())
            break;
    }

    const selectedCollectionId = useSelector((state) => state.collections.collectionMenuSelectedCollectionId);
    const selectedProjectId = useSelector((state) => state.collections.projectsMenuSelectedProjectId);
    const fetchContributors = async () => {
        try {
            dispatch(collectionsActions.setLoader({ value: true }));
            const response = await fetchContributorsData(selectedCollectionId);
            dispatch(collectionsActions.setLoader({ value: false }));
            dispatch(collectionsActions.setContributors({ contributors: { collection_name: contributors.collection_name, contributors: response.data.contributors } }));
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data Fetched' } }));
        }
        catch (error) {
            dispatch(collectionsActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Menu
            sx={{ mt: '25px', width: '600px' }}
            id="menu-appbar"
            anchorEl={menuAnchor}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={anchorName}
            onClose={closeMenuhandler}
        >
            {
                menuList.map((listData) => (
                    <MenuItem
                        key={listData}
                        disabled={selectedCollectionId === 1 && listData === 'Remove from Collection'}
                        onClick={() => {
                            closeMenuhandler();
                            switch (listData) {
                                case 'View Contributors': fetchContributors();
                                    dispatch(collectionsActions.setViewContributorsOpen());
                                    break;
                                case 'Rename Collection': dispatch(collectionsActions.setRenameCollectionModalOpen());
                                    break;
                                case 'Remove Collection': dispatch(collectionsActions.setRemoveCollectionModalOpen());
                                    break;
                                case 'Change Collection': dispatch(collectionsActions.setChangeCollectionModalOpen());
                                    break;
                                case 'Edit Project': dispatch(collectionsActions.setEditProjectModalOpen());
                                    break;
                                case 'Add Snapshot': dispatch(collectionsActions.setAddSnapshotModalOpen());
                                    break;
                                case 'Remove from Collection': dispatch(collectionsActions.setRemoveFromCollectionModalOpen());
                                    break;
                                case 'See Details': snapshotExist && navigate(`/collections/details/${selectedProjectId}`);
                                    break;
                                default: break;
                            }
                        }}
                        sx={{ padding: 1.5 }}
                    >

                        <Typography
                            textAlign="center"
                            sx={{ fontWeight: '350' }}
                        >
                            {listData}
                        </Typography>
                    </MenuItem>
                ))}
        </Menu>
    );
}

export default CollectionMenus;