import axios from "axios";
let baseUrl = process.env.REACT_APP_DEV_BASE_URL;

const fetchAllGithubProjects = async () => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/git/all-projects`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        )
    ]);
    return response;
}

const fetchContributorsInProject = async (project_id) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/contributorsInProject?project_id=${project_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        )
    ]);
    return response;
}

const fetchIssuesData = async (user_id, project_uid, date) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/git/getIssues?user_id=${user_id}&project_id=${project_uid}&date=${date}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        )
    ]);
    return response;
}

export {
    fetchAllGithubProjects,
    fetchContributorsInProject,
    fetchIssuesData,
}