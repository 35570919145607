import { useTheme } from "@emotion/react";
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TableSortLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useState } from "react";
import { paginationResult } from '../../Utils/TableColumnSort';
import { selfDirectednessActions } from "../../store";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { deleteFeedback, fetchFeedbacks } from '../../services/selfDirectednessService';
import { useNavigate } from "react-router-dom";


const FeedbackTable = () => {
    const theme = useTheme();
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const pages = useSelector((state) => state.selfDirectedness.pages);
    const page = useSelector((state) => state.selfDirectedness.page);
    const rowsPerPage = useSelector((state) => state.selfDirectedness.rowsPerPage);
    let manager = useSelector((state) => state.user.manager);
    let feedback = useSelector((state) => state.selfDirectedness.filteredFeedback);
    const loggedinUserEmail = useSelector((state) => state.user.userEmail);

    const columns = ['Contributor Name', 'From Month, Year', 'To Month, Year', 'Initiative', 'Conceptual ability', 'Self-discipline', 'Total Score', 'Feedback by', 'Feedback On', ''];
    const fields = ['contributor_name', 'from', 'to', 'questions', 'questions', 'questions', 'total_score', 'manager_name', 'createdOn', 'view'];

    if (manager) {
        columns.push('', '');
        fields.push('delete', 'edit');
    }

    const handleChangePage = (event, newPage) => {
        dispatch(selfDirectednessActions.setPage({ value: newPage }));
    }

    const handleChangeRowsPerPage = (event) => {
        dispatch(selfDirectednessActions.setRowsPerPage({ value: parseInt(event.target.value) }));
        dispatch(selfDirectednessActions.setPage({ value: 0 }));
    }

    const handleSortRequest = (cellId) => {
        if (!order) {
            setOrder("asc")
            setOrderBy(cellId);
        }
        else if (order === "asc") {
            setOrder("desc");
            setOrderBy(cellId);
        }
        else {
            setOrder();
            setOrderBy();
        }
    }

    const feedbackDeleteHandler = async (feedback_id) => {
        try {
            dispatch(selfDirectednessActions.setLoader({ value: true }));
            await deleteFeedback(feedback_id);
            const response = await fetchFeedbacks();
            dispatch(selfDirectednessActions.setFeedback({ feedback: response.data.feedback }));
            dispatch(selfDirectednessActions.setLoader({ value: false }));
            dispatch(selfDirectednessActions.setSnackbar({ value: { open: true, type: 'success', message: 'Feedback deleted!!' } }));
        }
        catch (error) {
            dispatch(selfDirectednessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(selfDirectednessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    if (!manager) {
        feedback = feedback.filter((feedback) => feedback.contributor_email == loggedinUserEmail);
    }

    return (
        <Box style={{ backgroundColor: theme.palette.surface.main, color: theme.palette.surface.contrastText, padding: 0, borderRadius: '10px', marginTop: 20 }}>
            <TableContainer sx={{
                backgroundColor: theme.palette.lighter.main,
                borderRadius: '10px',
                minheight: '500px',
                "&::-webkit-scrollbar": { width: 0 }
            }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((column, i) => {
                                    return (
                                        <TableCell key={column + fields[i]} sx={{ backgroundColor: theme.palette.light.main }}>
                                            <TableSortLabel
                                                sx={{
                                                    '&.MuiTableSortLabel-root': {
                                                        color: theme.palette.light.contrastText,
                                                        '& .MuiTableSortLabel-icon': {
                                                            color: theme.palette.light.contrastText
                                                        }
                                                    },
                                                }}
                                                active={orderBy === fields[i]}
                                                direction={orderBy === fields[i] ? order : "asc"}
                                                onClick={() => { handleSortRequest(fields[i]) }}>
                                                {column}
                                            </TableSortLabel>
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            paginationResult(feedback, order, orderBy, page, rowsPerPage).map((feedback, i) => {
                                return (
                                    <TableRow key={feedback.feedback_id}>
                                        <TableCell sx={{ color: "#EE7214" }}>{feedback.contributor_name}</TableCell>
                                        <TableCell>{moment(feedback.from).format('DD-MMM-YY')}</TableCell>
                                        <TableCell>{moment(feedback.to).format('DD-MMM-YY')}</TableCell>
                                        <TableCell>{feedback.questions.filter((ques) => ques.ques_id == 1)[0].score}</TableCell>
                                        <TableCell>{feedback.questions.filter((ques) => ques.ques_id == 2)[0].score}</TableCell>
                                        <TableCell>{feedback.questions.filter((ques) => ques.ques_id == 3)[0].score}</TableCell>
                                        <TableCell>{feedback.total_score}</TableCell>
                                        <TableCell>{feedback.manager_name}</TableCell>
                                        <TableCell>{moment(feedback.createdOn).format('DD-MMM-YY')}</TableCell>
                                        <TableCell onClick={() => {
                                            dispatch(selfDirectednessActions.setFeedbackToView({ feedbackToView: feedback.feedback_id }));
                                            dispatch(selfDirectednessActions.setFeedbackModalOpen());
                                        }}>
                                            <RemoveRedEyeIcon sx={{ cursor: 'pointer' }} />
                                        </TableCell>
                                        {manager && moment(feedback.createdOn).add(30, 'd') > moment() &&
                                            <TableCell>
                                                {loggedinUserEmail === feedback.manager_email &&
                                                    <EditIcon
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            navigate(`/Self-directedness/edit/${feedback.feedback_id}`);
                                                        }}
                                                    />
                                                }
                                            </TableCell>}

                                        {manager && moment(feedback.createdOn).add(30, 'd') > moment() &&
                                            <TableCell>
                                                {loggedinUserEmail === feedback.manager_email &&
                                                    <DeleteIcon
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={() => loggedinUserEmail === feedback.manager_email ? feedbackDeleteHandler(feedback.feedback_id) : null}
                                                    />
                                                }
                                            </TableCell>
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                sx={{
                    '& .MuiIconButton-root': {
                        color: theme.palette.surface.contrastText,
                    },
                    '& .Mui-disabled': {
                        color: theme.palette.disabled.main,
                    },
                    '& .MuiTablePagination-toolbar': {
                        color: theme.palette.surface.contrastText
                    }
                }}
                rowsPerPageOptions={pages}
                component="div"
                count={feedback.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
            />
        </Box>
    )
}

export default FeedbackTable;