import axios from "axios";

let baseUrl = process.env.REACT_APP_DEV_BASE_URL;
let baseUrlAttendance = process.env.REACT_APP_ATTENDANCE_BASE_URL;

const fetchGoogleUserData = async () => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/google/users`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }))
    ]);
    return response;
}

const fetchAttendance = async (date) => {
    const [response] = await Promise.all([
        (await axios(`${baseUrlAttendance}/attendance?date=${date}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }))
    ]);
    return response;
}

const fetchLoginURl = async () => {
    const [response] = await Promise.all([
        (await axios.get(`${baseUrl}/google/oAuth/url`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }))
    ]);
    return response;
}

const fetchGithubHandle = async () => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios.get(`${baseUrl}/api/git/username`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        }))
    ]);
    return response;
}

const updateGithubHandle = async (username) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/git/username?github_username=${username}`, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        }))
    ]);
    return response;
}

const fetchFigmaToken = async () => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios.get(`${baseUrl}/api/figmaToken`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            }
        }))
    ]);
    return response;
}

const updateFigmaToken = async (figmaToken) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/figmaToken?figma_token=${figmaToken}`, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        }))
    ]);
    return response;
}

export { fetchGoogleUserData, fetchAttendance, fetchLoginURl, fetchGithubHandle, fetchFigmaToken, updateGithubHandle, updateFigmaToken };