import { useTheme } from "@emotion/react";
import { Typography, Box } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';
import TimerIcon from '@mui/icons-material/Timer';
import PersonIcon from '@mui/icons-material/Person';
import { useLocation } from "react-router-dom";
import GitHubIcon from '@mui/icons-material/GitHub';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import noData from '../Assets/8961448_3973477.svg';
import projectSummaryImg from '../Assets/9712726_4170014.svg';
import timelinessImg from '../Assets/timeliness.svg';
import contributorImg from '../Assets/contributor.svg';
import githubImg from '../Assets/github.svg';

const EmptyPage = (props) => {
    const { text } = props;
    const theme = useTheme();
    const location = useLocation();

    const fetchIcon = () => {
        const fontStyle = { fontSize: 100, marginTop: '15%' };
        if (location.pathname.startsWith('/project-summary')) {
            // return <DescriptionIcon style={fontStyle} />
            return <img src={projectSummaryImg} alt='No Data Found' loading='lazy' style={{ marginTop: '5%', height: '50%' }} />
        }
        else if (location.pathname.startsWith('/task-timeliness')) {
            // return <TimerIcon style={fontStyle} />
            return <img src={timelinessImg} alt='No Data Found' loading='lazy' style={{ marginTop: '5%', height: '50%' }} />
        }
        else if (location.pathname.startsWith('/contributor-details') || location.pathname.startsWith('/admin')) {
            // return <PersonIcon style={fontStyle} />
            return <img src={contributorImg} alt='No Data Found' loading='lazy' style={{ marginTop: '5%', height: '50%' }} />
        }
        else if (location.pathname.startsWith('/allocation')) {
            return <img src={noData} alt='No Data Found' loading='lazy' style={{ marginTop: '5%', height: '70%' }} />
        }
        else if (location.pathname.startsWith('/github')) {
            // return <GitHubIcon style={fontStyle} />
            return <img src={githubImg} alt='No Data Found' loading='lazy' style={{ marginTop: '5%', height: '50%' }} />
        }
        else if (location.pathname.startsWith('/Self-directedness')) {
            return <SelfImprovementIcon style={fontStyle} />
        }
        else if (location.pathname.startsWith('/leave')) {
            return <img src={noData} alt='No Data Found' loading='lazy' style={{ marginTop: '5%', height: '70%' }} />
        }
    }

    return (
        <Box sx={{ backgroundColor: theme.palette.surface1.main, height: '70vh', width: '100%', borderRadius: '10px', textAlign: 'center', marginTop: 1, padding: 1, color: theme.palette.surface1.contrastText }}>
            {!location.pathname.startsWith('/allocation') && !location.pathname.startsWith('/leave') && fetchIcon()}
            {
                (location.pathname.startsWith('/allocation') || location.pathname.startsWith('/leave')) && <Box sx={{ backgroundColor: theme.palette.surface1.main, height: '68vh', width: '100%', borderRadius: '10px', textAlign: 'center' }}>
                    {fetchIcon()}
                </Box>
            }
            <Typography variant="h4">{text}</Typography>
        </Box>
    );
}

export default EmptyPage;