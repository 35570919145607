import React from "react";
import { Modal, Box, Typography, Stack, Button, TextField } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { taskTimelinessActions } from "../../store/index";
import { updateVoteRequest, fetchTimelinessData } from '../../services/timelinessServices';
import { useNavigate } from "react-router-dom";

const CorrectDelayModal = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const correctedDelayModal = useSelector((state) => state.taskTimeliness.correctedDelayModal);
    const navigate = useNavigate();
    const selectedSnapshotDate = useSelector((state) => state.taskTimeliness.selectedSnapshotDate);
    const selectedComparedTo = useSelector((state) => state.taskTimeliness.selectedComparedTo);
    const selectedProjectId = useSelector((state) => state.taskTimeliness.selectedProjectId);
    const selectedTask = useSelector((state) => state.taskTimeliness.selectedTask);
    const filter1 = useSelector((state) => state.taskTimeliness.filter1);
    const filter2 = useSelector((state) => state.taskTimeliness.filter2);


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };

    const closeModalHandler = () => {
        dispatch(taskTimelinessActions.setSelectedTask({ selectedTask: { taskId: null, delay: '', comment: '', upvote: null } }));
        dispatch(taskTimelinessActions.setCorrectedDelayModalClose())
    }

    const saveDelayHandler = async () => {
        const data = {
            baseline_date: selectedComparedTo,
            comments: selectedTask.comment,
            corrected_delay: selectedTask.delay,
            project_id: selectedProjectId.project_uid,
            snapshot_date: selectedSnapshotDate,
            task_id: selectedTask.taskId,
            upvote: selectedTask.upvote,
        }
        console.log(data);
        try {
            dispatch(taskTimelinessActions.setLoader({ value: true }));
            await updateVoteRequest(data);
            const response = await fetchTimelinessData(selectedProjectId.project_uid, selectedSnapshotDate, selectedComparedTo, filter1, filter2);
            dispatch(taskTimelinessActions.setTimelinessData({ timelinessData: response.data.delayArray }));
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            closeModalHandler();
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'success', message: 'delay corrected !!!' } }));
        }
        catch (error) {
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={correctedDelayModal}
            onClose={closeModalHandler}
        >
            <Box sx={style}>
                <Stack rowGap={2}>
                    <Typography variant="h5">Comments & corrected delay</Typography>
                    <TextField
                        FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                        helperText={<Stack direction="row" justifyContent={selectedTask.comment.length < 3 && selectedTask.comment !== '' ? "space-between" : "flex-end"}>
                            {selectedTask.comment.length < 3 && selectedTask.comment !== '' && <Typography variant="caption">min 3 and max 50 chars</Typography>}
                            <Typography variant="caption">{selectedTask.comment.length}/50</Typography>
                        </Stack>}
                        error={selectedTask.comment.length < 3 && selectedTask.comment !== ''}
                        id="notes-box"
                        label="Comment"
                        value={selectedTask.comment}
                        onChange={(e) => {
                            if (e.target.value.length <= 50) {
                                dispatch(taskTimelinessActions.setSelectedTask({ selectedTask: { taskId: selectedTask.taskId, delay: selectedTask.delay, comment: e.target.value, upvote: selectedTask.upvote } }));
                            }
                            else {
                                return null;
                            }
                        }}
                    />
                    <TextField
                        FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                        helperText={<Typography variant="caption">Positive value for delays, negative values for early finish
                        </Typography>}
                        label="Delay in days"
                        value={selectedTask.delay}
                        onChange={(e) => {
                            if (e.target.value === '-' || !isNaN(+e.target.value)) {
                                dispatch(taskTimelinessActions.setSelectedTask({ selectedTask: { taskId: selectedTask.taskId, delay: e.target.value, comment: selectedTask.comment, upvote: selectedTask.upvote } }));
                            } else {
                                return null;
                            }
                        }}
                    />
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={closeModalHandler}>Cancel</Button>
                        <Button disabled={!selectedTask.delay && !selectedTask.comment} onClick={saveDelayHandler}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    )
}

export default CorrectDelayModal;