import React, { useState } from "react";
import { Modal, Box, Typography, Stack, Button, Divider, Chip } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { projectSummaryActions } from "../../../store/index";
import { useParams } from "react-router-dom";
import moment from "moment";

const FilterTasksModal = () => {
    const dispatch = useDispatch();
    const taskFiltersModal = useSelector((state) => state.projectSummary.taskFiltersModal);
    const taskDetails = useSelector((state) => state.projectSummary.taskDetails);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [date, setDate] = useState('');
    const theme = useTheme();
    let params = useParams();
    const project_id = params.project_id;
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };

    let chipStyle = { cursor: 'pointer', fontSize: '14px', fontWeight: 400 };

    const addFilters = (filterName) => {
        setSelectedFilter((prevState) => {
            if (prevState.includes(filterName)) {
                return prevState.filter((val) => val !== filterName)
            }
            else {
                return [...prevState, filterName]
            }
        });
    }

    const resetFilterHandler = () => {
        dispatch(projectSummaryActions.setFilterSelected({ value: false }));
        setSelectedFilter([]);
        setDate('');
    }

    const applyFilterHandler = () => {
        let filteredTasks = taskDetails.filter((task) => {
            if (selectedFilter.length === 0) {
                return task;
            }
            else {
                return selectedFilter.includes(task.task_type);
            }
        });
        const filteredDate = date ? new Date(moment().subtract(date, 'd').format('YYYY-MM-DD')).valueOf() : null;
        if (filteredDate) {
            filteredTasks = filteredTasks.filter((task) => {
                let taskStartDate = new Date(task.start_date).valueOf();
                let today = new Date().valueOf();
                return taskStartDate <= today && taskStartDate >= filteredDate;
            })
        }
        dispatch(projectSummaryActions.setfilteredTaskDetails({ filteredTaskDetails: filteredTasks }));
        if (!date && selectedFilter.length === 0) {
            dispatch(projectSummaryActions.setFilterSelected({ value: false }));
        }
        else {
            dispatch(projectSummaryActions.setFilterSelected({ value: true }));
        }
        dispatch(projectSummaryActions.setTaskFilterModalClose());
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={taskFiltersModal}
            onClose={() => {
                setSelectedFilter([]);
                dispatch(projectSummaryActions.setTaskFilterModalClose())
            }}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Filter Tasks</Typography>
                    <Divider sx={{ backgroundColor: theme.palette.highlight.main }}></Divider>
                    <Typography variant="subtitle" color="#EE7214">Filter by task type</Typography>
                    <Stack direction="row" gap={1} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Chip
                            label="Work"
                            onClick={() => addFilters('work')}
                            sx={chipStyle}
                            color={selectedFilter.includes('work') ? 'secondary' : 'default'}
                        />
                        <Chip
                            label="Rework"
                            onClick={() => addFilters('rework')}
                            sx={chipStyle}
                            color={selectedFilter.includes('rework') ? 'secondary' : 'default'}
                        />
                        <Chip
                            label="Plan"
                            onClick={() => addFilters('plan')}
                            sx={chipStyle}
                            color={selectedFilter.includes('plan') ? 'secondary' : 'default'}
                        />
                        <Chip
                            label="Learn"
                            onClick={() => addFilters('learn')}
                            sx={chipStyle}
                            color={selectedFilter.includes('learn') ? 'secondary' : 'default'}
                        />
                        <Chip
                            label="External"
                            onClick={() => addFilters('external')}
                            sx={chipStyle}
                            color={selectedFilter.includes('external') ? 'secondary' : 'default'}
                        />
                        <Chip
                            label="Spec Change"
                            onClick={() => addFilters('spec change')}
                            sx={chipStyle}
                            color={selectedFilter.includes('spec change') ? 'secondary' : 'default'}
                        />
                    </Stack>
                    <Typography variant="subtitle" color="#EE7214">Filter by start date</Typography>
                    <Stack direction="row" gap={1} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Chip
                            label="Last 7 days"
                            onClick={() => setDate(7)}
                            sx={chipStyle}
                            color={date == 7 ? 'secondary' : 'default'}
                        />
                        <Chip
                            label="Last 14 days"
                            onClick={() => setDate(14)}
                            sx={chipStyle}
                            color={date == 14 ? 'secondary' : 'default'}
                        />
                        <Chip
                            label="Last 21 days"
                            onClick={() => setDate(21)}
                            sx={chipStyle}
                            color={date == 21 ? 'secondary' : 'default'}
                        />
                        <Chip
                            label="Last 28 days"
                            onClick={() => setDate(28)}
                            sx={chipStyle}
                            color={date == 28 ? 'secondary' : 'default'}
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={resetFilterHandler}>Reset Filters</Button>
                        <Button variant="contained" onClick={applyFilterHandler}>Apply Filters</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default FilterTasksModal;