import { Box, Stack } from "@mui/material";
import EmptyPage from '../EmptyPage';
import React, { useRef, useEffect } from "react";
import TimelinessLoader from "./TimelinessLoader";
import SelectProjects from '../TaskTimeliness/SelectProjects';
import { useDispatch, useSelector } from "react-redux";
import { taskTimelinessActions } from "../../store";
import { fetchAllProjects } from "../../services/projectSummaryServices";
import { useNavigate } from "react-router-dom";

function TaskTimeliness() {
    const projectsFetchedRef = useRef(false);
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.taskTimeliness.projects);
    const navigate = useNavigate();

    const fetchProjects = async () => {
        try {
            dispatch(taskTimelinessActions.setLoader({ value: true }));
            const response = await fetchAllProjects();
            dispatch(taskTimelinessActions.setProjects({ projects: response.data.projects }));
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data Fetched' } }));

        }
        catch (error) {
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (projectsFetchedRef.current) return;
        projectsFetchedRef.current = true;
        fetchProjects();
        dispatch(taskTimelinessActions.setSelectedProjectId({ id: null }));
    }, [projects]);

    return (
        <Box>
            <Stack rowGap={3}>
                <Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <SelectProjects />
                    </Stack>
                </Stack>
                <Stack alignItems='center'>
                    <EmptyPage text="Select a project to view task timeliness details" />
                </Stack>
            </Stack>
            <TimelinessLoader />
        </Box >
    );
}

export default TaskTimeliness;