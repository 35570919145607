import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Stack, Button, TextField } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { collectionsActions } from "../../store";
import { addCollection, fetchCollectionsData } from "../../services/collectionServices";
import { useNavigate } from "react-router-dom";

const AddCollectionModal = () => {
    const addCollectionModal = useSelector((state) => state.collections.addCollectionModal);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
    };

    const [collectionName, setCollectionName] = useState('');
    const [saveButton, setSaveButton] = useState(false);

    useEffect(() => {
        setCollectionName('');
        setSaveButton(false);
    }, [addCollectionModal]);

    const addCollectionHandler = async () => {
        try {
            dispatch(collectionsActions.setLoader({ value: true }));
            await addCollection(collectionName);
            const response = await fetchCollectionsData();
            dispatch(collectionsActions.setCollections({ collections: response.data.collections }));
            dispatch(collectionsActions.setLoader({ value: false }));
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'success', message: 'Collection added successfully !!!' } }));
            dispatch(collectionsActions.setAddCollectionModalClose());
        }
        catch (error) {
            dispatch(collectionsActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={addCollectionModal}
            onClose={() => dispatch(collectionsActions.setAddCollectionModalClose())}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Add Collection</Typography>
                    <TextField
                        FormHelperTextProps={{ sx: { display: 'flex', justifyContent: 'flex-end' } }}
                        helperText={collectionName.length < 3 && collectionName.length > 0 ? 'min 3 and max 100 chars' : `${collectionName.length}/100`}
                        error={collectionName.length < 3 && collectionName.length > 0}
                        id="collection-name"
                        label="Collection Name"
                        variant="outlined"
                        required
                        value={collectionName}
                        onChange={(e) => {
                            if (e.target.value.length <= 100) {
                                setCollectionName(e.target.value);
                                setSaveButton(e.target.value.length >= 3)
                            }
                            else {
                                return null;
                            }
                        }}
                    />
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={() => dispatch(collectionsActions.setAddCollectionModalClose())}>Cancel</Button>
                        <Button disabled={!saveButton} onClick={addCollectionHandler}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default AddCollectionModal;