import React, { useState } from "react";
import { Modal, Box, Typography, Stack, Button, TextField, Divider, Link, Chip, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { leaveActions } from "../../store";
import { fetchOthersLeave, fetchManager, actionOnLeave, reassignLeave } from "../../services/leaveServices";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import moment from "moment";
import { formatLeaveType } from '../../Utils/leaveHelpers';

let baseUrl = process.env.REACT_APP_DEV_BASE_URL;

const LeaveActionModal = ({ style }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [action, setAction] = useState('');
    const [saveButton, setSaveButton] = useState(false);
    const [notes, setNotes] = useState('');
    const [managers, setManagers] = useState([]);
    const [selectedManager, setSelectedManager] = useState('');

    const leaveActionModal = useSelector((state) => state.leave.leaveActionModal);
    const selectedLeaveData = useSelector((state) => state.leave.selectedLeaveData);
    const loggedInUser = useSelector((state) => state.user.userEmail);

    const resetData = () => {
        setAction('');
        setSelectedManager('');
        setNotes('');
        setManagers([]);
        setSaveButton(false);
    }

    const getManagers = async () => {
        try {
            dispatch(leaveActions.setLoader({ value: true }));
            const response = await fetchManager();
            setManagers(response.data.employees);
            dispatch(leaveActions.setLoader({ value: false }));
        }
        catch (error) {
            dispatch(leaveActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const applyActionOnLeaveHandler = async () => {
        try {
            dispatch(leaveActions.setLoader({ value: true }));
            if (action === 'approve' || action === 'decline') {
                let data = { notes: notes };
                const response = await actionOnLeave(selectedLeaveData.leave_id, action, data);
                dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'success', message: response?.data?.msg || '' } }));
            }
            else if (action === 'reassign') {
                let data = { leave_id: selectedLeaveData.leave_id, manager: selectedManager, notes: notes };
                const response = await reassignLeave(data);
                dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'success', message: response?.data?.msg || '' } }));
            }
            const othersLeave = await fetchOthersLeave();
            dispatch(leaveActions.setOthersLeave({ othersLeave: othersLeave.data.allLeaves }));
            dispatch(leaveActions.setFilteredOthersLeave({ filteredOthersLeave: othersLeave?.data?.allLeaves.filter((leave) => leave.status === "pending") }));
            dispatch(leaveActions.setLoader({ value: false }));
            dispatch(leaveActions.setleaveActionModalClose());
            dispatch(leaveActions.resetFilters());
            resetData();
        }
        catch (error) {
            dispatch(leaveActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={leaveActionModal}
            onClose={() => {
                dispatch(leaveActions.setleaveActionModalClose());
                resetData();
            }}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Approve/Decline/Reassign leave</Typography>
                    <Divider />

                    <Stack spacing={-1}>
                        <Typography variant="overline" sx={{ color: theme.palette.highlight.main }}>Manager</Typography>
                        <Typography variant="body">{selectedLeaveData?.approver}</Typography>
                    </Stack>

                    <Stack rowGap={1}>
                        <Typography variant="overline" sx={{ color: '#f57c00' }}>LEAVE TYPE & DATE</Typography>
                        <Stack spacing={0}>
                            <Typography variant="caption" sx={{ color: theme.palette.highlight.main }}>Leave Type</Typography>
                            <Typography variant="body">{formatLeaveType(selectedLeaveData?.leave_type)}</Typography>
                        </Stack>
                        <Stack spacing={0}>
                            <Typography variant="caption" sx={{ color: theme.palette.highlight.main }}>Leave Dates</Typography>
                            <Typography variant="body">{moment(selectedLeaveData?.from).format('DD-MMM-YYYY')}   to   {moment(selectedLeaveData?.to).format('DD-MMM-YYYY')}</Typography>
                        </Stack>
                    </Stack>

                    {selectedLeaveData?.notes &&
                        <Stack spacing={0}>
                            <Typography variant="overline" sx={{ color: '#f57c00' }}>NOTES</Typography>
                            <Typography variant="body2">{selectedLeaveData?.notes}</Typography>
                        </Stack>
                    }

                    {selectedLeaveData?.file && <Stack spacing={0}>
                        <Typography variant="overline" sx={{ color: '#f57c00' }}>attachment</Typography>
                        <Typography variant="body">
                            <Link
                                color='#1565c0'
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                    window.open(baseUrl + '/public/attachment/' + selectedLeaveData.file)
                                }}
                            >
                                {selectedLeaveData.file}
                            </Link>
                        </Typography>
                    </Stack>
                    }

                    <Stack spacing={0}>
                        <Typography variant="overline" sx={{ color: '#f57c00' }}>LEAVE ACTION</Typography>
                        <Stack direction="row" gap={1}>
                            <Chip
                                label="APPROVE"
                                onClick={(e) => {
                                    setAction('approve');
                                    setNotes('');
                                    setSaveButton(true);
                                }}
                                sx={{ cursor: 'pointer', fontSize: '14px', fontWeight: 350 }}
                                color={action === 'approve' ? 'primary' : 'default'}
                            />
                            <Chip
                                label="DECLINE"
                                onClick={(e) => {
                                    setNotes('');
                                    setAction('decline');
                                    setSaveButton(false);
                                }}
                                sx={{ cursor: 'pointer', fontSize: '14px', fontWeight: 350 }}
                                color={action === 'decline' ? 'primary' : 'default'}
                            />
                            <Chip
                                label="REASSIGN"
                                onClick={(e) => {
                                    setNotes('');
                                    setAction('reassign');
                                    getManagers();
                                    setSaveButton(false);
                                }}
                                sx={{ cursor: 'pointer', fontSize: '14px', fontWeight: 350 }}
                                color={action === 'reassign' ? 'primary' : 'default'}
                            />
                        </Stack>
                    </Stack>

                    {
                        action === 'reassign' &&
                        <TextField
                            sx={{ width: '50%', svg: { color: theme.palette.svg.main } }}
                            id="select manager"
                            required
                            label="Select Manager"
                            variant="outlined"
                            select
                            value={selectedManager}
                            SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }}
                            onChange={(e) => {
                                setSelectedManager(e.target.value);
                                setSaveButton(e.target.value && action === 'reassign' && notes.length >= 3);
                            }}
                        >
                            {managers.map((manager, index) => (
                                <MenuItem
                                    key={manager.emp_id + manager.email}
                                    value={manager.email}
                                    sx={{ padding: 1 }}
                                    disabled={manager.email === loggedInUser}
                                >
                                    {manager.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    }

                    {
                        (action === 'decline' || action === 'reassign') &&
                        <TextField
                            FormHelperTextProps={{ sx: { display: 'flex', justifyContent: 'flex-end' } }}
                            helperText={notes.length < 3 && notes.length > 0 ? 'min 3 and max 100 chars' : `${notes.length}/100`}
                            error={notes.length < 3 && notes.length > 0}
                            id="notes"
                            label="Notes"
                            variant="outlined"
                            value={notes}
                            required
                            onChange={(e) => {
                                if (e.target.value.length <= 100) {
                                    setNotes(e.target.value);
                                    setSaveButton(e.target.value.length >= 3 && ((action === 'decline') || (action === 'reassign' && selectedManager)));
                                }
                            }}
                        />
                    }

                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button
                            onClick={() => {
                                dispatch(leaveActions.setleaveActionModalClose());
                                resetData();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button disabled={!saveButton} onClick={applyActionOnLeaveHandler}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default LeaveActionModal;