import { useTheme } from '@emotion/react';
import { Box, Stack, FormGroup, FormControlLabel, Checkbox, Divider } from '@mui/material';
import { Chart, registerables } from 'chart.js';
import { SankeyController, Flow } from 'chartjs-chart-sankey';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allocationActions } from '../../store';
import { fetchAllocationsData } from '../../services/allocationServices';
import { useNavigate } from 'react-router-dom';

const ShankeyChart = (props) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const result = useSelector((state) => state.allocation.result);
    const contributors = useSelector((state) => state.allocation.contributors);
    const startDate = useSelector((state) => state.allocation.startDate);
    const duration = useSelector((state) => state.allocation.duration);
    const selectedCollections = useSelector((state) => state.allocation.selectedCollections);
    const selectedProjects = useSelector((state) => state.allocation.selectedProjects);
    const allContributorSelected = useSelector((state => state.allocation.allContributorSelected));

    const contributorChangeHandler = async (e, triggeredFrom) => {
        let filteredUsers;
        if (triggeredFrom === 'contributors') {
            filteredUsers = contributors.filter((user) => user.user_id !== e.target.value).filter((contributor) => !contributor.selected).map((contributor) => contributor.user_id);
            if (!e.target.checked) {
                filteredUsers.push(e.target.value);
            }
        }
        else {
            filteredUsers = e.target.checked ? [] : contributors.map((contributor) => contributor.user_id);
        }

        try {
            let data = {
                startDate,
                duration,
                requested_collections: [selectedCollections],
                requested_projects: selectedProjects,
                user_id: filteredUsers,
            }
            dispatch(allocationActions.setLoader({ value: true }));
            const response = await fetchAllocationsData(data);
            dispatch(allocationActions.setContributors({ contributors: response.data.user_names }));
            props.checkAllSelected(response.data.user_names);
            dispatch(allocationActions.setResult({ result: response.data.result }));
            dispatch(allocationActions.setLoader({ value: false }));
            dispatch(allocationActions.setSnackbar({ value: { open: true, type: 'success', message: `${response.data.result.length === 0 ? 'No data for current selection' : 'Data fetched !!!'}` } }));
        }
        catch (error) {
            dispatch(allocationActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(allocationActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        const chartBox = document.querySelector("#chartReport");
        chartBox.innerHTML = '<canvas id="chart" ></canvas>';
        const ctx = document.getElementById("chart")?.getContext("2d");
        Chart.register(SankeyController, Flow);
        Chart.register(...registerables);

        const colors2 = ['#fff5eb', '#fee6ce', '#fdd0a2', '#fdae6b', '#fd8d3c', '#f16913', '#d94801', '#a63603', '#7f2704'];
        const assigned = {};

        function c2(name) {
            return assigned[name] || (assigned[name] = colors2[Object.keys(assigned).length % colors2.length]);
        }
        const chartData = result;
        if (chartData.length > 0) {
            let chart = new Chart(ctx, {
                type: 'sankey',
                data: {
                    datasets: [{
                        data: chartData,
                        colorFrom: (c) => c2(c.dataset.data[c.dataIndex].from),
                        colorTo: (c) => c2(c.dataset.data[c.dataIndex].to),
                        borderWidth: 0
                    }]
                }
            });
        }
    }, [result]);

    return (
        <Box sx={{
            backgroundColor: theme.palette.surface1.main, color: theme.palette.surface1.contrastText, width: '100%', borderRadius: '10px', textAlign: 'center', marginTop: 1, padding: 1
        }}>
            <Box sx={{ backgroundColor: theme.palette.surface.main, width: '100%', borderRadius: '10px', textAlign: 'center', padding: 2 }}>
                <Stack direction="row" justifyContent="space-between" columnGap={2}>
                    <FormGroup sx={{
                        width: '25%',
                        backgroundColor: theme.palette.lighter.main,
                        padding: 2,
                        height: '58vh',
                        overflowY: 'scroll',
                    }}>
                        <Stack>
                            <FormControlLabel control={<Checkbox checked={allContributorSelected}
                                onChange={(e) => contributorChangeHandler(e, 'selectAll')}
                            />}
                                label={"Select All"}
                            />
                            <Divider></Divider>
                            {
                                contributors.map((contributor) => {
                                    return <FormControlLabel
                                        key={contributor.user_id}
                                        control={<Checkbox checked={contributor.selected === 1}
                                            value={contributor.user_id}
                                            onChange={(e) => contributorChangeHandler(e, 'contributors')}
                                        />}
                                        label={contributor.user_name}
                                    />
                                })
                            }
                        </Stack>
                    </FormGroup>
                    <Box id="chartReport" sx={{ width: '75%', height: '58vh' }}></Box>
                </Stack>
            </Box>
        </Box>
    );
}

export default ShankeyChart;
