import { createSlice } from "@reduxjs/toolkit";

const initialState = { open: true }

const drawerSlice = createSlice({
    name: 'drawer',
    initialState: initialState,
    reducers: {
        openDrawer(state, action) {
            state.open = true;
        },
        closeDrawer(state, action) {
            state.open = false;
        }
    }
});

export default drawerSlice;