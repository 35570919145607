import { TextField, MenuItem, Typography, } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { allocationActions } from "../../store";

const CollectionsDropdown = (props) => {
    const dispatch = useDispatch();
    const { fetchAllocationData } = props;
    const collections = useSelector((state) => state.allocation.collections);
    const duration = useSelector((state) => state.allocation.duration);
    const startDate = useSelector((state) => state.allocation.startDate);
    const selectedProjects = useSelector((state) => state.allocation.selectedProjects);
    const selectedCollection = useSelector((state) => state.allocation.selectedCollection);

    const collectionChangeHandler = (collectionId) => {
        const data = {
            startDate,
            duration,
            requested_collections: collectionId === -1 ? collections.map((collection) => collection.collection_id) : [collectionId],
            requested_projects: selectedProjects,
        }

        collectionId === -1 ? fetchAllocationData(data, 'allCollections') : fetchAllocationData(data, 'collections');
    }

    return (
        <TextField id="collections" label="COLLECTION" variant="filled" select
            required
            value={selectedCollection}
            FormHelperTextProps={{ sx: { marginLeft: 0 } }}
            SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }} sx={{ width: '300px' }}
            InputProps={{ style: { backgroundColor: '#FFFFFF' } }}
            onChange={(e) => {
                dispatch(allocationActions.setSelectedCollections({ selectedCollections: e.target.value }));
                dispatch(allocationActions.setSelectedCollection({ selectedCollection: e.target.value }));
                collectionChangeHandler(e.target.value);
            }}
        >
            {
                collections.map((collection, i) => {
                    return (<MenuItem
                        sx={{ padding: 1.5 }}
                        key={collection.collection_id}
                        value={collection.collection_id}
                    >
                        <Typography sx={{ fontWeight: 350 }}>{collection.collection_name}</Typography>
                    </MenuItem>)
                })
            }
        </TextField>
    );
}

export default CollectionsDropdown;