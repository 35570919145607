import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import Months from "./Months";
import DetailsCard from "./DetailsCard";

const IssuesData = () => {
    const theme = useTheme();
    const issuesData = useSelector((state) => state.github.issues);

    const style = {
        backgroundColor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        minheight: '70vh', width: '100%', borderRadius: '10px',
        padding: 5
    }

    return (
        <Box sx={style}>
            <Stack rowGap={3}>
                <Months />
                {issuesData?.defect && <Stack rowGap={1}>
                    <Typography variant="h6">Defects</Typography>
                    <Stack direction="row" columnGap={8}>
                        <DetailsCard data={issuesData?.defect?.high} label="high-severity" color={issuesData?.defect?.high?.count === 0 ? '#98d8aa' : '#db4437d9'} />
                        <DetailsCard data={issuesData?.defect?.medium} label="medium-severity" color={issuesData?.defect?.medium?.count === 0 ? '#98d8aa' : '#f4b400'} />
                        <DetailsCard data={issuesData?.defect?.low} label="low-severity" color={issuesData?.defect?.low?.count === 0 ? '#98d8aa' : '#f3e99f'} />
                        <DetailsCard data={issuesData?.defect?.unclassified} label="unclassified" color={issuesData?.defect?.unclassified?.count === 0 ? '#98d8aa' : '#f4b400'} />
                    </Stack>
                </Stack>
                }
                {issuesData?.gap && <Stack rowGap={1}>
                    <Typography variant="h6">Gaps</Typography>
                    <Stack direction="row" columnGap={8}>
                        <DetailsCard data={issuesData?.gap?.high} label="high-severity" color={issuesData?.gap?.high?.count === 0 ? '#98d8aa' : '#db4437d9'} />
                        <DetailsCard data={issuesData?.gap?.medium} label="medium-severity" color={issuesData?.gap?.medium?.count === 0 ? '#98d8aa' : '#f4b400'} />
                        <DetailsCard data={issuesData?.gap?.low} label="low-severity" color={issuesData?.gap?.low?.count === 0 ? '#98d8aa' : '#f3e99f'} />
                        <DetailsCard data={issuesData?.gap?.unclassified} label="unclassified" color={issuesData?.gap?.unclassified?.count === 0 ? '#98d8aa' : '#f4b400'} />
                    </Stack>
                </Stack>
                }
                {issuesData?.bug && <Stack rowGap={1}>
                    <Typography variant="h6">Bugs</Typography>
                    <Stack direction="row" columnGap={8}>
                        <DetailsCard data={issuesData?.bug?.high} label="high-severity" color={issuesData?.bug?.high?.count === 0 ? '#98d8aa' : '#db4437d9'} />
                        <DetailsCard data={issuesData?.bug?.medium} label="medium-severity" color={issuesData?.bug?.medium?.count === 0 ? '#98d8aa' : '#f4b400'} />
                        <DetailsCard data={issuesData?.bug?.low} label="low-severity" color={issuesData?.bug?.low?.count === 0 ? '#98d8aa' : '#f3e99f'} />
                        <DetailsCard data={issuesData?.bug?.unclassified} label="unclassified" color={issuesData?.bug?.unclassified?.count === 0 ? '#98d8aa' : '#f4b400'} />
                    </Stack>
                </Stack>
                }
            </Stack>
        </Box >
    );
}

export default IssuesData;