import React from "react";
import { Typography, CardActions, CardContent, Button, Stack } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import { collectionsActions, checkSnapshotActions } from "../../store/index";
import AllCollectionModal from "./AllCollectionModal";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const CollectionCard = (props) => {
    let { data } = props;
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    let manager = useSelector((state) => state.user.manager);

    return (
        <>
            <CardContent>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5" gutterBottom marginTop={1}>{data.collection_name}</Typography>
                    {
                        data.collection_id !== 1
                        &&
                        <MoreVertIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={
                                (event) => {
                                    dispatch(collectionsActions.handleOpenCollectionNameMenu({ currentTarget: event.currentTarget }));
                                    dispatch(collectionsActions.setSelectedCollectionIDinCollectionMenu({ collection_id: data.collection_id }));
                                    dispatch(collectionsActions.setContributors(
                                        { contributors: { collection_name: data.collection_name, contributors: [] } }
                                    ));
                                    dispatch(collectionsActions.setSelectedCollectionNameinCollectionMenu({ collection_name: data.collection_name }));
                                }
                            }
                        />
                    }
                </Stack>
                {
                    data.projects.map((project, index) => {
                        if (index < 4) {
                            return (
                                <Stack direction="row" alignItems="center" justifyContent="space-between" marginTop={0.5} key={project.project_id}>
                                    <Stack>
                                        <Typography variant="h7" color={theme.palette.card.contrastText1} sx={{ cursor: `${project.project_uid ? 'pointer' : ''}` }} onClick={() => {
                                            if (project.project_uid) {
                                                navigate(`/project-summary/${project.project_uid}`);
                                            }
                                        }}>{project.project_name}</Typography>
                                        <Typography variant="caption" color={theme.palette.highlight.main}>{(project.last_snapshot && `${project.last_snapshot} days since last snapshot`) || 'No snapshot uploaded'}</Typography>
                                    </Stack>
                                    {
                                        manager &&
                                        <MoreVertIcon
                                            sx={{ cursor: 'pointer' }}
                                            onClick={(event) => {
                                                dispatch(collectionsActions.setSnapshotExist({ snapshotExist: !!project.last_snapshot }))
                                                dispatch(collectionsActions.handleOpenProjectNameMenu({ currentTarget: event.currentTarget }));
                                                dispatch(collectionsActions.setSelectedCollectionIDinCollectionMenu({ collection_id: data.collection_id }));
                                                dispatch(collectionsActions.setSelectedProjectIDinProjectMenu({ project_id: project.project_id }));
                                                dispatch(checkSnapshotActions.setSelectedProject({ selectedProject: { project_uid: project.uid, id: project.project_id } }));
                                                dispatch(checkSnapshotActions.setNavigate({ navigate: '/collections' }));
                                            }}
                                        />
                                    }
                                </Stack>
                            );
                        };
                    })
                }
            </CardContent>
            <CardActions>
                <Button
                    size="medium"
                    sx={{ color: theme.palette.secondary.main }}
                    onClick={() => {
                        dispatch(collectionsActions.setCollectionModalOpen({ id: data.collection_id }));
                        dispatch(collectionsActions.setSelectedCollectionIDinCollectionMenu({ collection_id: data.collection_id }));
                    }}
                >
                    {data.projects.length > 4 ? `SEE ${data.projects.length - 4} MORE` : 'OPEN COLLECTION'}
                </Button>
            </CardActions>
            <AllCollectionModal collection={data} />
        </>
    );
}

export default CollectionCard;