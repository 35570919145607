import { useTheme } from "@emotion/react";
import { Box, Stack, Breadcrumbs, Typography, TextField, Button, TableContainer, TableCell, Table, TableHead, TableRow, TableBody, MenuItem, TablePagination, TableSortLabel } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FilterListIcon from '@mui/icons-material/FilterList';
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { fetchTaskDetails, fetchAllProjects, fetchSnapshotDates } from '../../../services/projectSummaryServices';
import { projectSummaryActions } from '../../../store';
import ProjectSummaryLoader from "../ProjectSummaryLoader";
import FilterTasksModal from "./FilterTasksModal";
import { paginationResult } from '../../../Utils/TableColumnSort';

const TaskDetails = () => {
    const tasksDetails = useRef(false);
    const theme = useTheme();
    let params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const project_uid = params.project_uid;
    const snapshot_date = params.snapshot_date;
    const projects = useSelector((state) => state.projectSummary.projects);
    const projectSnapshotDates = useSelector((state) => state.projectSummary.projectSnapshotDates);
    const taskDetails = useSelector((state) => state.projectSummary.taskDetails);
    const filteredTaskDetails = useSelector((state) => state.projectSummary.filteredTaskDetails);
    const selectedSnapshotDate = useSelector((state) => state.projectSummary.selectedSnapshotDate);
    const pages = [5, 10, 20];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pages[1]);
    const [snapshotDate, setSnapshotDate] = useState(selectedSnapshotDate);
    const filtersSelected = useSelector((state) => state.projectSummary.filtersSelected);
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();

    let selectedProject = null;
    projects.forEach((project) => {
        project.projects.forEach((prjct) => {
            if (prjct.project_uid === project_uid) {
                selectedProject = prjct
            }
        });
    });

    const Boxstyle = {
        width: '100%', borderRadius: '10px',
        padding: 3, marginTop: 5
    }

    const CardStyle = {
        backgroundColor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        minheight: '75vh', width: '100%', borderRadius: '10px',
        paddingTop: 4, paddingBottom: 4, paddingLeft: 2, paddingRight: 2
    }

    const columns = ['Task Name', 'Assignee', 'Start Date', 'End Date', 'On CP?', 'Task Type'];
    const fields = ['task_title', 'user_name', 'start_date', 'end_date', 'on_cp', 'task_type'];

    const handleSortRequest = (cellId) => {
        if (!order) {
            setOrder("asc")
            setOrderBy(cellId);
        }
        else if (order === "asc") {
            setOrder("desc");
            setOrderBy(cellId);
        }
        else {
            setOrder();
            setOrderBy();
        }
    }

    const getTaskDetails = async (e) => {
        try {
            setOrder();
            setOrderBy();
            dispatch(projectSummaryActions.setFilterSelected({ value: false }));
            setSnapshotDate(e.target.value);
            setPage(0);
            dispatch(projectSummaryActions.setLoader({ value: true }));
            const projects = await fetchAllProjects();
            dispatch(projectSummaryActions.setProjects({ projects: projects.data.projects }));
            const projectSnapshotDates = await fetchSnapshotDates(project_uid);
            dispatch(projectSummaryActions.setProjectSnapshotDates({ snapshots: projectSnapshotDates.data.snapshotDates }));
            const response = await fetchTaskDetails(project_uid, e.target.value);
            dispatch(projectSummaryActions.setTaskDetails({ taskDetails: response.data.task_details }));
            dispatch(projectSummaryActions.setfilteredTaskDetails({ filteredTaskDetails: response.data.task_details }));
            dispatch(projectSummaryActions.setLoader({ value: false }));
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data fetched !!!' } }));
        }
        catch (error) {
            dispatch(projectSummaryActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    }

    useEffect(() => {
        if (tasksDetails.current) return;
        tasksDetails.current = true;
        getTaskDetails({ target: { value: snapshot_date } })
    });

    return (
        <Box style={Boxstyle}>
            <Stack rowGap={4}>
                <Breadcrumbs
                    color={theme.palette.highlight.main}
                    separator={<NavigateNextIcon fontSize="medium" large="true" />}
                    aria-label="breadcrumb"
                >
                    <Typography onClick={() => navigate(`/project-summary/${project_uid}`)} variant="h5" sx={{ color: theme.palette.active.main, cursor: 'pointer' }}>{selectedProject?.project_name + ' Project Summary'}</Typography>
                    <Typography variant="h5" sx={{ color: theme.palette.highlight.main }}>{selectedProject?.project_name + ' Task Details'}</Typography>
                </Breadcrumbs>
                <Box sx={CardStyle}>
                    <Stack rowGap={3}>
                        <Stack direction="row" alignItems="center" columnGap={5}>
                            <TextField id="search-in-taskdetail" label="Search" variant="filled"
                                InputProps={{ style: { backgroundColor: theme.palette.searchInput.main, color: theme.palette.searchInput.contrastText } }}
                                sx={{ width: '60%' }}
                                onChange={(e) => {
                                    setPage(0);
                                    const seachedText = e.target.value.toLowerCase();
                                    const filteredTasks = taskDetails.filter((task) => {
                                        return (task.task_title.toLowerCase().includes(seachedText) || task.user_name.toLowerCase().includes(seachedText))
                                    });
                                    dispatch(projectSummaryActions.setfilteredTaskDetails({ filteredTaskDetails: filteredTasks }));
                                }}
                            />
                            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '40%' }}>
                                <TextField id="snapshot-dates" label="Snapshot Date" required variant="filled" select
                                    InputProps={{ style: { backgroundColor: '#FFFFFF' } }}
                                    SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }} sx={{ width: '60%' }}
                                    value={snapshotDate}
                                    onChange={getTaskDetails}
                                >
                                    {
                                        projectSnapshotDates.map((snapshot, i) => {
                                            return (<MenuItem
                                                sx={
                                                    { fontWeight: 300, padding: 1.5 }
                                                }
                                                key={snapshot.snapshot_date}
                                                value={snapshot.snapshot_date}
                                            >
                                                <Stack width="100%" direction="row" justifyContent="space-between" alignItems="end">
                                                    <Typography sx={{ fontWeight: 350 }}>{snapshot.snapshot_date}</Typography>
                                                    <Typography sx={{ color: theme.palette.highlight.main }} variant="caption">{snapshot.reference ? 'Reference' : ''}</Typography>
                                                </Stack>
                                            </MenuItem>)
                                        })
                                    }
                                </TextField>
                                <Button variant={filtersSelected ? "contained" : "outlined"} color="secondary" size="large" onClick={() => {
                                    dispatch(projectSummaryActions.setTaskFilterModalOpen());
                                }}>
                                    <Stack direction="row" justifyContent="space-between" columnGap={1} alignItems="center">
                                        <FilterListIcon style={{ fontSize: 25, marginLeft: 0 }} />
                                        <Typography variant="subtitle1">Filters</Typography>
                                    </Stack>
                                </Button>
                            </Stack>
                        </Stack>
                        <Box style={{
                            backgroundColor: theme.palette.surface.main,
                            color: theme.palette.surface.contrastText,
                            padding: '1.5%', borderRadius: '10px'
                        }}>
                            <TableContainer sx={{
                                backgroundColor: theme.palette.lighter.main,
                                borderRadius: '10px',
                                minheight: '500px',
                                "&::-webkit-scrollbar": { width: 0 }
                            }}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {
                                                columns.map((column, i) => {
                                                    return (
                                                        <TableCell key={column} sx={{ backgroundColor: theme.palette.light.main }}>
                                                            <TableSortLabel
                                                                sx={{
                                                                    '&.MuiTableSortLabel-root': {
                                                                        color: theme.palette.light.contrastText,
                                                                        '& .MuiTableSortLabel-icon': {
                                                                            color: theme.palette.light.contrastText
                                                                        }
                                                                    },
                                                                }}
                                                                active={orderBy === fields[i]}
                                                                direction={orderBy === fields[i] ? order : "asc"}
                                                                onClick={() => { handleSortRequest(fields[i]) }}>
                                                                {column}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            paginationResult(filteredTaskDetails, order, orderBy, page, rowsPerPage).map((task, i) => {
                                                return (
                                                    <TableRow key={task.uid}>
                                                        <TableCell>{task.task_title}</TableCell>
                                                        <TableCell sx={{ color: "#EE7214" }}>{task.user_name}</TableCell>
                                                        <TableCell>{moment(task.start_date).format('DD-MMM')}</TableCell>
                                                        <TableCell>{moment(task.end_date).format('DD-MMM')}</TableCell>
                                                        <TableCell>{task.on_cp ? 'Yes' : 'No'}</TableCell>
                                                        <TableCell>{task.task_type}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{
                                    '& .MuiIconButton-root': {
                                        color: theme.palette.surface.contrastText,
                                    },
                                    '& .Mui-disabled': {
                                        color: theme.palette.disabled.main,
                                    },
                                    '& .MuiTablePagination-toolbar': {
                                        color: theme.palette.surface.contrastText
                                    }
                                }}
                                rowsPerPageOptions={pages}
                                component="div"
                                count={filteredTaskDetails.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                showFirstButton={true}
                                showLastButton={true}
                            />
                        </Box>
                    </Stack>
                </Box>
            </Stack>
            <ProjectSummaryLoader />
            <FilterTasksModal />
        </Box>
    );
}

export default TaskDetails;