import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: false,
    snackbar: { open: false, type: 'success', message: '' },
    selectedProjectId: null,
    projects: [],
    projectSnapshotDates: [],
    comparedToSnapshots: [],
    selectedSnapshotDate: '',
    selectedComparedTo: '',
    projectSummary: null,
    projectNotes: [],
    notesModal: false,
    effortProgress: 0,
    effortProgressOn: false,
    taskDetails: [],
    filteredTaskDetails: [],
    taskFiltersModal: false,
    filtersSelected: false,
}

const projectSummarySlice = createSlice({
    name: 'projectSummary',
    initialState: initialState,
    reducers: {
        setLoader(state, action) {
            state.loader = action.payload.value;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload.value;
        },
        setSelectedProjectId(state, action) {
            state.selectedProjectId = action.payload.id;
        },
        setProjects(state, action) {
            state.projects = action.payload.projects;
        },
        setProjectSnapshotDates(state, action) {
            state.projectSnapshotDates = action.payload.snapshots
        },
        setComparedToSnapshotDates(state, action) {
            let index = 0;
            state.projectSnapshotDates.forEach((obj, i) => {
                if (obj.snapshot_date === state.selectedSnapshotDate) {
                    index = i;
                }
            });
            state.comparedToSnapshots = state.projectSnapshotDates.slice(index + 1);
        },
        setSelectedSnapshotDate(state, action) {
            state.selectedSnapshotDate = action.payload.selectedSnapshotDate;
        },
        setSelectedComparedToDate(state, action) {
            state.selectedComparedTo = action.payload.selectedComparedTo;
        },
        setProjectSummary(state, action) {
            state.projectSummary = action.payload.projectSummary;
        },
        setProjectNotes(state, action) {
            state.projectNotes = action.payload.notes;
        },
        setNotesModalOpen(state, action) {
            state.notesModal = true;
        },
        setNotesModalClose(state, action) {
            state.notesModal = false;
        },
        setProgressEffort(state, action) {
            state.effortProgress = action.payload.effort;
        },
        setEffortProgressOn(state, action) {
            state.effortProgressOn = true;
        },
        setEffortProgressOff(state, action) {
            state.effortProgressOn = false;
        },
        setTaskDetails(state, action) {
            state.taskDetails = action.payload.taskDetails;
        },
        setfilteredTaskDetails(state, action) {
            state.filteredTaskDetails = action.payload.filteredTaskDetails;
        },
        setTaskFilterModalOpen(state, action) {
            state.taskFiltersModal = true;
        },
        setTaskFilterModalClose(state, action) {
            state.taskFiltersModal = false;
        },
        setFilterSelected(state, action) {
            state.filtersSelected = action.payload.value;
        }
    }
});

export default projectSummarySlice;