import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const months = [];
for (let i = 0; i < 6; i++) {
    months.push({
        key: i,
        month_name: moment().subtract(i, "month").startOf("month").format('MMMM YYYY'),
        value: i === 0 ? moment().startOf('month').format('YYYY-MM-DD') + 'T00:00:00' : moment().subtract(i, "month").startOf('month').format('YYYY-MM-DD') + 'T00:00:00'
    });
}

const initialState = {
    loader: false,
    snackbar: { open: false, type: 'success', message: '' },
    projects: [],
    selectedProjectId: null,
    contributors: [],
    issues: null,
    selectedContributor: null,
    months: months,
    selectedMonth: months[0].value,
}

const githubSlice = createSlice({
    name: 'github',
    initialState: initialState,
    reducers: {
        setLoader(state, action) {
            state.loader = action.payload.value;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload.value;
        },
        setProjects(state, action) {
            state.projects = action.payload.projects;
        },
        setSelectedProjectId(state, action) {
            state.selectedProjectId = action.payload.id;
        },
        setContributors(state, action) {
            state.contributors = action.payload.contributors;
        },
        setIssues(state, action) {
            state.issues = action.payload.issues;
        },
        setSelectedContributor(state, action) {
            state.selectedContributor = action.payload.selectedContributor;
        },
        setMonth(state, action) {
            state.selectedMonth = action.payload.month;
        }
    }
});

export default githubSlice;