import { useDispatch, useSelector } from "react-redux";
import { selfDirectednessActions } from "../../store";
import { useTheme } from "@emotion/react";
import { Stack, Box, Modal, Typography } from "@mui/material";

const ViewFeedbackModal = () => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const feedbackModal = useSelector((state) => state.selfDirectedness.feedbackModal);
    const feedbackToView = useSelector((state) => state.selfDirectedness.feedbackToView);
    const feedback = useSelector((state) => state.selfDirectedness.feedback);

    let feedbackData = feedback.filter((data) => data.feedback_id === feedbackToView)[0];
    let questions = [];
    for (let i = feedbackData?.questions?.length - 1; i >= 0; i--) {
        questions.push(feedbackData?.questions[i]);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
        maxHeight: '95vh',
        overflowY: 'scroll'
    };

    const fetchHeading = (ques_id) => {
        switch (ques_id) {
            case 1: return '1: Initiative';
            case 2: return '2: Conceptual ability';
            case 3: return '3: Self-discipline';
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={feedbackModal}
            onClose={() => {
                dispatch(selfDirectednessActions.setFeedbackModalClose());
            }}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    {
                        questions?.map((question) => {
                            return (
                                <Stack rowGap={2} key={question.ques_id}>
                                    <Typography variant="h6">{fetchHeading(question.ques_id)}</Typography>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={5}>
                                        <Typography variant="body">{question.question} {feedbackData.contributor_name} ?</Typography>
                                        <Typography variant="body" sx={{ color: theme.palette.highlight.main }}>score : {question.score}</Typography>
                                    </Stack>
                                    <Typography variant="body" sx={{ color: theme.palette.highlight.main }}>{question.selected_option}</Typography>
                                </Stack>
                            )
                        })
                    }
                    <Stack rowGap={2} key={'thisIsComment'} sx={{ width: '100%' }}>
                        <Typography variant="h6">4: Comment</Typography>
                        <Typography variant="body" sx={{ color: theme.palette.highlight.main, wordWrap: 'break-word' }}>{feedbackData?.comments || '--'}</Typography>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    )
}

export default ViewFeedbackModal;