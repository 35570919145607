import { Typography, TextField, MenuItem, Autocomplete } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { contributorDetailActions } from "../../store";
import { useTheme } from "@emotion/react";

const ContributorDropdown = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const contributors = useSelector((state) => state.contributorDetail.contributors);
    const selectedContributor = useSelector((state) => state.contributorDetail.selectedContributor);

    return (
        <Autocomplete
            options={contributors}
            getOptionLabel={(contributor) => contributor.user_name}
            style={{ width: '22%' }}
            sx={{ svg: { color: theme.palette.svg.main } }}
            autoHighlight={false}
            renderInput={(params) => (
                <TextField {...params}
                    label="CONTRIBUTOR" variant="filled"
                    style={{ backgroundColor: theme.palette.searchInput.main }}
                    value={selectedContributor === null ? '' : selectedContributor?.user_name}
                />)}
            renderOption={
                (props, contributor) => (
                    <MenuItem
                        {...props}
                        style={{ fontWeight: 300, padding: '0.8rem' }}
                        key={contributor.user_id + contributor.user_email}
                        value={JSON.stringify({
                            user_id: contributor.user_id,
                            user_email: contributor.user_email,
                            user_name: contributor.user_name
                        })}
                    >
                        <Typography sx={{ fontWeight: 350 }}>{contributor.user_name}</Typography>
                    </MenuItem>
                )}
            onChange={(e, newValue) => {
                dispatch(contributorDetailActions.setSelectedContributor({ contributor: newValue }));
                dispatch(contributorDetailActions.setContributorData({ contributorData: [] }));
                dispatch(contributorDetailActions.setSelectedComparedToDate({ selectedComparedTo: '' }));
                dispatch(contributorDetailActions.resetFilters());
            }}
        />
    );
}

export default ContributorDropdown;