import { useTheme } from "@emotion/react";
import { Box, Stack, Button, Typography, RadioGroup, FormControlLabel, TextField, Radio } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { selfDirectednessActions } from "../../store";
import { useNavigate, useParams } from "react-router-dom";
import { fetchFeedbacks, postFeedback } from "../../services/selfDirectednessService";
import { useDispatch } from "react-redux";


const EditFeedback = () => {
    const editRef = useRef();
    const theme = useTheme();
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [feedback, setFeedback] = useState({
        contributor_email: '',
        contributor_name: '',
        comment: '',
        score1: 0,
        score2: 0,
        score3: 0,
        from: '',
        to: '',
        response1: null,
        response2: null,
        response3: null,
    });
    const [questions, setQuestions] = useState([]);

    const feedback_id = params.feedback_id;

    const style = { backgroundColor: theme.palette.surface1.main, width: '100%', borderRadius: '10px', marginTop: 1, color: theme.palette.surface1.contrastText };

    const fetchHeading = (ques_id) => {
        switch (ques_id) {
            case 1: return '1: Initiative';
            case 2: return '2: Conceptual ability';
            case 3: return '3: Self-discipline';
        }
    }

    const fetchDefaultOption = (ques_id) => {
        switch (ques_id) {
            case 1: return feedback.response1.option_id;
            case 2: return feedback.response2.option_id;
            case 3: return feedback.response3.option_id;
        }
    }

    const fetchScore = (ques_id) => {
        switch (ques_id) {
            case 1: return feedback.score1;
            case 2: return feedback.score2;
            case 3: return feedback.score3;
        }
    }

    const findScore = (option_number) => {
        switch (option_number) {
            case 1: return 0;
            case 2: return 1;
            case 3: return 3;
            case 4: return 5;
        }
    }

    const updateResponse = (ques_id, option_id, options) => {
        const selectedOption = options.filter((option) => option.option_id == option_id);
        let option_number = findScore(selectedOption[0].option_number);
        const response = {
            question_id: ques_id,
            option_id: Number(option_id)
        };
        let data = { ...feedback };
        switch (ques_id) {
            case 1: data.response1 = response;
                data.score1 = option_number;
                break;
            case 2: data.response2 = response;
                data.score2 = option_number;
                break;
            case 3: data.response3 = response;
                data.score3 = option_number;
                break;
        }
        setFeedback(data);
    }

    const getFeedback = async () => {
        try {
            dispatch(selfDirectednessActions.setLoader({ value: true }));
            const response = await fetchFeedbacks(feedback_id);
            let data = response?.data?.feedback[0];
            setFeedback({
                contributor_email: data.contributor_email,
                contributor_name: data.contributor_name,
                comment: data.comments || '',
                score1: data.questions[2].score,
                score2: data.questions[1].score,
                score3: data.questions[0].score,
                from: data.from,
                to: data.to,
                response1: {
                    question_id: data.questions[2].ques_id,
                    option_id: data.questions[2].selected_option_id
                },
                response2: {
                    question_id: data.questions[1].ques_id,
                    option_id: data.questions[1].selected_option_id
                },
                response3: {
                    question_id: data.questions[0].ques_id,
                    option_id: data.questions[0].selected_option_id
                },
            });
            setQuestions(data?.questions.reverse() || []);
            dispatch(selfDirectednessActions.setLoader({ value: false }));
        }
        catch (error) {
            dispatch(selfDirectednessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(selfDirectednessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const createFeedback = async () => {
        try {
            let data = {
                user_email: feedback.contributor_email,
                from: feedback.from,
                to: feedback.to,
                comments: feedback.comment,
                response: [feedback.response1, feedback.response2, feedback.response3],
            }
            dispatch(selfDirectednessActions.setLoader({ value: true }));
            let response = await postFeedback(data, feedback_id);
            dispatch(selfDirectednessActions.setLoader({ value: false }));
            dispatch(selfDirectednessActions.setSnackbar({ value: { open: true, type: 'success', message: response?.data?.msg || 'feedback updated !!' } }));
            navigate('/Self-directedness');
        }
        catch (error) {
            dispatch(selfDirectednessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(selfDirectednessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (editRef.current) return;
        editRef.current = true;
        getFeedback();
    }, [feedback_id]);

    const formatTextField = (value, label) => {
        return (
            <TextField
                id="user"
                label={label}
                variant="filled"
                value={value}
                InputProps={{ style: { fontWeight: 350, backgroundColor: theme.palette.searchInput.main, color: theme.palette.highlight.main } }}
            />
        );
    }

    return (
        <>
            <Box sx={{ ...style, padding: 2, }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={6}>
                    {formatTextField(feedback.contributor_name, "Contributor")}
                    {formatTextField(feedback.from, "From month, year")}
                    {formatTextField(feedback.to, "To month, year")}
                </Stack>
            </Box>
            <Box sx={{ ...style, padding: 4, }}>
                <Stack rowGap={4}>
                    {
                        questions.map((question) => {
                            return (
                                <Stack rowGap={2} key={question.ques_id}>
                                    <Typography variant="h6">{fetchHeading(question.ques_id)}</Typography>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={5}>
                                        <Typography variant="body">{question.question} {feedback.contributor_name} ?</Typography>
                                        <Typography variant="body" sx={{ color: theme.palette.highlight.main }}>score : {fetchScore(question.ques_id)}</Typography>
                                    </Stack>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        sx={{ color: theme.palette.highlight.main, width: '100%', gap: 5 }}
                                        defaultValue={() => fetchDefaultOption(question.ques_id)}
                                        onChange={(e) => {
                                            updateResponse(question.ques_id, e.target.value, question.options);
                                        }}
                                    >
                                        {
                                            question.options.map((option) => {
                                                return (
                                                    <FormControlLabel value={option.option_id} control={<Radio />} label={option.option} key={option.option_number} sx={{ width: '20%' }} />
                                                )
                                            })
                                        }
                                    </RadioGroup>
                                </Stack>
                            )
                        })
                    }
                    <Stack rowGap={2} key={'thisIsComment'}>
                        <Typography variant="h6">4: Comment</Typography>
                        <TextField
                            FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                            helperText={<Stack direction="row" justifyContent={feedback.comment.length < 3 && feedback.comment !== '' ? "space-between" : "flex-end"}>
                                {feedback.comment.length < 3 && feedback.comment !== '' && <Typography variant="caption">min 3 and max 2000 chars</Typography>}
                                <Typography variant="caption">{feedback.comment.length}/2000</Typography>
                            </Stack>}
                            error={feedback.comment.length < 3 && feedback.comment !== ''}
                            id="notes-box"
                            label="Enter your response ...."
                            multiline
                            rows={5}
                            value={feedback.comment}
                            onChange={(e) => {
                                if (e.target.value.length <= 2000) {
                                    let data = { ...feedback };
                                    data.comment = e.target.value;
                                    setFeedback(data);
                                }
                            }}
                            sx={{ width: '50%' }}
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={() => { navigate('/Self-directedness') }} variant="contained"
                        >Cancel</Button>
                        <Button disabled={!feedback.response1 || !feedback.response2 || !feedback.response3} variant="contained" onClick={() => createFeedback()}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </>
    );
}

export default EditFeedback;