import React, { useState } from "react";
import { Modal, Box, Typography, Stack, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { projectDetailsActions } from "../../../store/index";
import { projectDetailsData, markProjectAsEnded } from "../../../services/collectionServices";
import { useNavigate, useParams } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";

const ProjectCompletionModal = () => {
    const projectCompletionModal = useSelector((state) => state.projectDetails.projectCompletionModal);
    const [saveButton, setSaveButton] = useState(false);
    const params = useParams();
    const project_id = params.project_id;
    const [date, setDate] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };

    const setProjectOverHandler = async () => {
        try {
            let data = { id: project_id, end_date: date };
            dispatch(projectDetailsActions.setLoader({ value: true }));
            await markProjectAsEnded(data);
            const response = await projectDetailsData(project_id);
            dispatch(projectDetailsActions.setProjectDetails({ projectDetails: response.data }));
            dispatch(projectDetailsActions.setLoader({ value: false }));
            dispatch(projectDetailsActions.setSnackbar({ value: { open: true, type: 'success', message: 'Project Completed !!' } }));
            dispatch(projectDetailsActions.setProjectCompletionModalClose());
        }
        catch (error) {
            dispatch(projectDetailsActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectDetailsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={projectCompletionModal}
            onClose={() => dispatch(projectDetailsActions.setProjectCompletionModalClose())}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Add project completion date</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{
                                svg: { color: theme.palette.svg.main },
                                backgroundColor: theme.palette.datePicker.main
                            }}
                            label="Choose a date"
                            format="DD-MMM-YYYY"
                            slotProps={{
                                field: {
                                    readOnly: true
                                }
                            }}
                            disableFuture={false}
                            onChange={(value) => {
                                setDate(moment(new Date(value.$d)).format("YYYY-MM-DD"));
                                setSaveButton(moment(new Date(value.$d)).format("YYYY-MM-DD") !== '');
                            }}
                        />
                    </LocalizationProvider>
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={() => dispatch(projectDetailsActions.setProjectCompletionModalClose())}>Cancel</Button>
                        <Button disabled={!saveButton} onClick={setProjectOverHandler}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default ProjectCompletionModal;