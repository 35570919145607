import { useTheme } from "@emotion/react";
import { Modal, Box, Typography, Stack, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { collectionsActions } from "../../store";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CollectionMenus from "./CollectionMenus";
import { fetchContributorsData } from "../../services/collectionServices";
import { useNavigate } from "react-router-dom";


const AllCollectionModal = ({ collection }) => {
    const collectionModal = useSelector((state) => state.collections.collectionModal);
    let manager = useSelector((state) => state.user.manager);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.card.main,
        color: theme.palette.card.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto'
    };

    const fetchContributors = async (selectedCollectionId, collection_name) => {
        try {
            dispatch(collectionsActions.setLoader({ value: true }));
            const response = await fetchContributorsData(selectedCollectionId);
            dispatch(collectionsActions.setLoader({ value: false }));
            dispatch(collectionsActions.setContributors({ contributors: { collection_name: collection_name, contributors: response.data.contributors } }));
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data Fetched' } }));
            dispatch(collectionsActions.setViewContributorsOpen())
        }
        catch (error) {
            dispatch(collectionsActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={collectionModal === collection.collection_id}
            onClose={() => dispatch(collectionsActions.setCollectionModalClose())}
        >
            <Box sx={style}>
                <Stack rowGap={2}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">{collection.collection_name}</Typography>
                        {
                            collection.collection_id !== 1 &&
                            <Button
                                size="medium"
                                sx={{ color: theme.palette.secondary.main }}
                                onClick={() => {
                                    fetchContributors(collection.collection_id, collection.collection_name);
                                }}
                            >
                                CONTRIBUTORS
                            </Button>
                        }
                    </Stack>
                    <Stack>
                        {
                            collection.projects.map((project) => {
                                return (
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        marginTop={1}
                                        key={project.project_id}
                                    >
                                        <Stack>
                                            <Typography
                                                variant="h7"
                                                color={theme.palette.card.contrastText1}
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    navigate(`/project-summary/${project.project_uid}`);
                                                    dispatch(collectionsActions.setCollectionModalClose());
                                                }}
                                            >
                                                {project.project_name}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                color={theme.palette.highlight.main}
                                            >
                                                {(project.last_snapshot && `${project.last_snapshot} days since last snapshot`) || 'No snapshot uploaded'}
                                            </Typography>
                                        </Stack>
                                        {
                                            manager &&
                                            <MoreVertIcon
                                                sx={{ cursor: 'pointer' }}
                                                onClick={(event) => {
                                                    dispatch(collectionsActions.handleOpenProjectNameMenu({ currentTarget: event.currentTarget }));
                                                    dispatch(collectionsActions.setSelectedProjectIDinProjectMenu({ project_id: project.project_id }));
                                                }}
                                            />
                                        }
                                    </Stack>
                                );
                            })
                        }
                    </Stack>
                </Stack>
                <CollectionMenus type="projectName" />
            </Box>

        </Modal>
    );
}

export default AllCollectionModal;  