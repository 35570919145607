import { useNavigate } from 'react-router-dom';
import MainNavigation from '../Components/MainNavigation/MainNavigation';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loggedInUserActions, adminActions } from '../store';
import { jwtDecode } from "jwt-decode";

const RootLayout = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let token = localStorage.getItem('token');

    useEffect(() => {
        let mode = localStorage.getItem('mode');
        if (mode === 'true') {
            localStorage.setItem('mode', true);
            dispatch(adminActions.setMode({ mode: true }));
        }
        else {
            localStorage.setItem('mode', false);
            dispatch(adminActions.setMode({ mode: false }));
        }

        if (token) {
            let userData = jwtDecode(token);
            dispatch(loggedInUserActions.login(userData));
            window.location.pathname === '/' ? navigate('/collections') : navigate(window.location.pathname);
        }
        else {
            const urlSearchString = window.location.search;
            const params = new URLSearchParams(urlSearchString);
            let token = params.get('token');
            if (token === null) {
                navigate('/login');
            }
            else {
                localStorage.setItem('token', token);
                token = localStorage.getItem('token');
                let userData = jwtDecode(token);
                dispatch(loggedInUserActions.login(userData));
                navigate('/collections');
            }
        }
    }, [token, navigate, dispatch]);


    return (
        <MainNavigation />
    );
}

export default RootLayout;