import { useTheme } from "@emotion/react";
import { Box, Stack, TextField, MenuItem, Typography, RadioGroup, FormControlLabel, Radio, Button, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import { leaveActions } from "../../store";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { fetchEmployees, fetchOthersLeave } from "../../services/leaveServices";
import { SortByEmployeeName } from '../../Utils/SortEmployees';
import AllLeavesTable from "./AllLeavesTable";
import EmptyPage from "../EmptyPage";
import PostLeaveModal from "./PostLeaveModal";
import UnpaidModal from "./UnpaidModal";
import DeleteUnpaidModal from './DeleteUnpaidModal';
import LeaveActionModal from "./LeaveActionModal";

const AllLeaves = ({ style }) => {
    const allLeavesRef = useRef(false);
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedAppliedBy = useSelector((state) => state.leave.selectedAppliedBy);
    const appliedBy = useSelector((state) => state.leave.appliedBy);
    const selectedRadioFilter = useSelector((state) => state.leave.selectedRadioFilter);
    const othersLeave = useSelector((state) => state.leave.othersLeave);
    const filteredOthersLeave = useSelector((state) => state.leave.filteredOthersLeave);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.card.main,
        color: theme.palette.card.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
    };

    const dateStyle = {
        width: '35%',
        svg: { color: theme.palette.svg.main },
        color: theme.palette.datePicker.main,
        input: { color: theme.palette.lighter.contrastText1 }
    }

    const textFieldStyle = {
        InputProps: { style: { backgroundColor: theme.palette.searchInput.main, fontWeight: 350 } },
        variant: "filled"
    }

    const loadAllLeaves = async () => {
        try {
            dispatch(leaveActions.setLoader({ value: true }));
            const response = await fetchEmployees();
            dispatch(leaveActions.setAppliedBy({ appliedBy: SortByEmployeeName(response.data.employees) }));
            const othersLeave = await fetchOthersLeave();
            dispatch(leaveActions.setOthersLeave({ othersLeave: othersLeave.data.allLeaves }));
            dispatch(leaveActions.setFilteredOthersLeave({ filteredOthersLeave: othersLeave?.data?.allLeaves.filter((leave) => leave.status === "pending") }));
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'success', message: 'data fetched' } }));
            dispatch(leaveActions.setLoader({ value: false }));
        }
        catch (error) {
            dispatch(leaveActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (allLeavesRef.current) return;
        allLeavesRef.current = true;
        dispatch(leaveActions.resetFilters());
        loadAllLeaves();
    });

    return (
        <Stack>
            <Box sx={style}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ width: '50%' }} columnGap={2}>
                        <TextField id="search-in-all-leaves" label="Search" variant="filled"
                            InputProps={{ style: { backgroundColor: theme.palette.searchInput.main, color: theme.palette.searchInput.contrastText } }}
                            sx={{ width: '50%' }}
                            onChange={(e) => {
                                dispatch(leaveActions.setPage({ value: 0 }));
                                const seachedText = e.target.value.toLowerCase();
                                let filteredLeaves = selectedRadioFilter !== 'All' ? othersLeave.filter((leave) => {
                                    return (leave.status.toLowerCase().includes(selectedRadioFilter.toLowerCase()));
                                }) : othersLeave;
                                filteredLeaves = selectedAppliedBy === null ? filteredLeaves : filteredLeaves.filter((leave) => leave.applied_by_email === selectedAppliedBy.email);

                                filteredLeaves = filteredLeaves.filter((leave) => {
                                    return (
                                        leave.applied_by_name.toLowerCase().includes(seachedText) ||
                                        leave.applied_on.toLowerCase().includes(seachedText) ||
                                        leave.leave_type?.toLowerCase().includes(seachedText) ||
                                        leave.approver.toLowerCase().includes(seachedText) ||
                                        leave.from.toLowerCase().includes(seachedText) ||
                                        leave.to.toLowerCase().includes(seachedText)
                                    )
                                });
                                dispatch(leaveActions.setFilteredOthersLeave({ filteredOthersLeave: filteredLeaves }));
                            }}
                        />

                        <Autocomplete
                            options={appliedBy}
                            getOptionLabel={(user) => user.name}
                            style={{ width: '50%' }}
                            sx={{ svg: { color: theme.palette.svg.main } }}
                            autoHighlight={false}
                            renderInput={(params) => (
                                <TextField {...params}
                                    label="Applied by" variant="filled"
                                    style={{ backgroundColor: theme.palette.searchInput.main }}
                                    value={selectedAppliedBy === null ? '' : selectedAppliedBy}
                                />)}
                            renderOption={
                                (props, user) => (
                                    <MenuItem
                                        {...props}
                                        style={{ fontWeight: 300, padding: '0.8rem' }}
                                        key={user.email + user.name + user.emp_id}
                                        value={JSON.stringify({
                                            emp_id: user.emp_id,
                                            email: user.email,
                                            name: user.name
                                        })}
                                    >
                                        <Typography sx={{ fontWeight: 350 }}>{user.name}</Typography>
                                    </MenuItem>
                                )}
                            onChange={(e, newValue) => {
                                dispatch(leaveActions.setPage({ value: 0 }));
                                dispatch(leaveActions.setSelectedAppliedBy({ selectedAppliedBy: newValue }));
                                if (!newValue) {
                                    selectedRadioFilter === 'All' ? dispatch(leaveActions.setFilteredOthersLeave({ filteredOthersLeave: othersLeave })) : dispatch(leaveActions.setFilteredOthersLeave({ filteredOthersLeave: othersLeave.filter((leave) => leave.status === selectedRadioFilter.toLowerCase()) }));
                                }
                                else {
                                    let filteredLeaves = othersLeave.filter((leave) => leave.applied_by_email === newValue.email);
                                    selectedRadioFilter === 'All' ? dispatch(leaveActions.setFilteredOthersLeave({ filteredOthersLeave: filteredLeaves })) : dispatch(leaveActions.setFilteredOthersLeave({ filteredOthersLeave: filteredLeaves.filter((leave) => leave.status === selectedRadioFilter.toLowerCase()) }));
                                }
                            }}
                        />
                    </Stack>

                    <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ width: '50%' }} columnGap={2}>
                        <RadioGroup
                            row
                            value={selectedRadioFilter}
                            onChange={(e) => {
                                dispatch(leaveActions.setPage({ value: 0 }));
                                dispatch(leaveActions.setRadioFilter({ selectedRadioFilter: e.target.value }));
                                let filteredLeaves = e.target.value === 'All' ? othersLeave : othersLeave.filter((leave) => leave.status === e.target.value.toLowerCase());
                                filteredLeaves = selectedAppliedBy ? filteredLeaves.filter((leave) => leave.applied_by_email === selectedAppliedBy.email) : filteredLeaves;
                                dispatch(leaveActions.setFilteredOthersLeave({ filteredOthersLeave: filteredLeaves }));
                            }}
                        >
                            <FormControlLabel
                                value="Pending"
                                control={<Radio />}
                                label={<Typography variant="body" sx={{ fontWeight: 365 }}>Pending</Typography>}
                            />
                            <FormControlLabel
                                value="Approved"
                                control={<Radio />}
                                label={<Typography variant="body" sx={{ fontWeight: 365 }}>Approved</Typography>}
                            />
                            <FormControlLabel
                                value="All"
                                control={<Radio />}
                                label={<Typography variant="body" sx={{ fontWeight: 365 }}>All</Typography>}
                            />
                        </RadioGroup>

                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                dispatch(leaveActions.setPostLeaveModalOpen())
                            }}
                        >
                            Post Leave
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                dispatch(leaveActions.setunpaidModalOpen());
                            }}
                        >
                            Post Unpaid
                        </Button>
                    </Stack>
                </Stack>
            </Box>
            {filteredOthersLeave.length !== 0 && <AllLeavesTable />}
            {filteredOthersLeave.length === 0 && <EmptyPage />}
            <PostLeaveModal style={modalStyle} dateStyle={dateStyle} textFieldStyle={textFieldStyle} />
            <UnpaidModal style={modalStyle} dateStyle={dateStyle} textFieldStyle={textFieldStyle} />
            <DeleteUnpaidModal style={modalStyle} />
            <LeaveActionModal style={modalStyle} />
        </Stack>
    );
}

export default AllLeaves;