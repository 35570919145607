import { Card, Box } from '@mui/material'
import img from '../../Assets/feeta-wordmark-2 1.png';
import Button from '@mui/material/Button';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchLoginURl } from '../../services/adminServices';

const Login = () => {
    const [url, setUrl] = useState("");
    const urlDataFetchedRef = useRef(false);
    let navigate = useNavigate();

    const fetchURLData = async () => {
        try {
            const response = await fetchLoginURl();
            setUrl(response.data.url);
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let token = localStorage.getItem('token');
        token ? navigate('/') : navigate('/login');
        if (urlDataFetchedRef.current) return;
        urlDataFetchedRef.current = true;
        fetchURLData();
    }, [navigate]);

    const loginoAuth = () => {
        window.location.href = url;
    }

    return (
        <Box style={{ backgroundColor: '#E3F2FD', width: '100%', height: '100vh' }}>
            <Card sx={{
                width: '500px',
                height: '550px',
                backgroundColor: 'white',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: 'auto'
            }}>
                <img src={img} alt='Feeta' loading='lazy'
                    style={{
                        marginLeft: '30%',
                        marginTop: '20%',
                        height: '40px'
                    }} />
                <p style={{ marginTop: '15%', textAlign: 'center' }}>Use your lattice account to login to the</p>
                <p style={{ marginTop: '-11px', textAlign: 'center' }}>application</p>
                <Button
                    onClick={loginoAuth}
                    variant="contained"
                    sx={{ marginLeft: '35%', marginTop: '10%', width: '150px' }}>Login</Button>
            </Card>
        </Box >
    );
}

export default Login;