import { createTheme } from "@mui/material";

const palette = {
    primary: {
        main: '#00072d',
        contrastText: '#FFFFFF'
    },
    active: {
        main: '#0e6ba8',
        contrastText: '#FFFFFF'
    },
    surface: {
        main: '#FFFFFF',
        contrastText: '#000'
    },
    surface1: {
        main: '#e3f2fd',
        contrastText: '#00072d'
    },
    surface2: {
        main: '#FFFFFF',
        contrastText: '#000'
    },
    secondary: {
        main: '#0a2472',
        contrastText: '#FFF'
    },
    light: {
        main: '#a6e1fa',
        contrastText: '#000'
    },
    lighter: {
        main: "#e3f2fd",
        contrastText: '#000',
        contrastText1: '#1565c0'
    },
    highlight: {
        main: '#0009',
    },
    card: {
        main: '#e3f2fd',
        contrastText: '#000',
        contrastText1: '#1565c0'
    },
    svg: {
        main: '#00072d'
    },
    datePicker: {
        main: '#FFFFFF'
    },
    disabled: {
        main: '#0009'
    },
    searchInput: {
        main: "#FFFFFF",
        contrastText: '#000',
        contrastText1: '#000'
    }
}

const lightTheme = createTheme({
    palette: palette,
    typography: {
        fontFamily: `"${[
            'Nunito',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(',')}"`,
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: palette.primary.main,
                    contrastText: '#000'
                },

            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: palette.light.main,
                    color: palette.primary.main,
                    fontSize: '12px',
                    fontWeight: 'bold',
                },
                arrow: {
                    "&:before": {
                        border: `2px solid ${palette.light.main}`
                    },
                    color: palette.light.main,
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: palette.secondary.main,
                        color: '#FFFFFF'
                    },
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: '#000'
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& label': {
                        color: '#0009',
                    },
                    '& label.Mui-focused': {
                        color: palette.primary.main,
                    },
                    '& .MuiInputLabel-root': {
                        '&.Mui-error': {
                            color: 'red', // Label color when error
                        },
                        '&.Mui-disabled': {
                            color: '#0005' //label when disabled
                        }
                    },
                    '&.MuiInput-underline:hover:before': {
                        borderColor: '#0009'
                    },
                    '& .MuiOutlinedInput-root': {
                        color: '#000',
                        '& fieldset': {
                            borderColor: '#0004',
                        },
                        '&:hover fieldset': {
                            borderColor: '#00072d',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#00072d',
                        },
                        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'red', // Border color when error
                        },
                        '&.Mui-disabled': {
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#0004'
                            },
                            '.MuiInputBase-input': {
                                WebkitTextFillColor: "#0004",
                            }
                        },
                    },
                },
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#0009', // Set your desired color for helper text
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#FFFFFF',
                    color: palette.card.contrastText1,
                    fontWeight: 350
                },
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontWeight: 350,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                filled: {
                    backgroundColor: '#FFFFFF',
                    color: palette.card.contrastText1,
                    fontWeight: 350,
                },
                select: {
                    backgroundColor: palette.lighter.main,
                    color: palette.card.contrastText1,
                    fontWeight: 350,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#000',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                underline: {
                    '&:hover:not(.Mui-disabled):before': {
                        borderBottomColor: '#0009',
                    },
                    '&.Mui-focused:after': {
                        borderBottomColor: palette.primary.main,
                    },
                    '&:before': {
                        borderBottomColor: '#0007', // Default color
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    backgroundColor: palette.secondary.main, // Set your desired color
                    '&:hover': {
                        backgroundColor: '#1F2D61', // Set your desired hover color
                    },
                    '&:disabled': {
                        color: palette.disabled.main,
                    }
                },
                text: {
                    color: palette.secondary.main,
                    '&:hover': {
                        backgroundColor: '#0001', // Set your desired hover color
                    },
                    '&:disabled': {
                        color: '#0004',
                    }
                }
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": { width: 2, height: 2 },
                    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                        backgroundColor: palette.primary.main,
                        borderRadius: '5px'
                    },
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: palette.disabled.main,
                    '&.Mui-checked': {
                        color: palette.primary.main,
                    },
                    '&.Mui-disabled': {
                        color: '#0004'
                    }
                }
            },
        },
        MuiSwitch: {
            styleOverrides: {
                track: {
                    backgroundColor: palette.light.main, // Set your desired track color
                    opacity: 0.5,
                    ".Mui-checked.Mui-checked + &": {
                        backgroundColor: palette.primary.main
                    }
                },
                switchBase: {
                    color: "#FFF",
                    "&.Mui-checked": {
                        color: palette.primary.main,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: '#0009',
                    "&.Mui-checked": {
                        color: palette.primary.main
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    color: '#000',
                    backgroundColor: '#0001',
                    '&:hover': {
                        color: '#000',
                        backgroundColor: '#0002'
                    }
                },
                colorPrimary: {
                    color: '#FFF',
                    backgroundColor: palette.primary.main,
                    '&:hover': {
                        color: '#FFF',
                        backgroundColor: palette.primary.main,
                    }
                },
                colorSecondary: {
                    color: '#FFF',
                    backgroundColor: palette.secondary.main,
                    '&:hover': {
                        color: '#FFF',
                        backgroundColor: palette.secondary.main,
                    }
                },
                colorSuccess: {
                    color: '#FFF',
                    backgroundColor: '#388e3c',
                    '&:hover': {
                        color: '#FFF',
                        backgroundColor: '#388e3c',
                    }
                },
                colorWarning: {
                    color: '#FFF',
                    backgroundColor: '#f57c00',
                    '&:hover': {
                        color: '#FFF',
                        backgroundColor: '#f57c00',
                    }
                },
                colorError: {
                    color: '#FFF',
                    backgroundColor: '#d32f2f',
                    '&:hover': {
                        color: '#FFF',
                        backgroundColor: '#d32f2f',
                    }
                },
                colorInfo: {
                    color: '#FFF',
                    backgroundColor: '#1976D2',
                    '&:hover': {
                        color: '#FFF',
                        backgroundColor: '#1976D2',
                    }
                }
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                input: {
                    color: palette.card.contrastText1,
                    fontWeight: 350,
                },
                paper: {
                    color: palette.card.contrastText1,
                    backgroundColor: '#FFF'
                }
            },
        },
    },
});

export default lightTheme;