import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; 
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { useTheme } from '@emotion/react';

const Calendar = () => {
    const theme = useTheme();
    const holidays = useSelector((state) => state.leave.holidays);
    const othersLeave = useSelector((state) => state.leave.othersLeave);

    let events = holidays.map((holiday) => ({
        title: holiday.holiday,
        start: holiday.from,
        end: holiday.end,
        type: 'holiday',
        display: 'background',
        backgroundColor: 'yellow'
    }));

    events = [...events, ...othersLeave.filter(leave => leave.leave_type !== 'CO' && leave.status === 'approved').map((leave) => {
        return {
            title: `${leave.applied_by_name} (${leave.leave_type === 'earned' ? 'EL' : 'SL'})`,
            start: leave.from,
            end: leave.to,
            type: 'Leave',
            display: 'list-item'
        }   
    })]


    return (
        <Box sx={{
            backgroundColor: theme.palette.surface1.main, color: theme.palette.surface1.contrastText, width: '100%', borderRadius: '10px', textAlign: 'center', marginTop: 1, padding: 1
        }}>
            <Box 
                sx={{ 
                    // backgroundColor: theme.palette.surface.main, 
                    backgroundColor: '#FFFFFF',
                    width: '100%', 
                    borderRadius: '10px', 
                    textAlign: 'center', 
                    padding: 2 
                }}
            >
                <Stack direction="row" justifyContent="space-between" columnGap={2}>
                    {/* <Box sx={{
                        width: '25%',
                        backgroundColor: '#e3f2fd',
                        color: '#00072d',
                        padding: 2,
                        height: '70vh',
                        overflowY: 'scroll',
                    }}>
                        <Stack rowGap={2}>
                        <Typography>Holidays</Typography>
                        <Divider></Divider>
                            {
                                holidays.map((holiday) => {
                                    return (
                                        <Stack direction="row" justifyContent="space-between" alignItems="center" key={holiday.holiday + holiday.from + holiday.to}>
                                            <Typography>{holiday.holiday}</Typography>
                                            <Typography>{holiday.from}</Typography>
                                        </Stack>
                                    );
                                })
                            }
                        </Stack>
                    </Box> */}
                    <Box 
                        sx={{ 
                            width: '100%', 
                            height: '70vh' ,
                            color: '#00072d'
                        }}>
                        <FullCalendar
                            plugins={[ dayGridPlugin ]}
                            initialView="dayGridMonth"
                            events={events}
                            dayMaxEventRows={2}
                            height={'70vh'}
                            eventBackgroundColor='red'
                        />
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
}

export default Calendar;