import { CircularProgress, Box, Typography } from "@mui/material";

const Progress = (props) => {
    const { value, scolor } = props;
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value={value} size='110px' sx={{ color: scolor }} />
            <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', }} >
                <Typography variant="h5" component="div" sx={{ color: scolor }}>
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

export default Progress;