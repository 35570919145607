import axios from "axios";

let baseUrl = process.env.REACT_APP_DEV_BASE_URL;

const fetchEmployees = async (figmaToken) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/employee`, {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        }))
    ]);
    return response;
}

const fetchOthersLeave = async () => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/othersleave`, {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        }))
    ]);
    return response;
}

const fetchLeaveBalance = async (email) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/leaveBalance/?email=${email}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        }))
    ]);
    return response;
}

const postLeave = async (data) => {
    let token = localStorage.getItem('token');
    let bodyFormData = new FormData();
    bodyFormData.append('manager', data.manager);
    bodyFormData.append('from', data.from);
    bodyFormData.append('to', data.to);
    bodyFormData.append('type', data.type);
    bodyFormData.append('notes', data.notes);
    bodyFormData.append('employee', data.employee);
    bodyFormData.append('attachment', data.attachment);

    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/postLeaveTransaction`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            data: bodyFormData
        }))
    ]);
    return response;
}

const postUnpaid = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/unpaid`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            data: data
        }))
    ]);
    return response;
}

const deleteUnpaid = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/unpaid`, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            data: data
        }))
    ]);
    return response;
}

const fetchManager = async () => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/employee?managers=true`, {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        }))
    ]);
    return response;
}

const actionOnLeave = async (leaveID, decision, data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/leaveAction?leaveID=${leaveID}&decision=${decision}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            data: data
        }))
    ]);
    return response;
}

const reassignLeave = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/reassign`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            data: data
        }))
    ]);
    return response;
}

const fetchMyLeaveData = async () => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/myleaves`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        }))
    ]);
    return response;
}

const applyCompOff = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/compoff`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            data: data
        }))
    ]);
    return response;
}

const applyLeave = async (data) => {
    let token = localStorage.getItem('token');
    let bodyFormData = new FormData();
    bodyFormData.append('manager', data.manager);
    bodyFormData.append('from', data.from);
    bodyFormData.append('to', data.to);
    bodyFormData.append('type', data.type);
    bodyFormData.append('notes', data.notes);
    bodyFormData.append('attachment', data.attachment);

    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/leave`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            data: bodyFormData
        }))
    ]);
    return response;
}

const withdrawPendingLeave = async (leaveID) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/withdrawPending?leaveID=${leaveID}`, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        }))
    ]);
    return response;
}

const withdrawApprovedLeave = async (leaveID) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/withdrawApproved?leaveID=${leaveID}`, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + token,
            }
        }))
    ]);
    return response;
}

const editLeave = async (data, file) => {
    let token = localStorage.getItem('token');
    let bodyFormData = new FormData();
    bodyFormData.append('manager', data.applied_to_email);
    bodyFormData.append('from', data.from);
    bodyFormData.append('to', data.to);
    bodyFormData.append('type', data.leave_type);
    bodyFormData.append('notes', data.notes);
    bodyFormData.append('attachment', file);
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/leave?leaveID=${data.leave_id}`, {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            data: bodyFormData
        }))
    ]);
    return response;
}

const fetchHolidays = async (year) => {
    let url = year ? `/api/holiday?year=${year}` : '/api/holiday';
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl + url}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        }))
    ]);
    return response;
}


export { fetchEmployees, fetchOthersLeave, fetchLeaveBalance, postLeave, postUnpaid, deleteUnpaid, fetchManager, actionOnLeave, reassignLeave, fetchMyLeaveData, applyCompOff, applyLeave, withdrawPendingLeave, withdrawApprovedLeave, editLeave, fetchHolidays };

