import React from "react";
import { Box, Stack } from "@mui/material";
import { useTheme } from "@emotion/react";
import StartDateDropdown from "./StartDateDropdown";
import DurationDropdown from "./DurationDropdown";
import CollectionsDropdown from "./CollectionsDropdown";
import ProjectsDropdown from "./ProjectsDropdown";
import { fetchAllocationsData, fetchAllocationCollections, fetchAllocationProjects } from '../../services/allocationServices';
import { useDispatch } from "react-redux";
import { allocationActions } from "../../store";
import { useNavigate } from "react-router-dom";

const AllocationDropdowns = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const style = {
        backgroundColor: theme.palette.surface1.main, color: theme.palette.surface1.contrastText,
        minheight: '70vh', width: '100%', borderRadius: '10px',
        padding: 5
    }

    const formatProjects = (projectResponse) => {
        return projectResponse.data.projects.filter((project) => {
            if (project.project_uid) {
                return {
                    id: project.id,
                    uid: project.project_uid,
                    project_name: project.project_name
                }
            }

        });
    }

    const fetchAllocationData = async (data, calledBy) => {
        try {
            dispatch(allocationActions.setLoader({ value: true }));
            if (calledBy === 'startDate' || calledBy == 'duration' || calledBy == 'allCollections') {
                const collectionResponse = await fetchAllocationCollections(data.startDate, data.duration);
                const projectResponse = await fetchAllocationProjects(data.startDate, data.duration);
                const projects = formatProjects(projectResponse);
                dispatch(allocationActions.setCollections({ collections: collectionResponse.data.collections }));
                dispatch(allocationActions.setProjects({ projects: projects }));

                data.requested_collections = collectionResponse.data.collections.map((collection) => collection.collection_id);
                data.requested_projects = projects.map((project) => project.id);
                const response = await fetchAllocationsData(data);
                dispatch(allocationActions.setContributors({ contributors: response.data.user_names }));
                props.checkAllSelected(response.data.user_names);
                dispatch(allocationActions.setSelectedCollections({ selectedCollections: -1 }));
                dispatch(allocationActions.setResult({ result: response.data.result }));
                dispatch(allocationActions.setLoader({ value: false }));
                dispatch(allocationActions.setSnackbar({ value: { open: true, type: 'success', message: `${response.data.result.length === 0 ? 'No data for current selection' : 'Data fetched !!!'}` } }));
            }
            else {
                const projectResponse = await fetchAllocationProjects(data.startDate, data.duration, data.requested_collections[0]);
                const projects = formatProjects(projectResponse);
                dispatch(allocationActions.setProjects({ projects: projects }));
                data.requested_projects = projects.map((project) => project.id);
                if (data.requested_projects.length !== 0) {
                    const response = await fetchAllocationsData(data);
                    dispatch(allocationActions.setContributors({ contributors: response.data.user_names }));
                    props.checkAllSelected(response.data.user_names);
                    dispatch(allocationActions.setResult({ result: response.data.result }));
                    dispatch(allocationActions.setLoader({ value: false }));
                    dispatch(allocationActions.setSnackbar({ value: { open: true, type: 'success', message: `${response.data.result.length === 0 ? 'No data for current selection' : 'Data fetched !!!'}` } }));
                }
                else {
                    dispatch(allocationActions.setResult({ result: [] }));
                    dispatch(allocationActions.setContributors({ contributors: [] }));
                    dispatch(allocationActions.setLoader({ value: false }));
                    dispatch(allocationActions.setSnackbar({ value: { open: true, type: 'warning', message: 'No Projects available !!!' } }));
                }
            }
        }
        catch (error) {
            dispatch(allocationActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(allocationActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Box sx={style}>
            <Stack direction="row" columnGap={3} justifyContent="space-between" alignItems="start">
                <StartDateDropdown fetchAllocationData={fetchAllocationData} checkAllSelected={props.checkAllSelected} />
                <DurationDropdown fetchAllocationData={fetchAllocationData} checkAllSelected={props.checkAllSelected} />
                <CollectionsDropdown fetchAllocationData={fetchAllocationData} checkAllSelected={props.checkAllSelected} />
                <ProjectsDropdown fetchAllocationData={fetchAllocationData} checkAllSelected={props.checkAllSelected} />
            </Stack>
        </Box>
    );
}

export default AllocationDropdowns;