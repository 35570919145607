import moment from "moment";

const getWorkingDays = (startDate, endDate) => {
    let currentDate = moment(startDate);
    const finalDate = moment(endDate);
    let workingDays = 0;
    while (currentDate <= finalDate) {
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
            workingDays++;
        }
        currentDate = currentDate.add(1, 'days');
    }
    return workingDays;
}

const lastDayOfFinancialYear = (inputDate) => {
    const currentDate = moment(inputDate);
    const lastDayOfMarchNextYear = currentDate.add(1, 'year').month(2).endOf('month');
    const formattedDate = lastDayOfMarchNextYear.format('YYYY-MM-DD');
    return formattedDate;
}

const firstDayOfFinancialYear = (inputDate) => {
    const currentDate = moment(inputDate);
    const firstDayOfAprilNextYear = currentDate.subtract(1, 'year').month(3).startOf('month');
    const formattedDate = firstDayOfAprilNextYear.format('YYYY-MM-DD');
    return formattedDate;
}


function disableWeekends(date) {
    const jsDate = date.toDate();
    return jsDate.getDay() === 0 || jsDate.getDay() === 6;
}

const formatLeaveType = (leave_type) => {
    switch (leave_type) {
        case 'sick': return 'Sick Leave';
        case 'earned': return 'Earned Leave';
        case 'CO': return 'Comp-Off';
        default: return '';
    }
}

export { getWorkingDays, lastDayOfFinancialYear, firstDayOfFinancialYear, disableWeekends, formatLeaveType };