import React, { useEffect, useRef, useState } from "react";
import { Modal, Box, Typography, Stack, Button, TextField, MenuItem, Divider, Chip } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { leaveActions } from "../../store";
import { applyLeave, fetchMyLeaveData } from "../../services/leaveServices";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import moment from "moment";
import { getWorkingDays, lastDayOfFinancialYear, firstDayOfFinancialYear, disableWeekends } from "../../Utils/leaveHelpers";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const ApplyLeaveModal = ({ style, dateStyle, textFieldStyle }) => {
    const applyLeaveRef = useRef();
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [leaveType, setLeaveType] = useState('');
    const [saveButton, setSaveButton] = useState(false);
    const [selectedManager, setSelectedManager] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [notes, setNotes] = useState('');
    const inputFile = useRef(null);
    const [file, setFile] = useState(null);

    const managers = useSelector((state) => state.leave.managers);
    const applyLeaveModal = useSelector((state) => state.leave.applyLeaveModal);
    const leaveBalance = useSelector((state) => state.leave.leaveBalance);
    const loggedInUser = useSelector((state) => state.user.userEmail);

    const resetData = () => {
        setSelectedManager('');
        setLeaveType('');
        setSaveButton(false);
        setFromDate('');
        setToDate('');
        setFile(null);
        setNotes('');
    }

    useEffect(() => {
        if (applyLeaveRef.current) return;
        applyLeaveRef.current = true;
        resetData();
    });

    const applyLeaveHandler = async () => {
        try {
            const data = {
                manager: selectedManager,
                from: fromDate,
                to: toDate,
                type: leaveType,
                notes: notes,
                attachment: file,
            }
            dispatch(leaveActions.setLoader({ value: true }));
            const response = await applyLeave(data);
            const myLeave = await fetchMyLeaveData();
            dispatch(leaveActions.setMyLeave({ myLeave: myLeave.data.myleaves }));
            dispatch(leaveActions.setFilteredMyLeave({ filteredMyLeave: myLeave.data.myleaves }));
            dispatch(leaveActions.setLeaveBalance({ leaveBalance: myLeave.data.leaveBalance }));
            dispatch(leaveActions.setLoader({ value: false }));
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'success', message: response?.data?.msg || 'leave applied !!' } }));
            dispatch(leaveActions.setApplyLeaveModalClose());
            resetData();
            dispatch(leaveActions.resetFilters());
        }
        catch (error) {
            dispatch(leaveActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={applyLeaveModal}
            onClose={() => {
                dispatch(leaveActions.setApplyLeaveModalClose());
                resetData();
            }}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Apply for leave</Typography>
                    <Divider />
                    <TextField
                        sx={{ width: '50%', svg: { color: theme.palette.svg.main } }}
                        id="select manager"
                        required
                        label="Select Manager"
                        variant="outlined"
                        select
                        value={selectedManager}
                        SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }}
                        onChange={(e) => {
                            setSelectedManager(e.target.value);
                            setSaveButton(e.target.value && leaveType && fromDate && toDate);
                        }}
                    >
                        {managers.map((manager, index) => (
                            <MenuItem
                                key={manager.emp_id + manager.email}
                                value={manager.email}
                                sx={{ padding: 1 }}
                                disabled={manager.email === loggedInUser}
                            >
                                {manager.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Stack rowGap={1}>
                        <Typography variant="overline" sx={{ color: '#f57c00' }}>Leave Type & Date</Typography>
                        <Stack direction="row" gap={1}>
                            <Chip
                                disabled={!selectedManager}
                                label="Earned Leave"
                                onClick={(e) => {
                                    setToDate('');
                                    setFromDate('');
                                    setLeaveType('earned');
                                    setSaveButton(false);
                                }
                                }
                                sx={{ cursor: 'pointer', fontSize: '14px', fontWeight: 350 }}
                                color={leaveType === 'earned' ? 'primary' : 'default'}
                            />
                            <Chip
                                disabled={!selectedManager}
                                label="Sick Leave"
                                onClick={() => {
                                    setToDate('');
                                    setFromDate('');
                                    setLeaveType('sick');
                                    setSaveButton(false);
                                }}
                                sx={{ cursor: 'pointer', fontSize: '14px', fontWeight: 350 }}
                                color={leaveType === 'sick' ? 'primary' : 'default'}
                            />
                        </Stack>
                    </Stack>

                    <Stack direction="row" columnGap={1} sx={{ width: '100%' }} alignItems="center">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={dateStyle}
                                label="Leave Date, From"
                                format="DD-MMM-YYYY"
                                defaultValue={''}
                                value={dayjs(fromDate)}
                                required
                                slotProps={{
                                    field: {
                                        readOnly: true
                                    },
                                    textField: textFieldStyle
                                }}
                                minDate={leaveType === 'sick' ? dayjs(firstDayOfFinancialYear(moment().format('YYYY-MM-DD'))) : dayjs(moment().add(15, 'days').format('YYYY-MM-DD'))}
                                maxDate={leaveType === 'sick' ? dayjs(moment().format('YYYY-MM-DD')) : dayjs(lastDayOfFinancialYear(moment().format('YYYY-MM-DD')))}
                                disabled={leaveType === ''}
                                shouldDisableDate={disableWeekends}
                                views={['day']}
                                onChange={(value) => {
                                    setToDate('');
                                    setSaveButton(false);
                                    setFromDate(moment(new Date(value.$d)).format("YYYY-MM-DD"));
                                }}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={dateStyle}
                                label="Leave Date, To"
                                format="DD-MMM-YYYY"
                                defaultValue={''}
                                value={dayjs(toDate)}
                                required
                                slotProps={{
                                    field: {
                                        readOnly: true
                                    },
                                    textField: textFieldStyle
                                }}
                                minDate={dayjs(moment(fromDate).format('YYYY-MM-DD'))}
                                maxDate={leaveType === 'sick' ? dayjs(moment().format('YYYY-MM-DD')) : dayjs(lastDayOfFinancialYear(moment().format('YYYY-MM-DD')))}
                                disabled={fromDate === ''}
                                views={['day']}
                                shouldDisableDate={disableWeekends}
                                onChange={(value) => {
                                    setSaveButton((selectedManager) && (fromDate) && leaveType && (moment(new Date(value.$d)).format("YYYY-MM-DD")));
                                    setToDate(moment(new Date(value.$d)).format("YYYY-MM-DD"));
                                }}
                            />
                        </LocalizationProvider>
                        {fromDate && toDate && <Typography variant="body" sx={{ fontWeight: 350, color: theme.palette.highlight.main }}>{getWorkingDays(fromDate, toDate) + ' working days'}</Typography>}
                    </Stack>

                    {leaveType &&
                        <Stack direction="row" justifyItems="center" justifyContent="flex-start" columnGap={3}>
                            <Typography variant="caption" sx={{ color: theme.palette.highlight.main }}>
                                {leaveType === 'sick' ? `Available SL balance: ${leaveBalance.SL_balance_without_threshold} days` : `Available EL balance: ${leaveBalance.EL_balance_without_threshold} days`}
                            </Typography>
                            <Typography variant="caption" sx={{ color: theme.palette.highlight.main }}>
                                Available Comp-off balance: {leaveBalance.CO_balance} days
                            </Typography>
                        </Stack>
                    }

                    {
                        !leaveType &&
                        <Typography variant="caption" sx={{ color: theme.palette.highlight.main }}>If you have accrued compensatory off, it will automatically be used up first</Typography>
                    }

                    <Stack>
                        <Typography variant="overline" sx={{ color: '#f57c00' }}>NOTES & ATTACHMENT</Typography>
                        <TextField
                            FormHelperTextProps={{ sx: { display: 'flex', justifyContent: 'flex-end' } }}
                            helperText={notes.length < 3 && notes.length > 0 ? 'min 3 and max 100 chars' : `${notes.length}/100`}
                            error={notes.length < 3 && notes.length > 0}
                            id="notes"
                            label="Notes"
                            variant="outlined"
                            value={notes}
                            multiline
                            rows={2}
                            onChange={(e) => {
                                if (e.target.value.length <= 100) {
                                    setNotes(e.target.value);
                                }
                            }}
                        />
                        {!file &&
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={{ width: '40%' }}
                                size="large"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    inputFile.current.click();
                                }}
                            >
                                upload file
                                <input
                                    style={{ display: 'none' }}
                                    ref={inputFile}
                                    type="file"
                                    accept=".pdf,.jpg,.jpeg,.png"
                                    onChange={(event) => {
                                        const fileObj = event?.target?.files[0]
                                        if (!fileObj) return;
                                        setFile(fileObj);
                                    }}
                                />
                            </Button>
                        }
                        {!file &&
                            <Typography
                                variant="caption"
                                sx={{ color: theme.palette.highlight.main, padding: 1 }}
                            >
                                Upload a single file of up to 1 MB. Only PDF, JPG, JPEG or PNG format allowed.
                            </Typography>
                        }
                        {file &&
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="body" sx={{ color: '#1565c0' }}>{file.name}</Typography>
                                <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setFile(null)} />
                            </Stack>
                        }
                    </Stack>

                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button
                            onClick={() => {
                                dispatch(leaveActions.setApplyLeaveModalClose());
                                resetData();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button disabled={!saveButton} onClick={applyLeaveHandler}>Submit</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default ApplyLeaveModal;