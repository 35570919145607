import { useTheme } from "@emotion/react";
import { Stack, Typography, TextField, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { taskTimelinessActions } from '../../store/index';
import { fetchTimelinessData } from '../../services/timelinessServices';
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SnapshotDates = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedProjectId = useSelector((state) => state.taskTimeliness.selectedProjectId);
    const projectSnapshotDates = useSelector((state) => state.taskTimeliness.projectSnapshotDates);
    const comparedToSnapshots = useSelector((state) => state.taskTimeliness.comparedToSnapshots);
    const selectedSnapshotDate = useSelector((state) => state.taskTimeliness.selectedSnapshotDate);
    const selectedComparedTo = useSelector((state) => state.taskTimeliness.selectedComparedTo);
    const [errorSnapshot, setErrorSnapshot] = useState(false);
    const [errorCompareTo, setErrorCompareTo] = useState(false);

    const snapshotDateChangeHandler = async (snapshot_date) => {
        try {
            if (selectedComparedTo === '') {
                setErrorCompareTo(true);
                return;
            }
            setErrorCompareTo(false);
            setErrorSnapshot(false);
            dispatch(taskTimelinessActions.setLoader({ value: true }));
            const response = await fetchTimelinessData(selectedProjectId.project_uid, snapshot_date, selectedComparedTo, true, true);
            dispatch(taskTimelinessActions.setTimelinessData({ timelinessData: response.data.delayArray }));
            dispatch(taskTimelinessActions.setDelayBlock({ delayBlock: response.data.delay_block }));
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data fetched !!!' } }));
        }
        catch (error) {
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const compareToDateChangeHandler = async (compare_to) => {
        try {
            if (selectedSnapshotDate === '') {
                setErrorSnapshot(true);
                return;
            }
            setErrorSnapshot(false);
            setErrorCompareTo(false);
            dispatch(taskTimelinessActions.setLoader({ value: true }));
            const response = await fetchTimelinessData(selectedProjectId.project_uid, selectedSnapshotDate, compare_to, true, true);
            dispatch(taskTimelinessActions.setTimelinessData({ timelinessData: response.data.delayArray }));
            dispatch(taskTimelinessActions.setDelayBlock({ delayBlock: response.data.delay_block }));
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data fetched !!!' } }));
        }
        catch (error) {
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Stack direction="row" columnGap={3} justifyContent="flex-start" alignItems="center" alignContent="center" width={"50%"}>
            <TextField id="current-snapshot" label="SNAPSHOT DATE" required variant="filled" select
                value={selectedSnapshotDate}
                error={errorSnapshot}
                SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }}
                sx={{ width: '50%', svg: { color: theme.palette.svg.main } }}
                InputProps={{ style: { backgroundColor: '#FFFFFF', fontWeight: 350 } }}
                onChange={(e) => {
                    dispatch(taskTimelinessActions.resetFilters());
                    setErrorSnapshot(false);
                    dispatch(taskTimelinessActions.setSelectedSnapshotDate({ selectedSnapshotDate: e.target.value }));
                    snapshotDateChangeHandler(e.target.value);
                }}
            >
                {
                    projectSnapshotDates.map((snapshot, i) => {
                        return (<MenuItem
                            sx={{ padding: 1.5 }}
                            key={snapshot.snapshot_date}
                            value={snapshot.snapshot_date}
                        >
                            <Stack width="100%" direction="row" justifyContent="space-between" alignItems="end">
                                <Typography sx={{ fontWeight: 350 }}>{snapshot.snapshot_date}</Typography>
                                <Typography sx={{ color: theme.palette.highlight.main, fontWeight: 350 }} variant="caption">{snapshot.reference ? 'Reference' : ''}</Typography>
                            </Stack>
                        </MenuItem>)
                    })
                }
            </TextField>
            <TextField id="comparedto-snapshot" label="COMPARED TO" required variant="filled" select
                value={selectedComparedTo}
                error={errorCompareTo}
                SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }} sx={{ width: '50%', svg: { color: theme.palette.svg.main } }}
                InputProps={{ style: { backgroundColor: '#FFFFFF', fontWeight: 350 } }}
                onChange={(e) => {
                    dispatch(taskTimelinessActions.resetFilters());
                    setErrorCompareTo(false);
                    dispatch(taskTimelinessActions.setSelectedComparedToDate({ selectedComparedTo: e.target.value }));
                    compareToDateChangeHandler(e.target.value);
                }}

                onBlurCapture={() => {
                    if (selectedSnapshotDate === '') setErrorSnapshot(true);
                }}
            >
                {
                    comparedToSnapshots.map((snapshot, i) => {
                        return (<MenuItem
                            sx={{ padding: 1.5 }}
                            key={snapshot.snapshot_date}
                            value={snapshot.snapshot_date}
                        >
                            <Stack width="100%" direction="row" justifyContent="space-between" alignItems="end">
                                <Typography sx={{ fontWeight: 350 }}>{snapshot.snapshot_date}</Typography>
                                <Typography sx={{ color: theme.palette.highlight.main, fontWeight: 350 }} variant="caption">{snapshot.reference ? 'Reference' : ''}</Typography>
                            </Stack>
                        </MenuItem>)
                    })
                }
            </TextField>
        </Stack>
    );
}

export default SnapshotDates;