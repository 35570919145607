import { useTheme } from "@emotion/react";
import { Stack, Typography, TextField, MenuItem, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TimerIcon from '@mui/icons-material/Timer';
import { projectSummaryActions, taskTimelinessActions } from '../../store/index';
import { fetchProjectSummaryData, progressEffortWise } from '../../services/projectSummaryServices';
import { useNavigate } from "react-router-dom";

const SnapshotDates = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedProjectId = useSelector((state) => state.projectSummary.selectedProjectId);
    const projectSnapshotDates = useSelector((state) => state.projectSummary.projectSnapshotDates);
    const comparedToSnapshots = useSelector((state) => state.projectSummary.comparedToSnapshots);
    const selectedSnapshotDate = useSelector((state) => state.projectSummary.selectedSnapshotDate);
    const selectedComparedTo = useSelector((state) => state.projectSummary.selectedComparedTo);
    const projects = useSelector((state) => state.projectSummary.projects);

    const snapshotDateChangeHandler = async (snapshot_date) => {
        try {
            dispatch(projectSummaryActions.setLoader({ value: true }));
            const response = await fetchProjectSummaryData(selectedProjectId.project_uid, snapshot_date, selectedComparedTo);
            dispatch(projectSummaryActions.setProjectSummary({ projectSummary: response.data }));
            const response2 = await progressEffortWise(selectedProjectId.project_uid, snapshot_date);
            dispatch(projectSummaryActions.setProgressEffort({ effort: response2.data.progress }));
            dispatch(projectSummaryActions.setLoader({ value: false }));
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data fetched !!!' } }));
        }
        catch (error) {
            dispatch(projectSummaryActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const compareToDateChangeHandler = async (compare_to) => {
        try {
            dispatch(projectSummaryActions.setLoader({ value: true }));
            const response = await fetchProjectSummaryData(selectedProjectId.project_uid, selectedSnapshotDate, compare_to);
            dispatch(projectSummaryActions.setProjectSummary({ projectSummary: response.data }));
            dispatch(projectSummaryActions.setLoader({ value: false }));
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data fetched !!!' } }));
        }
        catch (error) {
            dispatch(projectSummaryActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const timelinessButtonHandler = () => {
        dispatch(taskTimelinessActions.resetFilters());
        dispatch(taskTimelinessActions.setProjects({ projects: projects }));
        dispatch(taskTimelinessActions.setProjectSnapshotDates({ snapshots: projectSnapshotDates }));
        dispatch(taskTimelinessActions.setSelectedSnapshotDate({ selectedSnapshotDate: selectedSnapshotDate }));
        dispatch(taskTimelinessActions.setSelectedComparedToDate({ selectedComparedTo: selectedComparedTo }));
        dispatch(taskTimelinessActions.setSelectedProjectId({ id: selectedProjectId }));
        navigate(`/task-timeliness/${selectedProjectId.project_uid}`);
    }

    return (
        <Stack direction="row" columnGap={3} justifyContent="flex-start" alignItems="center">
            <TextField id="current-snapshot" label="CURRENT SNAPSHOT" required variant="filled" select
                // defaultValue={selectedSnapshotDate}
                value={selectedSnapshotDate}
                SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }} sx={{ width: '20%', svg: { color: theme.palette.svg.main } }}
                InputProps={{ style: { backgroundColor: '#FFFFFF' } }}
                onChange={(e) => {
                    dispatch(projectSummaryActions.setSelectedSnapshotDate({ selectedSnapshotDate: e.target.value }));
                    dispatch(projectSummaryActions.setSelectedComparedToDate({ selectedComparedTo: '' }));
                    dispatch(projectSummaryActions.setComparedToSnapshotDates());
                    snapshotDateChangeHandler(e.target.value);
                }}
            >
                {
                    projectSnapshotDates.map((snapshot, i) => {
                        return (<MenuItem
                            sx={
                                { fontWeight: 300, padding: 1.5 }
                            }
                            key={snapshot.snapshot_date}
                            value={snapshot.snapshot_date}
                        >
                            <Stack width="100%" direction="row" justifyContent="space-between" alignItems="end">
                                <Typography sx={{ fontWeight: 350 }}>{snapshot.snapshot_date}</Typography>
                                <Typography sx={{ color: theme.palette.highlight.main }} variant="caption">{snapshot.reference ? 'Reference' : ''}</Typography>
                            </Stack>
                        </MenuItem>)
                    })
                }
            </TextField>
            <TextField id="current-snapshot" label="COMPARED TO" required variant="filled" select
                // defaultValue={''}
                value={selectedComparedTo}
                SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }} sx={{ width: '20%', svg: { color: theme.palette.svg.main } }}
                InputProps={{ style: { backgroundColor: '#FFFFFF' } }}
                onChange={(e) => {
                    console.log(e.target.value);
                    dispatch(projectSummaryActions.setSelectedComparedToDate({ selectedComparedTo: e.target.value }));
                    compareToDateChangeHandler(e.target.value);
                }}
            >
                {
                    comparedToSnapshots.map((snapshot, i) => {
                        return (<MenuItem
                            sx={
                                { fontWeight: 300, padding: 1.5 }
                            }
                            key={snapshot.snapshot_date}
                            value={snapshot.snapshot_date}
                        >
                            <Stack width="100%" direction="row" justifyContent="space-between" alignItems="end">
                                <Typography sx={{ fontWeight: 350 }}>{snapshot.snapshot_date}</Typography>
                                <Typography sx={{ color: theme.palette.highlight.main }} variant="caption">{snapshot.reference ? 'Reference' : ''}</Typography>
                            </Stack>
                        </MenuItem>)
                    })
                }
            </TextField>
            <Button variant="contained" color="secondary" size="large" sx={{ height: '54px' }} onClick={timelinessButtonHandler}>
                <Stack direction="row" justifyContent="space-between" columnGap={1} alignItems="center">
                    <TimerIcon style={{ fontSize: 25, marginLeft: 0 }} />
                    <Typography variant="subtitle1">Task Timeliness</Typography>
                </Stack>
            </Button>
        </Stack>
    );
}

export default SnapshotDates;