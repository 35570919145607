import { useDispatch, useSelector } from "react-redux";
import { projectSummaryActions } from "../../store";
import { useTheme } from "@emotion/react";
import { Stack, Box, Modal, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { addNotes, fetchProjectNotes } from '../../services/projectSummaryServices';
import { useNavigate } from "react-router-dom";

const AddNotesModal = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const notesModal = useSelector((state) => state.projectSummary.notesModal);
    const selectedProjectId = useSelector((state) => state.projectSummary.selectedProjectId);
    const [note, setNote] = useState('');
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
    };

    const onNotesAddHandler = async () => {
        const project_uid = selectedProjectId.project_uid;
        let data = { note: note, project_id: project_uid };
        try {
            dispatch(projectSummaryActions.setLoader({ value: true }));
            await addNotes(data);
            const response = await fetchProjectNotes(project_uid);
            dispatch(projectSummaryActions.setProjectNotes({ notes: response.data.notes }));
            setNote('');
            dispatch(projectSummaryActions.setNotesModalClose());
            dispatch(projectSummaryActions.setLoader({ value: false }));
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'success', message: 'Note Added !!!' } }));
        }
        catch (error) {
            dispatch(projectSummaryActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={notesModal}
            onClose={() => {
                dispatch(projectSummaryActions.setNotesModalClose());
                setNote('');
            }}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <TextField
                        FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                        helperText={<Stack direction="row" justifyContent={note.length < 3 && note !== '' ? "space-between" : "flex-end"}>
                            {note.length < 3 && note !== '' && <Typography variant="caption">min 3 and max 100 chars</Typography>}
                            <Typography variant="caption">{note.length}/100</Typography>
                        </Stack>}
                        error={note.length < 3 && note !== ''}
                        id="notes-box"
                        label="Enter Notes Here"
                        multiline
                        rows={5}
                        value={note}
                        onChange={(e) => {
                            if (e.target.value.length <= 100) {
                                setNote(e.target.value);
                            }
                            else {
                                return null;
                            }
                        }}
                    />
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={() => {
                            dispatch(projectSummaryActions.setNotesModalClose());
                            setNote('');
                        }}
                        >Cancel</Button>
                        <Button disabled={note.length < 3} onClick={onNotesAddHandler}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default AddNotesModal;