import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { selfDirectednessActions } from "../../store";

const SearchField = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const loggedinUserEmail = useSelector((state) => state.user.userEmail);
    let manager = useSelector((state) => state.user.manager);
    let feedback = useSelector((state) => state.selfDirectedness.feedback);
    let filteredFeedback = useSelector((state) => state.selfDirectedness.filteredFeedback);
    if (!manager) {
        filteredFeedback = filteredFeedback.filter((feedback) => feedback.contributor_email == loggedinUserEmail);
    }
    
    return (
        <TextField id="search-in-selfDirectedness" label="Search" variant="filled"
            InputProps={{ style: { backgroundColor: theme.palette.searchInput.main, color: theme.palette.searchInput.contrastText } }}
            sx={{ width: '60%' }}

            onChange={(e) => {
                dispatch(selfDirectednessActions.setPage({ value: 0 }));
                dispatch(selfDirectednessActions.setRowsPerPage({ value: 10 }));
                const seachedText = e.target.value.toLowerCase();
                const filteredTasks = feedback.filter((data) => {
                    return (data.contributor_name.toLowerCase().includes(seachedText))
                });
                dispatch(selfDirectednessActions.setFilteredFeedback({ filteredFeedback: filteredTasks }));
            }}
        />
    );
}

export default SearchField;