import { TextField, MenuItem } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { contributorDetailActions } from "../../store";
import { fetchSnapshotDates } from '../../services/projectSummaryServices'
import { useNavigate } from "react-router-dom";

const ProjectDropdown = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projects = useSelector((state) => state.contributorDetail.projects);
    const selectedProject = useSelector((state) => state.contributorDetail.selectedProject);

    const flattenedProjects = projects.reduce((acc, collection) => {
        acc.push({ label: 'collection_name', ...collection });
        collection.projects.forEach((project) => {
            acc.push({ label: 'project_name', ...project });
        });
        return acc;
    }, []);

    const fetchSnapshotHandler = async (project_uid) => {
        try {
            dispatch(contributorDetailActions.setLoader({ value: true }));
            const response = await fetchSnapshotDates(project_uid);
            dispatch(contributorDetailActions.setProjectSnapshotDates({ snapshots: response.data.snapshotDates }));
            const currentSnapshots = [...response.data.snapshotDates];
            let selectedSnapshotDate = currentSnapshots.length > 0 ? currentSnapshots[0]?.snapshot_date : '';
            dispatch(contributorDetailActions.setSelectedSnapshotDate({ selectedSnapshotDate: selectedSnapshotDate }));
            dispatch(contributorDetailActions.setSelectedComparedToDate({ selectedComparedTo: '' }));
            dispatch(contributorDetailActions.setComparedToSnapshotDates());
            dispatch(contributorDetailActions.setContributorData({ contributorData: [] }));
            dispatch(contributorDetailActions.resetFilters());
            dispatch(contributorDetailActions.setLoader({ value: false }));
            dispatch(contributorDetailActions.setSnackbar({ value: { open: true, type: 'success', message: 'snapshot dates fetched !!!' } }));
        }
        catch (error) {
            dispatch(contributorDetailActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(contributorDetailActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <TextField id="project" label="PROJECT" variant="filled" select
            value={selectedProject === null ? '' : JSON.stringify(selectedProject)}
            SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }}
            sx={{ width: '22%', svg: { color: theme.palette.svg.main } }}
            onChange={(e) => {
                const value = JSON.parse(e.target.value);
                dispatch(contributorDetailActions.setSelectedProject({ project: value }));
                fetchSnapshotHandler(value.project_uid);
            }}
        >
            {
                flattenedProjects.map((item, index) => (
                    <MenuItem
                        sx={
                            item.label === 'collection_name' ? { fontWeight: 500, padding: 1.5 } : { fontWeight: 350, padding: 0.8, paddingLeft: '20px' }
                        }
                        key={item.label === 'collection_name' ? item.collection_id + item.collection_name : item.id + item.project_name + item.project_uid}
                        value={item.label === 'collection_name' ? item.collection_id : JSON.stringify({ id: item.id, project_uid: item.project_uid })}
                        disabled={item.label === 'collection_name'}
                    >
                        {item.label === 'collection_name' ? item.collection_name : item.project_name}
                    </MenuItem>
                ))
            }
        </TextField>
    );
}

export default ProjectDropdown;