import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { allocationActions } from '../../store/index';
import dayjs from 'dayjs';
import { useTheme } from '@emotion/react';

const StartDateDropdown = (props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const startDate = useSelector((state) => state.allocation.startDate);
    const duration = useSelector((state) => state.allocation.duration);
    const selectedCollections = useSelector((state) => state.allocation.selectedCollections);
    const selectedProjects = useSelector((state) => state.allocation.selectedProjects);
    const { fetchAllocationData } = props;

    const startDateChangeHandler = (startDate) => {
        const data = {
            startDate,
            duration,
            requested_collections: selectedCollections,
            requested_projects: selectedProjects,
        }
        fetchAllocationData(data, 'startDate');
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                sx={{
                    width: '300px',
                    svg: { color: theme.palette.svg.main },
                    color: theme.palette.datePicker.main,
                    input: { color: theme.palette.lighter.contrastText1 }
                }}
                label="Start Date"
                format="DD/MM/YYYY"
                value={dayjs(startDate)}
                required
                slotProps={{
                    field: {
                        readOnly: true
                    },
                    textField: {
                        helperText: 'Pick a date +/-6 months from today',
                        FormHelperTextProps: { sx: { marginLeft: 0 } },
                        InputProps: { style: { backgroundColor: theme.palette.searchInput.main, fontWeight: 350 } },
                        variant: "filled"
                    }
                }}
                minDate={dayjs(moment().subtract(6, 'M'))}
                maxDate={dayjs(moment().add(6, 'M'))}
                views={['day']}
                onChange={(value) => {
                    dispatch(allocationActions.setStartDate({ startDate: moment(new Date(value.$d)).format("YYYY-MM-DD") }));
                    dispatch(allocationActions.setSelectedCollection({ selectedCollection: -1 }));
                    startDateChangeHandler(moment(new Date(value.$d)).format("YYYY-MM-DD"));
                }}
            />
        </LocalizationProvider>
    );
}

export default StartDateDropdown;