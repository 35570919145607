import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#2F3036',
            contrastText: '#C6C5D0'
        },
        active: {
            main: '#364379',
            contrastText: '#FFF'
        },
        surface: {
            main: '#121318',
            contrastText: '#E3E1E9'
        },
        surface1: {
            main: '#292A2F',
            contrastText: '#E3E1E9'
        },
        surface2: {
            main: '#2F3036',
            contrastText: '#E3E1E9'
        },
        secondary: {
            main: '#E4BAD9',
            contrastText: '#FFF'
        },
        light: {
            main: '#292A2F',
            contrastText: '#C6C5D0'
        },
        lighter: {
            main: "#45464F",
            contrastText: '#C6C5D0',
            contrastText1: '#A9C7FF'
        },
        highlight: {
            main: '#D6E3FF',
        },
        card: {
            main: '#292A2F',
            contrastText: '#C6C5D0',
            contrastText1: '#A9C7FF'
        },
        svg: {
            main: '#FFF'
        },
        datePicker: {
            main: '#45464F'
        },
        disabled: {
            main: '#75546F'
        },
        searchInput: {
            main: "#45464F",
            contrastText: '#C6C5D0',
            contrastText1: '#A9C7FF'
        }
    },
    typography: {
        fontFamily: `"${[
            'Nunito',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(',')}"`,
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#2F3036',
                    contrastText: '#C6C5D0'
                },

            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#6E85B2',
                    color: '#FFFFFF',
                    fontSize: '12px',
                    fontWeight: 'bold',
                },
                arrow: {
                    "&:before": {
                        border: '2px solid #6E85B2'
                    },
                    color: '#6E85B2',
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "#1B262C",
                        color: '#FFFFFF'
                    },
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: '#C6C5D0'
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& label': {
                        color: '#C6C5D0',
                    },
                    '& label.Mui-focused': {
                        color: '#FFF',
                    },
                    '& .MuiInputLabel-root': {
                        '&.Mui-error': {
                            // color: '#BA1A1A',
                            color: '#E46962'
                        },
                        '&.Mui-disabled': {
                            color: '#75546F'
                        }
                    },
                    '&.MuiInput-underline:hover:before': {
                        borderColor: '#FFF'
                    },
                    '& .MuiOutlinedInput-root': {
                        color: '#FFF',
                        '& fieldset': {
                            borderColor: '#C6C5D0',
                        },
                        '&:hover fieldset': {
                            borderColor: '#FFF',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#FFF',
                        },
                        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                            // borderColor: '#BA1A1A', // Border color when error
                            borderColor: '#E46962'
                        },
                        '&.Mui-disabled': {
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#75546F'
                            },
                            '.MuiInputBase-input': {
                                WebkitTextFillColor: "#75546F",

                            }
                        },
                    },
                },
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#D6E3FF',
                    '&.Mui-error': {
                        color: '#E46962',
                    },
                }
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#45464F',
                    color: '#A9C7FF',
                    fontWeight: 350
                },
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontWeight: 350,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    color: '#A9C7FF',
                },
                select: {
                    backgroundColor: '#45464F',
                    color: '#A9C7FF',
                    fontWeight: 350,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#C6C5D0',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                underline: {
                    '&:hover:not(.Mui-disabled):before': {
                        borderBottomColor: '#C6C5D0',
                    },
                    '&.Mui-focused:after': {
                        borderBottomColor: '#FFF',
                    },
                    '&:before': {
                        borderBottomColor: '#C6C5D0', // Default color
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    backgroundColor: '#364379', // Set your desired color
                    '&:hover': {
                        backgroundColor: '#1F2D61', // Set your desired hover color
                    },
                    '&:disabled': {
                        color: '#75546F',
                    }
                },
                text: {
                    color: '#E4BAD9',
                    '&:hover': {
                        backgroundColor: '#5C3D56', // Set your desired hover color
                    },
                    '&:disabled': {
                        color: '#75546F',
                    }
                }
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": { width: 2, height: 2 },
                    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                        backgroundColor: '#C6C5D0',
                        borderRadius: '5px'
                    },
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#D6E3FF',
                    '&.Mui-checked': {
                        color: '#D6E3FF',
                    },
                    '&.Mui-disabled': {
                        color: '#75546F'
                    }
                }
            },
        },
        MuiSwitch: {
            styleOverrides: {
                track: {
                    backgroundColor: '#FFF', // Set your desired track color
                    opacity: 0.2,
                    ".Mui-checked.Mui-checked + &": {
                        backgroundColor: '#FFF'
                    }
                },
                switchBase: {
                    color: "#FFDAD6",
                    "&.Mui-checked": {
                        color: "#FFB4AB",
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: '#FFDAD6',
                    "&.Mui-checked": {
                        color: "#FFB4AB"
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    color: '#C6C5D0',
                    backgroundColor: '#45464F',
                    '&:hover': {
                        color: '#C6C5D0',
                        backgroundColor: '#45464F'
                    }
                },
                colorPrimary: {
                    color: '#000',
                    backgroundColor: '#E4BAD9',
                    '&:hover': {
                        color: '#000',
                        backgroundColor: '#E4BAD9',
                    }
                },
                colorSecondary: {
                    color: '#000',
                    backgroundColor: '#E4BAD9',
                    '&:hover': {
                        color: '#000',
                        backgroundColor: '#E4BAD9',
                    }
                },
                colorSuccess: {
                    color: '#FFF',
                    backgroundColor: '#388e3c',
                    '&:hover': {
                        color: '#FFF',
                        backgroundColor: '#388e3c',
                    }
                },
                colorWarning: {
                    color: '#FFF',
                    backgroundColor: '#f57c00',
                    '&:hover': {
                        color: '#FFF',
                        backgroundColor: '#f57c00',
                    }
                },
                colorError: {
                    color: '#FFF',
                    backgroundColor: '#d32f2f',
                    '&:hover': {
                        color: '#FFF',
                        backgroundColor: '#d32f2f',
                    }
                },
                colorInfo: {
                    color: '#FFF',
                    backgroundColor: '#1976D2',
                    '&:hover': {
                        color: '#FFF',
                        backgroundColor: '#1976D2',
                    }
                }
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                input: {
                    color: '#A9C7FF',
                    fontWeight: 350,
                },
                paper: {
                    color: '#A9C7FF',
                    backgroundColor: '#45464F'
                }
            },
        },
    },
});


export default theme;