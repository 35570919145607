import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import { CircularProgress, Snackbar, Alert, Backdrop, Box, CssBaseline } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { loggedInUserActions } from '../../store';
import Headers from './Headers';
import AppDrawer from './AppDrawer';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: theme.spacing(0, 1),
    height: '100px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const MainNavigation = () => {
    const dispatch = useDispatch();
    const snackbar = useSelector((state) => state.user.snackbar);
    const loader = useSelector((state) => state.user.loader);
    const theme = useTheme();

    const handleSnackbarClose = () => {
        dispatch(loggedInUserActions.setSnackbar({ value: { open: false, type: 'success', message: '' } }));
    }

    return (
        <Box sx={{ display: 'flex', backgroundColor: theme.palette.surface.main, minHeight: '100vh', color: theme.palette.surface.contrastText }}>
            <CssBaseline />
            <Headers />
            <AppDrawer />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Outlet />
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert severity={snackbar.type} sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
                <Backdrop
                    // sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    sx={{
                        zIndex: (theme) =>
                            Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
                    }}
                    open={loader}
                >
                    <CircularProgress color="secondary" />
                </Backdrop>
            </Box>
        </Box>
    );
}

export default MainNavigation;