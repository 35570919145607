import React, { useEffect, useRef, useState } from "react";
import { Modal, Box, Typography, Stack, Button, TextField, MenuItem, Divider } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { leaveActions } from "../../store";
import { applyCompOff, fetchMyLeaveData } from "../../services/leaveServices";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import moment from "moment";
import { firstDayOfFinancialYear } from "../../Utils/leaveHelpers";

const CompOffModal = ({ style, dateStyle, textFieldStyle }) => {
    const compOffRef = useRef();
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedManager, setSelectedManager] = useState('');
    const [saveButton, setSaveButton] = useState(false);
    const [notes, setNotes] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const compOffModal = useSelector((state) => state.leave.compOffModal);
    const managers = useSelector((state) => state.leave.managers);
    const loggedInUser = useSelector((state) => state.user.userEmail);

    const resetData = () => {
        setSelectedManager('');
        setNotes('');
        setSaveButton(false);
        setFromDate('');
        setToDate('');
    }

    const submitCompOffHandler = async () => {
        try {
            let data = { manager: selectedManager, from: fromDate, to: toDate, note: notes };
            dispatch(leaveActions.setLoader({ value: true }));
            const response = await applyCompOff(data);
            const myLeave = await fetchMyLeaveData();
            dispatch(leaveActions.setMyLeave({ myLeave: myLeave.data.myleaves }));
            dispatch(leaveActions.setFilteredMyLeave({ filteredMyLeave: myLeave.data.myleaves }));
            dispatch(leaveActions.setLeaveBalance({ leaveBalance: myLeave.data.leaveBalance }));
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'success', message: response?.data?.msg || 'comp-off requested!!' } }));
            dispatch(leaveActions.setLoader({ value: false }));
            resetData();
            dispatch(leaveActions.setCompOffModalClose());
            dispatch(leaveActions.resetFilters());
        }
        catch (error) {
            dispatch(leaveActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (compOffRef.current) return;
        compOffRef.current = true;
        resetData();
    });


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={compOffModal}
            onClose={() => {
                dispatch(leaveActions.setCompOffModalClose());
                resetData();
            }}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Add compensatory off</Typography>
                    <Divider />
                    <TextField
                        sx={{ width: '50%', svg: { color: theme.palette.svg.main } }}
                        id="select manager"
                        required
                        label="Select Manager"
                        variant="outlined"
                        select
                        value={selectedManager}
                        SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }}
                        onChange={(e) => {
                            setSelectedManager(e.target.value);
                            setSaveButton(notes.length >= 5 && e.target.value && fromDate && toDate);
                        }}
                    >
                        {managers.map((manager, index) => (
                            <MenuItem
                                key={manager.emp_id + manager.email}
                                value={manager.email}
                                sx={{ padding: 1 }}
                                disabled={manager.email === loggedInUser}
                            >
                                {manager.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Typography variant="caption" sx={{ color: theme.palette.highlight.main }}>Your manager will approve a comp-off request only if you have already
                        discussed the matter with them. Comp-offs are an exception, not the norm.</Typography>

                    <Stack direction="row" columnGap={1} sx={{ width: '100%' }} alignItems="center">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                variant="outlined"
                                sx={dateStyle}
                                label="Comp-off Dates, From"
                                format="DD-MMM-YYYY"
                                defaultValue={''}
                                value={dayjs(fromDate)}
                                required
                                slotProps={{ field: { readOnly: true }, textField: textFieldStyle }}
                                minDate={dayjs(firstDayOfFinancialYear(moment().format('YYYY-MM-DD')))}
                                maxDate={dayjs(moment().format('YYYY-MM-DD'))}
                                disabled={!selectedManager}
                                views={['day']}
                                onChange={(value) => {
                                    setToDate('');
                                    setSaveButton(false);
                                    setFromDate(moment(new Date(value.$d)).format("YYYY-MM-DD"));
                                }}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={dateStyle}
                                label="Comp-off Dates, To"
                                format="DD-MMM-YYYY"
                                defaultValue={''}
                                value={dayjs(toDate)}
                                required
                                slotProps={{ field: { readOnly: true }, textField: textFieldStyle }}
                                minDate={dayjs(moment(fromDate).format('YYYY-MM-DD'))}
                                maxDate={dayjs(moment().format('YYYY-MM-DD'))}
                                disabled={fromDate === ''}
                                views={['day']}
                                onChange={(value) => {
                                    setSaveButton((selectedManager) && (fromDate) && (moment(new Date(value.$d)).format("YYYY-MM-DD")) && notes.length >= 5);
                                    setToDate(moment(new Date(value.$d)).format("YYYY-MM-DD"));
                                }}
                            />
                        </LocalizationProvider>
                    </Stack>

                    <TextField
                        FormHelperTextProps={{ sx: { display: 'flex', justifyContent: 'flex-end' } }}
                        helperText={notes.length < 5 && notes.length > 0 ? 'min 5 and max 100 chars' : `${notes.length}/100`}
                        error={notes.length < 5 && notes.length > 0}
                        id="notes"
                        label="Notes"
                        variant="outlined"
                        value={notes}
                        required
                        onChange={(e) => {
                            if (e.target.value.length <= 100) {
                                setNotes(e.target.value);
                                setSaveButton(e.target.value.length >= 5 && selectedManager && fromDate && toDate);
                            }
                        }}
                    />

                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button
                            onClick={() => {
                                dispatch(leaveActions.setCompOffModalClose());
                                resetData();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button disabled={!saveButton} onClick={submitCompOffHandler}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    )
}

export default CompOffModal;