import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: false,
    snackbar: { open: false, type: 'success', message: '' },
    contributors: [],
    projects: [],
    selectedContributor: null,
    selectedProject: null,
    projectSnapshotDates: [],
    comparedToSnapshots: [],
    selectedSnapshotDate: '',
    selectedComparedTo: '',
    contributorData: [],
    filteredContributorData: [],
    pages: [10, 50, 100],
    page: 0,
    rowsPerPage: 10,
    filtersSelected: false,
    taskFiltersModal: false,
}

const contributorDetailSlice = createSlice({
    name: 'contributorDetail',
    initialState: initialState,
    reducers: {
        setLoader(state, action) {
            state.loader = action.payload.value;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload.value;
        },
        resetAllData(state, action) {
            state.contributors = [];
            state.projects = [];
            state.selectedContributor = null;
            state.selectedProject = null;
            state.projectSnapshotDates = [];
            state.comparedToSnapshots = [];
            state.selectedSnapshotDate = '';
            state.selectedComparedTo = '';
            state.contributorData = [];
            state.filteredContributorData = [];
            state.filtersSelected = false;
        },
        setContributors(state, action) {
            state.contributors = action.payload.contributors;
        },
        setProjects(state, action) {
            state.projects = action.payload.projects;
        },
        setSelectedContributor(state, action) {
            state.selectedContributor = action.payload.contributor;
        },
        setSelectedProject(state, action) {
            state.selectedProject = action.payload.project;
        },
        setProjectSnapshotDates(state, action) {
            state.projectSnapshotDates = action.payload.snapshots
        },
        setComparedToSnapshotDates(state, action) {
            let index = 0;
            state.projectSnapshotDates.forEach((obj, i) => {
                if (obj.snapshot_date === state.selectedSnapshotDate) {
                    index = i;
                }
            });
            state.comparedToSnapshots = state.projectSnapshotDates.slice(index + 1);
        },
        setSelectedSnapshotDate(state, action) {
            state.selectedSnapshotDate = action.payload.selectedSnapshotDate;
        },
        setSelectedComparedToDate(state, action) {
            state.selectedComparedTo = action.payload.selectedComparedTo;
        },
        setContributorData(state, action) {
            state.contributorData = action.payload.contributorData;
            state.filteredContributorData = action.payload.contributorData;
        },
        setFilteredContributorData(state, action) {
            state.filteredContributorData = action.payload.filteredContributorData;
        },
        setPage(state, action) {
            state.page = action.payload.value;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload.value;
        },
        resetFilters(state, action) {
            state.page = 0;
            state.rowsPerPage = state.pages[0];
            state.filtersSelected = false;
        },
        setFilterSelected(state, action) {
            state.filtersSelected = action.payload.value;
        },
        setTaskFilterModalOpen(state, action) {
            state.taskFiltersModal = true;
        },
        setTaskFilterModalClose(state, action) {
            state.taskFiltersModal = false;
        },
        setfilteredTaskDetails(state, action) {
            state.filteredContributorData = action.payload.filteredContributorData;
        },
    }
});

export default contributorDetailSlice;