import { Box, Stack } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
import ContributorDropdown from "./ContributorDropdown";
import ProjectDropdown from "./ProjectDropdown";
import SnapshotDateDropdown from "./SnapshotDateDropdown";

const Dropdowns = () => {
    const theme = useTheme();

    const style = {
        backgroundColor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        minheight: '70vh', width: '100%', borderRadius: '10px',
        padding: 5
    }

    return (
        <Box sx={style}>
            <Stack direction="row" columnGap={3} justifyContent="space-between" alignItems="center">
                <ContributorDropdown />
                <ProjectDropdown />
                <SnapshotDateDropdown />
            </Stack>
        </Box>
    );
}

export default Dropdowns;