import { useTheme } from "@emotion/react";
import { Modal, Box, Typography, Stack, Link, TextField, Button } from "@mui/material";
import { loggedInUserActions } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateFigmaToken } from "../../services/adminServices";

const FigmaTokenModal = ({ figmaOpen, handleFigmaClose }) => {
    const theme = useTheme();
    const figmaToken = useSelector((state) => state.user.figmaToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.lighter.main,
        color: theme.palette.lighter.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };

    const updateFigma_Token = async (figma_token) => {
        try {
            dispatch(loggedInUserActions.setLoader({ value: true }));
            await updateFigmaToken(figma_token);
            dispatch(loggedInUserActions.setLoader({ value: false }));
            dispatch(loggedInUserActions.setSnackbar({ value: { open: true, type: 'success', message: 'Figma token updated!!' } }));
            handleFigmaClose();
        }
        catch (error) {
            dispatch(loggedInUserActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(loggedInUserActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            open={figmaOpen}
            onClose={handleFigmaClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack rowGap={2}>
                    <Typography variant="h5">My Figma access token</Typography>
                    <Stack rowGap={0}>
                        <Typography variant="body2">Your Figma access token will be used to fetch data from the Figma API.</Typography>
                        <Typography variant="body2">To generate a token, follow the instruction here: <Link sx={{ color: theme.palette.secondary.main }}>https://www.figma.com/developers/api#access-tokens</Link></Typography>
                    </Stack>
                    <TextField label="Figma access token" required variant="outlined"
                        value={figmaToken}
                        FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                        helperText={
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="caption">Figma tokens are exactly 45 characters long</Typography>
                                <Typography variant="caption">{figmaToken.length}/{45}</Typography>
                            </Stack>
                        }
                        onChange={(e) => {
                            if (e.target.value.length <= 45) {
                                dispatch(loggedInUserActions.setFigmaToken({ figmaToken: e.target.value }));
                            }
                        }}
                        error={figmaToken.length < 45}
                    />
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={handleFigmaClose}>Cancel</Button>
                        <Button disabled={figmaToken.length === 0 || figmaToken.length !== 45} onClick={() => updateFigma_Token(figmaToken)}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default FigmaTokenModal;