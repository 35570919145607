import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux/es/hooks/useSelector";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { projectSummaryActions } from '../../store/index';
import { fetchAllProjects } from '../../services/projectSummaryServices';
import EmptyPage from "../EmptyPage";
import SelectProjects from "./SelectProjects";
import ProjectSummaryLoader from "./ProjectSummaryLoader";
import { useNavigate } from "react-router-dom";

function ProjectSummary() {
    const projectsFetchedRef = useRef(false);
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.projectSummary.projects);
    const navigate = useNavigate();

    const fetchProjects = async () => {
        try {
            dispatch(projectSummaryActions.setLoader({ value: true }));
            const response = await fetchAllProjects();
            dispatch(projectSummaryActions.setLoader({ value: false }));
            dispatch(projectSummaryActions.setProjects({ projects: response.data.projects }));
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data Fetched' } }));

        }
        catch (error) {
            dispatch(projectSummaryActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (projectsFetchedRef.current) return;
        projectsFetchedRef.current = true;
        fetchProjects();
        dispatch(projectSummaryActions.setSelectedProjectId({ id: null }));
        dispatch(projectSummaryActions.setProjectSnapshotDates({ snapshots: [] }));
        dispatch(projectSummaryActions.setSelectedSnapshotDate({ selectedSnapshotDate: '' }));
        dispatch(projectSummaryActions.setSelectedComparedToDate({ selectedComparedTo: '' }));
        dispatch(projectSummaryActions.setEffortProgressOff());
        dispatch(projectSummaryActions.setProgressEffort({ effort: 0 }));
    }, [projects]);

    return (
        <Box>
            <Stack rowGap={3}>
                <Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <SelectProjects />
                    </Stack>
                </Stack>
                <Stack alignItems='center'>
                    <EmptyPage text="Select a project to view summary" />
                </Stack>
            </Stack>
            <ProjectSummaryLoader />
        </Box >
    );
}

export default ProjectSummary;