import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useDispatch, useSelector } from 'react-redux';
import { updateVoteRequest, fetchTimelinessData } from '../../services/timelinessServices';
import { taskTimelinessActions } from '../../store';
import { useNavigate } from 'react-router-dom';

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));

const customIcons = {
    1: {
        icon: <ThumbUpIcon color="success" />,
        label: 'thumbs up',
    },
    2: {
        icon: <ThumbDownIcon color="error" />,
        label: 'thumbs down',
    }
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};

export default function UpvoteIcons(props) {
    let { upvote, taskId, delay, comment } = props;
    let upvoteValue;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedSnapshotDate = useSelector((state) => state.taskTimeliness.selectedSnapshotDate);
    const selectedComparedTo = useSelector((state) => state.taskTimeliness.selectedComparedTo);
    const selectedProjectId = useSelector((state) => state.taskTimeliness.selectedProjectId);
    const filter1 = useSelector((state) => state.taskTimeliness.filter1);
    const filter2 = useSelector((state) => state.taskTimeliness.filter2);
    let manager = useSelector((state) => state.user.manager);

    switch (upvote) {
        case 1: upvoteValue = 1;
            break;
        case 0: upvoteValue = 2;
            break;
        default:
            upvoteValue = 0;
            break;
    }

    const updateVote = async (vote, value) => {
        try {
            vote = !vote ? null : vote;
            vote = value == 2 && vote ? false : vote;
            delay = delay || null;
            let data = {
                snapshot_date: selectedSnapshotDate,
                baseline_date: selectedComparedTo,
                project_id: selectedProjectId.project_uid,
                task_id: taskId,
                corrected_delay: delay,
                comments: comment,
                upvote: vote,
            };
            dispatch(taskTimelinessActions.setLoader({ value: true }));
            await updateVoteRequest(data);
            const response = await fetchTimelinessData(selectedProjectId.project_uid, selectedSnapshotDate, selectedComparedTo, filter1, filter2);
            dispatch(taskTimelinessActions.setTimelinessData({ timelinessData: response.data.delayArray }));
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'success', message: 'Vote Updated !!!' } }));
        }
        catch (error) {
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const taskVotingHandler = (e) => {
        if (e.target.value === 1) {
            console.log('thumbs up', e);
            setTimeout(() => {
                console.log(e.target.checked);
                updateVote(e.target.checked, e.target.value);
            }, 500);
        }
        else {
            console.log('thumbs down', e);
            setTimeout(() => {
                console.log(e.target.checked);
                updateVote(e.target.checked, e.target.value);
            }, 500);
        }
    }

    return (
        <StyledRating
            max={2}
            name="highlight-selected-only"
            defaultValue={upvoteValue}
            IconContainerComponent={IconContainer}
            getLabelText={(value) => customIcons[value].label}
            highlightSelectedOnly
            onChange={(e) => {
                return manager ? taskVotingHandler(e) : null;
            }}
            disabled={!manager}
        />
    );
}