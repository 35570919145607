import React, { } from "react";
import { Modal, Box, Typography, Stack, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { projectDetailsActions } from "../../../store/index";
import { useNavigate, useParams } from "react-router-dom";
import { projectDetailsData, deleteSnapshot } from "../../../services/collectionServices";

const DeleteSnapshotModal = () => {
    const dispatch = useDispatch();
    const deleteSnapshotModal = useSelector((state) => state.projectDetails.deleteSnapshotModal);
    const deleteSnapshotId = useSelector((state) => state.projectDetails.deleteSnapshotId);
    const theme = useTheme();
    const navigate = useNavigate();
    let params = useParams();
    const project_id = params.project_id;
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };

    const snapshotDeleteHandler = async () => {
        try {
            dispatch(projectDetailsActions.setLoader({ value: true }));
            await deleteSnapshot(deleteSnapshotId);
            const response = await projectDetailsData(project_id);
            dispatch(projectDetailsActions.setProjectDetails({ projectDetails: response.data }));
            dispatch(projectDetailsActions.setLoader({ value: false }));
            dispatch(projectDetailsActions.setSnackbar({ value: { open: true, type: 'success', message: 'snapshot deleted !!!' } }));
            dispatch(projectDetailsActions.setDeleteSnapshotModalClose());
        }
        catch (error) {
            dispatch(projectDetailsActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectDetailsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                dispatch(projectDetailsActions.setDeleteSnapshotModalClose())
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={deleteSnapshotModal}
            onClose={() => dispatch(projectDetailsActions.setDeleteSnapshotModalClose())}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Delete Snapshot</Typography>
                    <Typography>Are you sure you want to delete this snapshot?</Typography>
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={() => dispatch(projectDetailsActions.setDeleteSnapshotModalClose())}>Cancel</Button>
                        <Button onClick={snapshotDeleteHandler}>Yes, Delete</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default DeleteSnapshotModal;