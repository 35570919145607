import { TextField, MenuItem, Typography, } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { allocationActions } from "../../store";

const DurationDropdown = (props) => {
    const dispatch = useDispatch();
    const { fetchAllocationData } = props;
    const durations = [1, 2, 3, 4, 5, 6, 7, 8];
    const duration = useSelector((state) => state.allocation.duration);
    const startDate = useSelector((state) => state.allocation.startDate);
    const selectedCollections = useSelector((state) => state.allocation.selectedCollections);
    const selectedProjects = useSelector((state) => state.allocation.selectedProjects);

    const durationChangeHandler = (duration) => {
        const data = {
            startDate,
            duration,
            requested_collections: selectedCollections,
            requested_projects: selectedProjects,
        }
        fetchAllocationData(data, 'duration');
    }

    return (
        <TextField id="duration" label="DURATION" variant="filled" select
            defaultValue={'4 Weeks'}
            required
            value={duration}
            helperText={'Date range, in weeks'}
            FormHelperTextProps={{ sx: { marginLeft: 0 } }}
            SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }} sx={{ width: '300px' }}
            InputProps={{ style: { backgroundColor: '#FFFFFF' } }}
            onChange={(e) => {
                dispatch(allocationActions.setDuration({ duration: e.target.value }));
                dispatch(allocationActions.setSelectedCollection({ selectedCollection: -1 }));
                durationChangeHandler(e.target.value);
            }}
        >
            {
                durations.map((duration, i) => {
                    return (<MenuItem
                        sx={{ padding: 1.5 }}
                        key={duration}
                        value={duration}
                    >
                        <Typography sx={{ fontWeight: 350 }}>{duration}{duration > 1 ? ' Weeks' : ' Week'}</Typography>
                    </MenuItem>)
                })
            }
        </TextField>
    );
}

export default DurationDropdown;