import { useTheme } from "@emotion/react";
import { Box, Stack, Typography, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';

const Schedule = () => {
    const theme = useTheme();
    const projectSummary = useSelector((state) => state.projectSummary.projectSummary);
    const style = {
        backgroundColor: theme.palette.surface2.main,
        color: theme.palette.surface2.contrastText,
        minheight: '300px', width: '50%', borderRadius: '10px',
        padding: 3, marginTop: 3
    }

    return (
        <Box sx={style}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={2}>
                <ChangeHistoryIcon />
                <Typography variant="h6">Schedule</Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-start" marginTop={3}>
                <Stack width='33%'>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={1}>
                        <ChangeHistoryIcon style={{ color: theme.palette.highlight.main }} />
                        <Typography variant="caption" color={theme.palette.highlight.main}>Goal End Date</Typography>
                    </Stack>
                    <Typography>- {projectSummary?.schedule?.del_goal_end} days</Typography>
                </Stack>
                <Stack width='33%'>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={1}>
                        <ChangeHistoryIcon style={{ color: theme.palette.highlight.main }} />
                        <Typography variant="caption" color={theme.palette.highlight.main}>Next Milestone</Typography>
                    </Stack>
                    <Typography>- {projectSummary?.schedule?.delNextMilestone} days</Typography>
                </Stack>
                <Stack width='33%'>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={1}>
                        <ChangeHistoryIcon style={{ color: theme.palette.highlight.main }} />
                        <Typography variant="caption" color={theme.palette.highlight.main}>Buffer Last Week</Typography>
                    </Stack>
                    <Typography>- {projectSummary?.schedule?.delBuffeLastWeek} days</Typography>
                </Stack>
            </Stack>
            <Divider sx={{ marginTop: 5, backgroundColor: theme.palette.highlight.main }} />
            <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={2} marginTop={3}>
                <ChangeHistoryIcon />
                <Typography variant="h6">Rework</Typography>
            </Stack>
            <Stack direction="row" justifyContent="flex-start" marginTop={3}>
                <Stack width='50%'>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={1}>
                        <ChangeHistoryIcon style={{ color: theme.palette.highlight.main }} />
                        <Typography variant="caption" color={theme.palette.highlight.main}>Total Rework</Typography>
                    </Stack>
                    <Typography>- {projectSummary?.schedule?.delTotalRework} days</Typography>
                </Stack>
                <Stack width='50%'>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={1}>
                        <ChangeHistoryIcon style={{ color: theme.palette.highlight.main }} />
                        <Typography variant="caption" color={theme.palette.highlight.main}>Rework Last Week</Typography>
                    </Stack>
                    <Typography>- {projectSummary?.schedule?.delReworkLastWeek} days</Typography>
                </Stack>
            </Stack>
        </Box>
    );
}

export default Schedule;