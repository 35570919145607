import React, { useState } from "react";
import { Modal, Box, Typography, Stack, TextField, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { collectionsActions } from "../../store";
import { renameCollection, fetchCollectionsData } from "../../services/collectionServices";
import { useNavigate } from "react-router-dom";

const RenameCollectionModal = () => {
    const dispatch = useDispatch();
    const collectionMenuSelectedCollectionName = useSelector((state) => state.collections.collectionMenuSelectedCollectionName);
    const [saveButton, setSaveButton] = useState(false);
    const renameCollectionModal = useSelector((state) => state.collections.renameCollectionModal);
    const theme = useTheme();
    const navigate = useNavigate();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };

    const selectedCollectionId = useSelector((state) => state.collections.collectionMenuSelectedCollectionId);

    const renameCollectionHandler = async () => {
        try {
            dispatch(collectionsActions.setLoader({ value: true }));
            await renameCollection(selectedCollectionId, collectionMenuSelectedCollectionName);
            const response = await fetchCollectionsData();
            dispatch(collectionsActions.setCollections({ collections: response.data.collections }));
            dispatch(collectionsActions.setLoader({ value: false }));
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'success', message: 'Collection renamed successfully !!!' } }));
            dispatch(collectionsActions.setRenameCollectionModalClose());
        }
        catch (error) {
            dispatch(collectionsActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={renameCollectionModal}
            onClose={() => dispatch(collectionsActions.setRenameCollectionModalClose())}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Rename Collection</Typography>
                    <TextField
                        FormHelperTextProps={{ sx: { display: 'flex', justifyContent: 'flex-end' } }}
                        helperText={collectionMenuSelectedCollectionName.length < 3 && collectionMenuSelectedCollectionName.length > 0 ? 'min 3 and max 100 chars' : `${collectionMenuSelectedCollectionName.length}/100`}
                        error={collectionMenuSelectedCollectionName.length < 3 && collectionMenuSelectedCollectionName.length > 0}
                        id="collection-name"
                        label="Collection Name"
                        variant="outlined"
                        required
                        value={collectionMenuSelectedCollectionName}
                        onChange={(e) => {
                            if (e.target.value.length <= 100) {
                                dispatch(collectionsActions.setSelectedCollectionNameinCollectionMenu({ collection_name: e.target.value }));
                                setSaveButton(e.target.value.length >= 3)
                            }
                            else {
                                return null;
                            }
                        }}
                    />
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={() => dispatch(collectionsActions.setRenameCollectionModalClose())}>Cancel</Button>
                        <Button disabled={!saveButton} onClick={renameCollectionHandler}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default RenameCollectionModal;