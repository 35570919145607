import { useTheme } from "@emotion/react";
import { TextField, MenuItem } from "@mui/material";
import { fetchAllContributors } from '../../services/contributorDetailServices';
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selfDirectednessActions } from "../../store";
import { useNavigate } from "react-router-dom";
import { fetchQuestions } from "../../services/selfDirectednessService";

const ContributorDropdown = () => {
    const theme = useTheme();
    const usersRef = useRef(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const contributors = useSelector((state) => state.selfDirectedness.contributors);
    const selectedContributor = useSelector((state) => state.selfDirectedness.selectedContributor);
    const loggedinUserEmail = useSelector((state) => state.user.userEmail);
    let manager = useSelector((state) => state.user.manager);
    if (!manager) {
        navigate('/error');
    }

    const getContributors = async () => {
        try {
            dispatch(selfDirectednessActions.setLoader({ value: true }));
            const response = await fetchAllContributors();
            dispatch(selfDirectednessActions.setContributors({ contributors: response.data.contributors }));
            dispatch(selfDirectednessActions.setLoader({ value: false }));
            dispatch(selfDirectednessActions.setSnackbar({ value: { open: true, type: 'success', message: 'Contributors Fetched!!' } }));
        }
        catch (error) {
            dispatch(selfDirectednessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(selfDirectednessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const fetchQuestion = async () => {
        try {
            dispatch(selfDirectednessActions.setLoader({ value: true }));
            const response = await fetchQuestions();
            dispatch(selfDirectednessActions.setQuestions({ questions: response.data.questions }));
            dispatch(selfDirectednessActions.setLoader({ value: false }));
            dispatch(selfDirectednessActions.setSnackbar({ value: { open: true, type: 'success', message: 'Questions Fetched!!' } }));
        }
        catch (error) {
            dispatch(selfDirectednessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(selfDirectednessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (usersRef.current) return;
        usersRef.current = true;
        dispatch(selfDirectednessActions.setSelectedContributor({ selectedContributor: '' }))
        getContributors();
    });


    return (
        <TextField
            sx={{ width: '25%', svg: { color: theme.palette.svg.main } }}
            id="contributor"
            label="Contributor"
            variant="filled"
            select
            value={selectedContributor}
            SelectProps={{ MenuProps: { sx: { maxHeight: 500 } } }}
            InputProps={{ style: { fontWeight: 350 } }}
            onChange={(e) => {
                dispatch(selfDirectednessActions.setSelectedContributor({ selectedContributor: e.target.value }));
                fetchQuestion();
            }}
        >
            {contributors.map((contributor, index) => (
                <MenuItem
                    sx={{ fontWeight: 350, paddingTop: 2, paddingBottom: 2 }}
                    key={contributor.user_id + contributor.user_email}
                    value={contributor.user_email}
                    disabled={contributor.user_email == loggedinUserEmail}
                >
                    {contributor.user_name}
                </MenuItem>
            ))}
        </TextField>
    );
}

export default ContributorDropdown;