import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from "react-router-dom";
import { Toolbar, Typography, IconButton, Avatar, Stack, Menu, MenuItem, Badge, Switch } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import GitHubIcon from '@mui/icons-material/GitHub';
import ModeNight from "@mui/icons-material/ModeNight";
import LightModeIcon from '@mui/icons-material/LightMode';
import { useSelector, useDispatch } from 'react-redux';
import { loggedInUserActions, drawerActions, adminActions } from '../../store';
import LogoutIcon from '@mui/icons-material/Logout';
import GithubHandleModal from './GithubHandleModal';
import FigmaTokenModal from './FigmaTokenModal';
import { fetchGithubHandle, fetchFigmaToken } from '../../services/adminServices';
import ProfileModal from './ProfileModal';
const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    height: '100px',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Headers = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const open = useSelector((state) => state.drawer.open);
    const mode = useSelector((state) => state.admin.mode);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [profileOpen, setProfileOpen] = React.useState(false);
    const [figmaOpen, setFigmaOpen] = React.useState(false);
    const [gitOpen, setGitOpen] = React.useState(false);
    const location = useLocation();

    let screenTitle;
    switch (location.pathname) {
        case '/admin': screenTitle = 'Admin';
            break;
        case '/collections': screenTitle = 'Collections';
            break;
        case '/project-summary': screenTitle = 'Project Summary';
            break;
        case '/task-timeliness': screenTitle = 'Task Timeliness';
            break;
        case '/contributor-details': screenTitle = 'Contributor Details';
            break;
        case '/github': screenTitle = 'Github';
            break;
        case '/figma': screenTitle = 'Figma';
            break;
        case '/allocation': screenTitle = 'Allocation';
            break;
        case '/leave': screenTitle = 'Leave'
            break;
        case '/upload-snapshot': screenTitle = 'Upload Snapshot'
            break;
        case '/Self-directedness': screenTitle = 'Self-directedness Feedback';
            break;
        case '/Self-directedness/create-feedback': screenTitle = 'Add self-directedness Feedback';
            break;
        default: if (location.pathname.startsWith('/project-summary')) screenTitle = 'Project Summary';
        else if (location.pathname.startsWith('/upload-snapshot')) screenTitle = 'Upload Snapshot';
        else if (location.pathname.startsWith('/task-timeliness')) screenTitle = 'Task Timeliness';
        else screenTitle = '';
            break;
    }

    const fetchGithubUsername = async () => {
        try {
            dispatch(loggedInUserActions.setLoader({ value: true }));
            const response = await fetchGithubHandle();
            dispatch(loggedInUserActions.setGitUsername({ gitUsername: response?.data?.userDetails[0]?.github_username || '' }));
            dispatch(loggedInUserActions.setLoader({ value: false }));
            dispatch(loggedInUserActions.setSnackbar({ value: { open: true, type: 'success', message: 'Github username fetched!!' } }));
            setGitOpen(true);
        }
        catch (error) {
            dispatch(loggedInUserActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(loggedInUserActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const getFigmaToken = async () => {
        try {
            dispatch(loggedInUserActions.setLoader({ value: true }));
            const response = await fetchFigmaToken();
            dispatch(loggedInUserActions.setFigmaToken({ figmaToken: response?.data?.figmaToken[0]?.figma_token || '' }));
            dispatch(loggedInUserActions.setLoader({ value: false }));
            dispatch(loggedInUserActions.setSnackbar({ value: { open: true, type: 'success', message: 'Figma token fetched!!' } }));
            setFigmaOpen(true);
        }
        catch (error) {
            dispatch(loggedInUserActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(loggedInUserActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleDrawerOpen = () => {
        dispatch(drawerActions.openDrawer());
    };

    const handleProfileOpen = () => {
        setProfileOpen(true);
    }

    const handleProfileClose = () => {
        setProfileOpen(false);
    }

    const handleGitOpen = () => {
        fetchGithubUsername();
    }

    const handleGitClose = () => {
        setGitOpen(false);
    }

    const handleFigmaOpen = () => {
        getFigmaToken();
    }

    const handleFigmaClose = () => {
        setFigmaOpen(false);
    }

    const handleLogout = () => {
        dispatch(loggedInUserActions.logout());
        navigate('/login');
    }

    const settings = [
        { name: 'Profile', icon: <PersonIcon />, onClickFunction: handleProfileOpen },
        { name: 'My Github handle', icon: <GitHubIcon />, onClickFunction: handleGitOpen },
        { name: 'My Figma access token', icon: <PersonIcon />, onClickFunction: handleFigmaOpen },
        { name: 'Logout', icon: <LogoutIcon />, onClickFunction: handleLogout },
    ];

    let userData = useSelector((state) => state.user);

    return (
        <AppBar position="fixed" open={open} elevation={1} sx={{ display: 'flex', justifyContent: 'center', bgcolor: 'primary', color: 'contrastText' }}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Stack direction="row" sx={{ flexGrow: 1, display: 'flex', alignItems: "center" }}>
                    <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1 }}>
                        {screenTitle}
                    </Typography>
                    <Stack sx={{ marginRight: '5px' }} direction="row" alignItems="center">
                        {mode && <ModeNight />}
                        {!mode && <LightModeIcon />}
                        <Switch
                            id='mode-switch'
                            checked={mode}
                            onChange={() => {
                                dispatch(adminActions.setMode({ mode: !mode }));
                                localStorage.setItem('mode', !mode);
                            }}
                        />
                    </Stack>
                    <IconButton onClick={handleOpenUserMenu}>
                        <Badge badgeContent={userData.userType} color="active" anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}>
                            <Avatar alt={userData.fullname} src={userData.avatar} content="no-referrer" sx={{ width: 55, height: 55 }} />
                        </Badge>
                    </IconButton>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting, i) => (
                            <MenuItem key={setting.name}
                                onClick={() => {
                                    setting.onClickFunction();
                                    handleCloseUserMenu();
                                }}
                            >
                                <Stack direction="row" justifyContent="space-between" alignItems="center" columnGap={1} sx={{ marginTop: 1, marginBottom: 1 }}>
                                    {setting.icon}
                                    <Typography sx={{ fontWeight: 350 }} textAlign="center">{setting.name}</Typography>
                                </Stack>
                            </MenuItem>
                        ))}
                    </Menu>
                </Stack>
            </Toolbar>
            <ProfileModal profileOpen={profileOpen} handleProfileClose={handleProfileClose} userData={userData} />
            <GithubHandleModal gitOpen={gitOpen} handleGitClose={handleGitClose} />
            <FigmaTokenModal figmaOpen={figmaOpen} handleFigmaClose={handleFigmaClose} />
        </AppBar >
    );
}

export default Headers;