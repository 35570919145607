import { configureStore } from "@reduxjs/toolkit";
import loggedInUserSlice from "./loggedInUser";
import drawerSlice from "./drawer";
import collectionSlice from "./collections";
import adminSlice from "./admin";
import projectDetailSlice from "./projectDetails";
import projectSummarySlice from "./projectSummary";
import taskTimelinessSlice from "./taskTimeliness";
import checkSnapshotSlice from "./checkSnapshot";
import contributorDetailSlice from "./contributorDetails";
import allocationSlice from "./allocation";
import githubSlice from "./github";
import selfDirectednessSlice from "./selfDirectedness";
import leaveSlice from "./leave";

const store = configureStore({
    reducer: {
        user: loggedInUserSlice.reducer,
        drawer: drawerSlice.reducer,
        collections: collectionSlice.reducer,
        admin: adminSlice.reducer,
        projectDetails: projectDetailSlice.reducer,
        projectSummary: projectSummarySlice.reducer,
        taskTimeliness: taskTimelinessSlice.reducer,
        checkSnapshot: checkSnapshotSlice.reducer,
        contributorDetail: contributorDetailSlice.reducer,
        allocation: allocationSlice.reducer,
        github: githubSlice.reducer,
        selfDirectedness: selfDirectednessSlice.reducer,
        leave: leaveSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;
export const loggedInUserActions = loggedInUserSlice.actions;
export const drawerActions = drawerSlice.actions;
export const collectionsActions = collectionSlice.actions;
export const adminActions = adminSlice.actions;
export const projectDetailsActions = projectDetailSlice.actions;
export const projectSummaryActions = projectSummarySlice.actions;
export const taskTimelinessActions = taskTimelinessSlice.actions;
export const checkSnapshotActions = checkSnapshotSlice.actions;
export const contributorDetailActions = contributorDetailSlice.actions;
export const allocationActions = allocationSlice.actions;
export const githubActions = githubSlice.actions;
export const selfDirectednessActions = selfDirectednessSlice.actions;
export const leaveActions = leaveSlice.actions;