import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./Pages/Root";
import ErrorPage from "./Pages/Error";
import LoginPage from "./Pages/LoginPage";
import Admin from "./Components/Admin/Admin";
import Collections from "./Components/Collections/Collections";
import ProjectSummary from "./Components/Project Summary/ProjectSummary";
import Allocation from "./Components/Allocation/Allocation";
import TaskTimeliness from "./Components/TaskTimeliness/TaskTimeliness";
import ContributorDetails from "./Components/ContributorDetails/ContributorDetails";
import Github from './Components/Github/Github';
import Leave from './Components/Leave/Leave';
import ProjectDetails from "./Components/Collections/ProjectDetails/ProjectDetails";
import TaskDetails from "./Components/Project Summary/Task Details/TaskDetails";
import ProjectSummaryData from "./Components/Project Summary/ProjectSummaryData";
import TaskTimelinessData from "./Components/TaskTimeliness/TaskTimelinessData";
import CheckSnapshot from "./Components/CheckSnapshot/CheckSnapshot";
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import lightTheme from './lightTheme';
import { useSelector } from "react-redux";
import SelfDirectedness from "./Components/SelfDirectedness/SelfDirectedness";
import FeedbackCreation from "./Components/SelfDirectedness/FeedbackCreation";
import EditFeedback from './Components/SelfDirectedness/EditFeedback';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      // { path: '/admin', element: <Admin /> },
      { path: '/collections', element: <Collections /> },
      { path: '/project-summary', element: <ProjectSummary /> },
      { path: '/project-summary/:project_uid', element: <ProjectSummaryData /> },
      { path: '/task-timeliness', element: <TaskTimeliness /> },
      { path: '/task-timeliness/:project_uid', element: <TaskTimelinessData /> },
      { path: '/contributor-details', element: <ContributorDetails /> },
      { path: '/github', element: <Github /> },
      { path: '/allocation', element: <Allocation /> },
      { path: '/Self-directedness', element: <SelfDirectedness /> },
      { path: '/Self-directedness/create-feedback', element: <FeedbackCreation /> },
      { path: '/Self-directedness/edit/:feedback_id', element: <EditFeedback /> },
      { path: '/leave', element: <Leave /> },
      { path: '/collections/details/:project_id', element: <ProjectDetails /> },
      { path: '/project-summary/task-details/:project_uid/:snapshot_date', element: <TaskDetails /> },
      { path: '/upload-snapshot/:snapshot_id/:snapshot_date/:reference', element: <CheckSnapshot /> },
    ]
  },
  { path: '/login', element: <LoginPage /> },
  { path: '/error', element: <ErrorPage /> },
]);

function App() {
  const mode = useSelector((state) => state.admin.mode);
  return (
    <ThemeProvider theme={mode ? theme : lightTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
