import axios from "axios";
let baseUrl = process.env.REACT_APP_DEV_BASE_URL;

const fetchCollectionsData = async () => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/collection`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }))
    ]);
    return response;
}

const fetchContributorsData = async (selectedCollectionId) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/contributors?id=${selectedCollectionId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }))
    ]);
    return response;
}

const renameCollection = async (selectedCollectionId, collectionName) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/collection`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: {
                id: selectedCollectionId,
                name: collectionName
            }
        }))
    ]);
    return response;
}

const removeCollection = async (selectedCollectionId) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/removeCollection?id=${selectedCollectionId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        }))
    ]);
    return response;
}

const addCollection = async (collectionName) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/collection`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: {
                name: collectionName
            }
        }))
    ]);
    return response;
}

const addProject = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/project`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: data
        }))
    ]);
    return response;
}

const changeCollection = async (project_id, collection_id) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/changeCollection?project_id=${project_id}&collection_id=${collection_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }))
    ]);
    return response;
}

const removeProject = async (project_id) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/removeProject?id=${project_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }))
    ]);
    return response;
}

const editProject = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/project`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: data
        }))
    ]);
    return response;
}

const projectDetailsData = async (project_id) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/projectDetails?id=${project_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        }))
    ]);
    return response;
}

const deleteProject = async (project_id) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/project?project_id=${project_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        }))
    ]);
    return response;
}

const editContributorRole = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/contributorsRole`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: data
        })
        )
    ]);
    return response;
}

const updateSnapshotReference = async (snapshot_id, reference) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/reference?snapshot_id=${snapshot_id}&reference=${reference}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        )
    ]);
    return response;
}

const markProjectAsEnded = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/endProject`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: data
        })
        )
    ]);
    return response;
}

const deleteSnapshot = async (snapshot_id) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/snapshot?snapshot_id=${snapshot_id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        )
    ]);
    return response;
}

export {
    fetchCollectionsData,
    fetchContributorsData,
    renameCollection,
    removeCollection,
    addCollection,
    addProject,
    changeCollection,
    removeProject,
    editProject,
    projectDetailsData,
    deleteProject,
    editContributorRole,
    updateSnapshotReference,
    markProjectAsEnded,
    deleteSnapshot,
};
