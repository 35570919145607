import { useTheme } from "@emotion/react";
import { Modal, Box, Typography, Stack, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateGithubHandle } from "../../services/adminServices";
import { loggedInUserActions } from "../../store";
import { useNavigate } from "react-router-dom";

const GithubHandleModal = ({ gitOpen, handleGitClose }) => {
    const theme = useTheme();
    const gitUsername = useSelector((state) => state.user.gitUsername);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.lighter.main,
        color: theme.palette.lighter.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };

    const updateGithubUsername = async (username) => {
        try {
            dispatch(loggedInUserActions.setLoader({ value: true }));
            await updateGithubHandle(username);
            dispatch(loggedInUserActions.setLoader({ value: false }));
            dispatch(loggedInUserActions.setSnackbar({ value: { open: true, type: 'success', message: 'Github username updated!!' } }));
            handleGitClose();
        }
        catch (error) {
            dispatch(loggedInUserActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(loggedInUserActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            open={gitOpen}
            onClose={handleGitClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack rowGap={2}>
                    <Typography variant="h5">My GitHub handle</Typography>
                    <TextField label="GitHub username" required variant="outlined"
                        // defaultValue={gitUsername}
                        value={gitUsername}
                        FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                        helperText={
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="caption">Feeta uses this to fetch GitHub issues
                                </Typography>
                                <Typography variant="caption">{gitUsername.length}/{50}</Typography>
                            </Stack>
                        }
                        onChange={(e) => {
                            if (e.target.value.length <= 50) {
                                dispatch(loggedInUserActions.setGitUsername({ gitUsername: e.target.value }));
                            }
                        }}
                        error={gitUsername.length === 0}
                    />
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={handleGitClose}>Cancel</Button>
                        <Button disabled={gitUsername.length === 0} onClick={() => updateGithubUsername(gitUsername)}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default GithubHandleModal;