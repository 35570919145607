import React from "react";
import { MenuItem, FormControl, InputLabel, Select, ListItemText, Checkbox, FormHelperText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { allocationActions } from "../../store";
import { fetchAllocationsData } from '../../services/allocationServices';
import { useNavigate } from "react-router-dom";


const ProjectsDropdown = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projects = useSelector((state) => state.allocation.projects);
    const selectedProjects = useSelector((state) => state.allocation.selectedProjects);
    const selectedCollections = useSelector((state) => state.allocation.selectedCollections);
    const startDate = useSelector((state) => state.allocation.startDate);
    const duration = useSelector((state) => state.allocation.duration);
    const { fetchAllocationData, checkAllSelected } = props;

    const handleChange = (event) => {
        const value = event.target.value;
        if (value.includes(-1) && value.length > projects.length) {
            dispatch(allocationActions.setSelectedProjects({ selectedProjects: [] }));
        }
        else if (value.includes(-1) && value.length <= projects.length) {
            dispatch(allocationActions.setSelectedProjects({ selectedProjects: projects.map((project) => project.id) }));
        }
        else {
            dispatch(allocationActions.setSelectedProjects({ selectedProjects: value }));
        }
    };

    const getProjectNames = (selectedProjectIds) => {
        return projects.filter((project) => selectedProjectIds.includes(project.id)).map((project) => project.project_name).join(', ');
    }

    const handleProjectMenuClose = async () => {
        const data = {
            startDate,
            duration,
            requested_collections: [selectedCollections],
            requested_projects: selectedProjects,
        }
        try {
            dispatch(allocationActions.setLoader({ value: true }));
            if (data.requested_projects.length === 0) {
                throw Error('No Project Selected');
            }
            const response = await fetchAllocationsData(data);
            dispatch(allocationActions.setContributors({ contributors: response.data.user_names }));
            checkAllSelected(response.data.user_names);
            dispatch(allocationActions.setResult({ result: response.data.result }));
            dispatch(allocationActions.setLoader({ value: false }));
            dispatch(allocationActions.setSnackbar({ value: { open: true, type: 'success', message: `${response.data.result.length === 0 ? 'No data for current selection' : 'Data fetched !!!'}` } }));
        }
        catch (error) {
            dispatch(allocationActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(allocationActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <FormControl sx={{ width: '450px' }}>
            <InputLabel id="Projects" variant="filled" >PROJECTS *</InputLabel>
            <Select
                labelId="projects-multiple-checkbox-label"
                id="projects-multiple-checkbox"
                multiple
                value={selectedProjects}
                onChange={handleChange}
                renderValue={(selected) => getProjectNames(selected)}
                MenuProps={{ sx: { maxHeight: 400 } }}
                variant="filled"
                style={{ backgroundColor: '#FFFFFF', fontWeight: 350 }}
                onClose={handleProjectMenuClose}
            >
                <MenuItem key={-1} value={-1} sx={{ padding: 0.5 }}>
                    <Checkbox checked={selectedProjects.length === projects.length} />
                    <ListItemText primaryTypographyProps={{ style: { fontWeight: 350 } }} primary='Select All' />
                </MenuItem>
                {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id} sx={{ padding: 0.5 }}>
                        <Checkbox checked={selectedProjects.includes(project.id)} />
                        <ListItemText primaryTypographyProps={{ style: { fontWeight: 350 } }} primary={project.project_name} />
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText id="username-helper" sx={{ marginLeft: 0 }}>{`Selected ${selectedProjects.length} of ${projects.length} projects`}</FormHelperText>
        </FormControl >
    );
}

export default ProjectsDropdown;