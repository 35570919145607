import React, { } from "react";
import { Modal, Box, Typography, Stack, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { collectionsActions } from "../../store";
import { removeProject, fetchCollectionsData } from "../../services/collectionServices";
import { useNavigate } from "react-router-dom";

const RemoveFromCollectionModal = () => {
    const selectedProjectId = useSelector((state) => state.collections.projectsMenuSelectedProjectId);
    const removeFromCollectionModal = useSelector((state) => state.collections.removeFromCollectionModal);
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
    };

    const removeFromCollectionHandler = async (selectedProjectId) => {
        try {
            dispatch(collectionsActions.setLoader({ value: true }));
            await removeProject(selectedProjectId);
            const response = await fetchCollectionsData();
            dispatch(collectionsActions.setCollections({ collections: response.data.collections }));
            dispatch(collectionsActions.setLoader({ value: false }));
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'success', message: 'Project removed successfully, Removed project has been moved to Uncollected Projects !!!' } }));
            dispatch(dispatch(collectionsActions.setRemoveFromCollectionModalClose()));

        }
        catch (error) {
            dispatch(collectionsActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={removeFromCollectionModal}
            onClose={() => dispatch(collectionsActions.setRemoveFromCollectionModalClose())}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="subtitle" sx={{ fontWeight: 400 }}>Remove this project from the collection?</Typography>
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={() => dispatch(collectionsActions.setRemoveFromCollectionModalClose())}>Cancel</Button>
                        <Button onClick={() => removeFromCollectionHandler(selectedProjectId)}>Yes, Remove</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default RemoveFromCollectionModal;