import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: false,
    snackbar: { open: false, type: 'success', message: '' },
    users: [],
    attendance: [],
    mode: false,
}

const adminSlice = createSlice({
    name: 'admin',
    initialState: initialState,
    reducers: {
        setLoader(state, action) {
            state.loader = action.payload.value;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload.value;
        },
        setUsers(state, action) {
            state.users = action.payload.users
        },
        setAttendance(state, action) {
            state.attendance = action.payload.attendance;
        },
        setMode(state, action) {
            state.mode = action.payload.mode
        }
    }
});

export default adminSlice;