import { useTheme } from "@emotion/react";
import { Stack } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { selfDirectednessActions } from "../../store";

const DatesDropdown = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const fromDate = useSelector((state) => state.selfDirectedness.fromDate);
    const toDate = useSelector((state) => state.selfDirectedness.toDate);
    console.log(fromDate, toDate);
    const dateStyle = {
        width: '25%',
        svg: { color: theme.palette.svg.main },
        color: theme.palette.datePicker.main,
        input: { color: theme.palette.lighter.contrastText1 }
    }
    const textFieldStyle = {
        InputProps: { style: { backgroundColor: theme.palette.searchInput.main, fontWeight: 350 } },
        variant: "filled"
    }

    return (
        <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={5} sx={{ width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    sx={dateStyle}
                    label="From month, year"
                    format="DD MMM, YYYY"
                    value={dayjs(fromDate)}
                    required
                    slotProps={{
                        field: {
                            readOnly: true
                        },
                        textField: textFieldStyle
                    }}
                    minDate={dayjs(moment().subtract(180, 'days'))}
                    maxDate={dayjs(moment(toDate).subtract(1, 'd'))}
                    disableFuture
                    views={['day']}
                    onChange={(value) => {
                        dispatch(selfDirectednessActions.setFromDate({ fromDate: moment(new Date(value.$d)).format("YYYY-MM-DD") }));
                    }}
                />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    sx={dateStyle}
                    label="To month, year"
                    format="DD MMM, YYYY"
                    value={dayjs(toDate)}
                    required
                    slotProps={{
                        field: {
                            readOnly: true
                        },
                        textField: textFieldStyle
                    }}
                    minDate={dayjs(moment(fromDate).add(1, 'd'))}
                    disableFuture
                    views={['day']}
                    onChange={(value) => {
                        dispatch(selfDirectednessActions.setToDate({ toDate: moment(new Date(value.$d)).format("YYYY-MM-DD") }));
                    }}
                />
            </LocalizationProvider>
        </Stack>
    );
}

export default DatesDropdown;