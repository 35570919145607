import { useTheme } from "@emotion/react";
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";

const Milestones = () => {
    const theme = useTheme();
    const style = {
        backgroundColor: theme.palette.surface2.main, height: '400px', width: '50%', borderRadius: '10px',
        padding: 3, marginTop: 3, color: theme.palette.surface2.contrastText
    }

    const projectSummary = useSelector((state) => state.projectSummary.projectSummary);
    const milestones = projectSummary?.milestone_task ? projectSummary?.milestone_task : [];
    const columns = ['Type', 'Milestone', 'Current', 'Baseline', 'Status'];

    return (
        <Box sx={style}>
            <Typography variant="h6">Milestones</Typography>
            <TableContainer
                sx={{
                    backgroundColor: theme.palette.lighter.main,
                    borderRadius: '10px',
                    marginTop: 2,
                    height: '300px',
                    "&::-webkit-scrollbar": { width: 0 }
                }}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((column) => {
                                    return (
                                        <TableCell key={column} sx={{ backgroundColor: theme.palette.light.main }}>
                                            {column}
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            milestones.map((milestone) => {
                                let delayText = '';
                                if (milestone.delay_days === '' || milestone.delay_days === null) {
                                    delayText = "";
                                }
                                else if (milestone.delay_days < 0) {
                                    delayText = Math.abs(milestone.delay_days) + 'd early';
                                }
                                else if (milestone.delay_days === 0) {
                                    delayText = 'On Time';
                                }
                                else {
                                    delayText = milestone.delay_days + 'd delay';
                                }
                                return (
                                    <TableRow key={milestone.task_id}>
                                        <TableCell>{milestone.status}</TableCell>
                                        <TableCell>{milestone.task_title}</TableCell>
                                        <TableCell>{moment(milestone.end_date).format('DD-MMM')}</TableCell>
                                        <TableCell>{milestone.base_end_date ? moment(milestone.base_end_date).format('DD-MMM') : 'NA'}</TableCell>
                                        <TableCell>{delayText}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default Milestones;