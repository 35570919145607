import { useDispatch } from "react-redux";
import { leaveActions } from "../../store";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { fetchHolidays } from "../../services/leaveServices";
import Calendar from "./Calendar";

const HolidayList = ({ style }) => {
    const holidayRef = useRef(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loadHolidays = async () => {
        try {
            dispatch(leaveActions.setLoader({ value: true }));
            const response = await fetchHolidays();
            dispatch(leaveActions.setHolidays({ holidays: response.data.holidays }));
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'success', message: 'data fetched' } }));
            dispatch(leaveActions.setLoader({ value: false }));
        }
        catch (error) {
            dispatch(leaveActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (holidayRef.current) return;
        holidayRef.current = true;
        loadHolidays();
    });

    return (
        <Calendar />
    );
}

export default HolidayList;