import { useTheme } from "@emotion/react";
import SelectProjects from "./SelectProjects";
import SnapshotDates from "./SnapshotDates";
import { Typography, Box, Stack, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TimelinessLoader from './TimelinessLoader';
import TimelinessTable from "./TimelinessTable";
import moment from "moment";
import EmptyPage from '../EmptyPage';
import { useEffect, useRef } from "react";
import { taskTimelinessActions } from '../../store/index';
import { fetchTimelinessData } from '../../services/timelinessServices';
import { fetchAllProjects } from "../../services/projectSummaryServices";
import { useNavigate } from "react-router-dom";

const TaskTimelinessData = () => {
    const timelinessRef = useRef(false);
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const delayBlock = useSelector((state) => state.taskTimeliness.delayBlock);
    const selectedSnapshotDate = useSelector((state) => state.taskTimeliness.selectedSnapshotDate);
    const selectedComparedTo = useSelector((state) => state.taskTimeliness.selectedComparedTo);
    const selectedProjectId = useSelector((state) => state.taskTimeliness.selectedProjectId);

    const style = {
        backgroundColor: theme.palette.surface1.main, color: theme.palette.surface1.contrastText, minheight: '70vh', width: '100%', borderRadius: '10px',
        padding: 3
    }

    const fetchTimeliness = async () => {
        try {
            dispatch(taskTimelinessActions.setLoader({ value: true }));
            const response = await fetchTimelinessData(selectedProjectId.project_uid, selectedSnapshotDate, selectedComparedTo, true, true);
            dispatch(taskTimelinessActions.setTimelinessData({ timelinessData: response.data.delayArray }));
            dispatch(taskTimelinessActions.setDelayBlock({ delayBlock: response.data.delay_block }));
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data fetched !!!' } }));
        }
        catch (error) {
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const fetchProjects = async () => {
        try {
            dispatch(taskTimelinessActions.setLoader({ value: true }));
            const response = await fetchAllProjects();
            dispatch(taskTimelinessActions.setProjects({ projects: response.data.projects }));
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data fetched !!!' } }));
        }
        catch (error) {
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (timelinessRef.current) return;
        timelinessRef.current = true;
        if (selectedProjectId && selectedSnapshotDate && selectedComparedTo) {
            fetchTimeliness();
        }
        else {
            fetchProjects();
        }
    });

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={3}>
                <SelectProjects />
            </Stack>
            <Box sx={style}>
                <Stack direction="row" rowGap={3} columnGap={5}>
                    <SnapshotDates />
                    {delayBlock && <Paper sx={{ borderRadius: '10px', backgroundColor: theme.palette.lighter.main, color: theme.palette.lighter.contrastText }}>
                        <Stack direction="row">
                            <Box sx={{ backgroundColor: theme.palette.lighter.main, padding: 2, borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>
                                <Typography variant="body1" sx={{ fontWeight: 400 }}>{delayBlock?.delay}d delay</Typography>
                                <Typography variant="body1" sx={{ fontWeight: 365 }} >{moment(delayBlock?.from).format('DD-MMM')} to {moment(delayBlock?.to).format('DD-MMM')}</Typography>
                            </Box>
                            <Box sx={{ padding: 2, borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                                <Typography variant="body1" sx={{ fontWeight: 365 }}>weekends & holidays : {delayBlock?.non_working_days < 0 ? '-' : '+'}{Math.abs(delayBlock?.non_working_days)}</Typography>
                                <Typography variant="body1" sx={{ fontWeight: 365 }}>delayed tasks : {delayBlock?.delay_tasks < 0 ? '-' : '+'}{Math.abs(delayBlock?.delay_tasks)}</Typography>
                            </Box>
                        </Stack>
                    </Paper>
                    }
                </Stack>
            </Box>
            {!selectedSnapshotDate && !selectedComparedTo && <EmptyPage text="No Data" />}
            {selectedSnapshotDate && selectedComparedTo && <TimelinessTable />}
            <TimelinessLoader />
        </Box>
    );
}

export default TaskTimelinessData;