import React from "react";
import { Box, Typography, Stack, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { checkSnapshotActions } from "../../store";
import CheckSnapshotLoader from "./CheckSnapshotLoader";
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import WarningTable from "./WarningTable";
import ErrorTable from "./ErrorTable";
import { useNavigate, useParams } from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';
import { uploadSnapshot, notifyUsers, addLogs } from "../../services/projectSummaryServices";

const CheckSnapshot = () => {
    const navigate = useNavigate();
    const params = useParams();
    const theme = useTheme();
    const dispatch = useDispatch();
    const checkSnapshot = useSelector((state) => state.checkSnapshot.checkSnapshot);
    const selectedProject = useSelector((state) => state.checkSnapshot.selectedProject);
    const navigateTo = useSelector((state) => state.checkSnapshot.navigate);
    let manager = useSelector((state) => state.user.manager);
    if (!manager) {
        navigate('/error');
    }
    const style = {
        backgroundColor: theme.palette.lighter.main,
        color: theme.palette.lighter.contrastText,
        minheight: '70vh', width: '100%', borderRadius: '10px',
        padding: 3
    }

    const style2 = {
        backgroundColor: theme.palette.surface.main,
        color: theme.palette.surface.contrastText,
        minheight: '70vh', width: '100%', borderRadius: '10px',
        padding: 2, marginTop: 2
    }

    const onSaveSnapshotHandler = async () => {
        const data = {
            project_id: selectedProject.id,
            snapshot_date: params.snapshot_date,
            snapshot_url: `https://app.instagantt.com/shared/${params.snapshot_id}`,
            id: checkSnapshot.id,
            reference: params.reference === "true"
        }
        try {
            dispatch(checkSnapshotActions.setLoader({ value: true }));
            await uploadSnapshot(data);
            dispatch(checkSnapshotActions.setLoader({ value: false }));
            dispatch(checkSnapshotActions.setSnackbar({ value: { open: true, type: 'success', message: 'Snapshot Uploaded !!!' } }));
            navigate(navigateTo);
        }
        catch (error) {
            dispatch(checkSnapshotActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(checkSnapshotActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const notifyHandler = async () => {
        try {
            const data = {
                id: checkSnapshot.id,
                project_id: selectedProject.id
            };
            dispatch(checkSnapshotActions.setLoader({ value: true }));
            await notifyUsers(data);
            dispatch(checkSnapshotActions.setLoader({ value: false }));
            dispatch(checkSnapshotActions.setSnackbar({ value: { open: true, type: 'success', message: 'users notified !!!' } }));
        }
        catch (error) {
            dispatch(checkSnapshotActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(checkSnapshotActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const addLogHandler = async () => {
        try {
            const data = {
                id: checkSnapshot.id,
                project_id: selectedProject.id
            };
            dispatch(checkSnapshotActions.setLoader({ value: true }));
            await addLogs(data);
            dispatch(checkSnapshotActions.setLoader({ value: false }));
            dispatch(checkSnapshotActions.setSnackbar({ value: { open: true, type: 'success', message: 'Logs Updated !!!' } }));
        }
        catch (error) {
            dispatch(checkSnapshotActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(checkSnapshotActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Box sx={style}>
            <Stack direction="row" gap={2} justifyContent="flex-end">
                <Button variant="outlined" color="secondary" size="large" onClick={() => navigate(navigateTo)}>
                    <Stack direction="row" justifyContent="space-between" columnGap={1} alignItems="center">
                        <CloseIcon style={{ fontSize: 25, marginLeft: 0 }} />
                        <Typography variant="subtitle2">Cancel</Typography>
                    </Stack>
                </Button>
                <Button variant="contained" color="secondary" size="large" onClick={notifyHandler}>
                    <Stack direction="row" justifyContent="space-between" columnGap={1} alignItems="center">
                        <SendIcon style={{ fontSize: 25, marginLeft: 0 }} />
                        <Typography variant="subtitle2">Notify</Typography>
                    </Stack>
                </Button>
                {checkSnapshot.errors.hanging.length === 0 &&
                    <Button variant="contained" color="secondary" size="large" onClick={onSaveSnapshotHandler}>
                        <Stack direction="row" justifyContent="space-between" columnGap={1} alignItems="center">
                            <SaveIcon style={{ fontSize: 25, marginLeft: 0 }} />
                            <Typography variant="subtitle2">Save</Typography>
                        </Stack>
                    </Button>
                }
            </Stack>
            <Box sx={style2}>
                <Stack rowGap={3}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row" alignItems="center" columnGap={2}>
                            {checkSnapshot.errors.hanging.length === 0 ? <CheckCircleIcon sx={{ color: '#6AA84F' }} /> : <ErrorIcon sx={{ color: '#F44336' }} />}
                            <Typography variant="h6" sx={{ color: checkSnapshot.errors.hanging.length === 0 ? '#6AA84F' : '#F44336' }}>{checkSnapshot.errors.hanging.length === 0 ? "No errors found, you may save the file" : 'Errors'}</Typography>
                        </Stack>
                        <Button variant="outlined" color="secondary" size="medium" onClick={addLogHandler}>Log</Button>
                    </Stack>
                    <ErrorTable />

                    <Stack direction="row" justifyContent="space-between" alignItems="center" >
                        <Stack direction="row" alignItems="center" columnGap={2}>
                            <WarningIcon sx={{ color: '#00BCD4' }} />
                            <Typography variant="h6" sx={{ color: '#00BCD4' }}>
                                {
                                    checkSnapshot.warnings.estimate_actual_hours.length === 0 &&
                                        checkSnapshot.warnings.description.length === 0 &&
                                        checkSnapshot.warnings.assignee.length === 0 &&
                                        checkSnapshot.warnings.overdue.length === 0 ? 'No warnings found' : 'Warnings'
                                }</Typography>
                        </Stack>
                    </Stack>
                    <WarningTable />
                </Stack>
            </Box>
            <CheckSnapshotLoader />
        </Box>
    );
}

export default CheckSnapshot;