import { Box, Stack, Button, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import GithubLoader from './GithubLoader';
import EmptyPage from '../EmptyPage';
import SelectProjects from "./SelectProjects";
import { githubActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAllGithubProjects } from "../../services/githubServices";
import GitHubIcon from '@mui/icons-material/GitHub';
import SelectContributor from "./SelectContributor";
import IssuesData from "./IssuesData";

function Github() {
    const gitProjectsFetchedRef = useRef(false);
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.github.projects);
    const issuesData = useSelector((state) => state.github.issues);
    const contributors = useSelector((state) => state.github.contributors);
    const months = useSelector((state) => state.github.months);
    const navigate = useNavigate();

    const fetchGitProjects = async () => {
        try {
            dispatch(githubActions.setLoader({ value: true }));
            const response = await fetchAllGithubProjects();
            dispatch(githubActions.setProjects({ projects: response.data.projects }));
            dispatch(githubActions.setLoader({ value: false }));
            dispatch(githubActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data Fetched' } }));

        }
        catch (error) {
            dispatch(githubActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(githubActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const fetchUserRoles = (user) => {
        let roles = [];
        user?.PM && roles.push('Project Manager');
        user?.UX && roles.push('UI/UX Designer')
        user?.FD && roles.push('Frontend Developer');
        user?.BD && roles.push('Backend Developer');
        user?.QA && roles.push('Quality Analyst');

        return roles.join(', ');
    }

    useEffect(() => {
        if (gitProjectsFetchedRef.current) return;
        gitProjectsFetchedRef.current = true;
        fetchGitProjects();
        dispatch(githubActions.setSelectedProjectId({ id: null }));
        dispatch(githubActions.setContributors({ contributors: [] }));
        dispatch(githubActions.setSelectedContributor({ selectedContributor: null }));
        dispatch(githubActions.setMonth({ month: months[0].value }));
        dispatch(githubActions.setIssues({ issues: null }));
    }, [projects]);

    return (
        <Box>
            <Stack rowGap={3}>
                <Stack>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={3}>
                        <SelectProjects />
                        {issuesData && <Button variant="contained" size="medium" startIcon={<GitHubIcon />} onClick={() => window.open(issuesData?.github_url)}>
                            {issuesData?.github_url.split('https://github.com/LatticeInnovations')[1]}
                        </Button>
                        }
                        {
                            contributors.length > 0 && <SelectContributor />
                        }
                        {
                            issuesData && <Typography variant="body2" sx={{ width: '15%' }}>{fetchUserRoles(issuesData?.roles)}</Typography>
                        }
                    </Stack>
                </Stack>
                <Stack alignItems='center'>
                    {!issuesData && <EmptyPage text="Select a project to view details" />}
                    {issuesData && <IssuesData />}
                </Stack>
            </Stack>
            <GithubLoader />
        </Box >
    );
}

export default Github;