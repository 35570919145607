import axios from "axios";

let baseUrl = process.env.REACT_APP_DEV_BASE_URL;

const fetchFeedbacks = async (feedback_id) => {
    const url = feedback_id ? `feedback?feedbackID=${feedback_id}` : 'feedback';
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/${url}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }))
    ]);
    return response;
}

const fetchQuestions = async () => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/question`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }))
    ]);
    return response;
}

const postFeedback = async (data, feedback_id) => {
    let token = localStorage.getItem('token');
    let url = feedback_id ? `/feedback?feedbackID=${feedback_id}` : '/feedback';
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/${url}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: data
        }))
    ]);
    return response;
}

const deleteFeedback = async (feedback_id) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/feedback?feedbackID=${feedback_id}`, {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + token
            }
        }))
    ]);
    return response;
}

export {
    fetchFeedbacks,
    fetchQuestions,
    postFeedback,
    deleteFeedback,
};