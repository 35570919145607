import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Stack, Button, TextField, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { projectDetailsActions } from "../../../store/index";
import { editContributorRole, projectDetailsData } from "../../../services/collectionServices";
import { useNavigate, useParams } from "react-router-dom";

const EditRoleModal = () => {
    const [selectedContributor, setSelectedContributor] = useState('');
    const [pm, setPm] = useState(false);
    const [ux, setUx] = useState(0);
    const [fd, setFd] = useState(0);
    const [bd, setBd] = useState(0);
    const [qa, setQa] = useState(0);
    const dispatch = useDispatch();
    const editRoleModal = useSelector((state) => state.projectDetails.editRoleModal);
    const projectDetails = useSelector((state) => state.projectDetails.projectDetails);
    const theme = useTheme();
    let params = useParams();
    const navigate = useNavigate();
    const project_id = params.project_id;
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };

    useEffect(() => {
        setSelectedContributor('');
        setBd(0);
        setFd(0);
        setPm(false);
        setQa(0);
        setUx(0);
    }, [editRoleModal]);

    const contributorChangeHandler = (e) => {
        setSelectedContributor(e.target.value);
        let [contributor] = projectDetails.contributors.filter((user) => user.user_id === e.target.value);
        setBd(contributor.BD === 1);
        setFd(contributor.FD === 1);
        setPm(contributor.PM);
        setQa(contributor.QA === 1);
        setUx(contributor.UX === 1);
    }

    const onSaveRoles = async () => {
        try {
            let data = { BD: bd, FD: fd, QA: qa, UX: ux, project_id, user_id: selectedContributor }
            dispatch(projectDetailsActions.setLoader({ value: true }));
            await editContributorRole(data);
            const response = await projectDetailsData(project_id);
            dispatch(projectDetailsActions.setProjectDetails({ projectDetails: response.data }));
            dispatch(projectDetailsActions.setLoader({ value: false }));
            dispatch(projectDetailsActions.setEditRoleModalClose());
            dispatch(projectDetailsActions.setSnackbar({ value: { open: true, type: 'success', message: 'Roles updated !!!' } }));
        }
        catch (error) {
            dispatch(projectDetailsActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectDetailsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={editRoleModal}
            onClose={() => dispatch(projectDetailsActions.setEditRoleModalClose())}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Map Contributor to a role</Typography>
                    <TextField
                        sx={{ svg: { color: theme.palette.svg.main } }}
                        id="map-contributor"
                        label="Select Contributor"
                        variant="outlined"
                        select
                        defaultValue=''
                        SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
                        onChange={(e) => contributorChangeHandler(e)}
                    >
                        {
                            projectDetails?.contributors?.map((contributor) => {
                                return (
                                    <MenuItem
                                        sx={{ fontWeight: 300, padding: 1.5 }}
                                        key={contributor.user_id}
                                        value={contributor.user_id || ''}
                                    >
                                        {contributor.user_name}
                                    </MenuItem>
                                )
                            })
                        }
                    </TextField>
                    <Stack>
                        <Typography variant="subtitle" sx={{ color: theme.palette.active.main, fontWeight: 500 }}>Select role(s)</Typography>
                        <Typography variant="caption" color={theme.palette.highlight.main} sx={{ lineHeight: 1.35 }}>
                            One contributor can play multiple roles in a project.
                            <br></br>
                            A contributor does not have to perform all the jobs in a role
                        </Typography>
                    </Stack>
                    <Stack rowGap={1}>
                        <FormControlLabel disabled control={<Checkbox checked={Boolean(pm)} />}
                            label={
                                <Stack alignItems="start" >
                                    <Typography sx={{ fontWeight: 350, color: theme.palette.disabled.main }}>Project Manager (PM)</Typography>
                                    <Typography variant="caption" color={theme.palette.highlight.main}>Scope, Sitemap, Wireframe</Typography>
                                </Stack>
                            }
                        />
                        <FormControlLabel control={<Checkbox checked={Boolean(ux)} onChange={(e) => setUx(e.target.checked)} />}
                            label={
                                <Stack alignItems="start" >
                                    <Typography>UI/UX Designer (UX)</Typography>
                                    <Typography variant="caption" color={theme.palette.highlight.main}>Wireframe, UI mockup, Use cases</Typography>
                                </Stack>
                            }
                        />
                        <FormControlLabel control={<Checkbox checked={Boolean(fd)} onChange={(e) => setFd(e.target.checked)} />}
                            label={
                                <Stack alignItems="start" >
                                    <Typography>Frontend Developer (FD)</Typography>
                                    <Typography variant="caption" color={theme.palette.highlight.main}>Frontend theme, Endpoint mocks, Screen design, Binding</Typography>
                                </Stack>
                            }
                        />
                        <FormControlLabel control={<Checkbox checked={Boolean(bd)} onChange={(e) => setBd(e.target.checked)} />}
                            label={
                                <Stack alignItems="start" >
                                    <Typography>Backend Developer (BD)</Typography>
                                    <Typography variant="caption" color={theme.palette.highlight.main}>API contract, Schema, Queries, API payload</Typography>
                                </Stack>
                            }
                        />
                        <FormControlLabel control={<Checkbox checked={Boolean(qa)} onChange={(e) => setQa(e.target.checked)} />}
                            label={
                                <Stack alignItems="start" >
                                    <Typography>Quality Analyst (QA)</Typography>
                                    <Typography variant="caption" color={theme.palette.highlight.main}>Use cases, Test cases & data, Automated testing, Manual testing</Typography>
                                </Stack>
                            }
                        />

                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={() => dispatch(projectDetailsActions.setEditRoleModalClose())}>Cancel</Button>
                        <Button disabled={selectedContributor === ''} onClick={onSaveRoles}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default EditRoleModal;