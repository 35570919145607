import { Stack, Button, Typography, Card } from "@mui/material";

const DetailsCard = ({ data, label, color }) => {
    const cardStyle = {
        width: '25%', height: '300px', borderRadius: '20px', padding: 2, paddingTop: 5,
        backgroundColor: color
    }
    let countText = '';
    countText += data?.open === 0 ? 'Zero open, ' : data?.open + ' open, ';
    countText += data?.closed === 0 ? 'Zero closed' : data?.closed + ' closed';
    return (
        <Card sx={cardStyle}>
            <Stack rowGap={2}>
                <Typography variant="h3" fontWeight={800}>{data?.count}</Typography>
                <Typography variant="h6" fontWeight={800}>{label}</Typography>
                <Typography variant="h6" fontWeight={800}>{data?.count === 0 ? '--' : countText}</Typography>
                <Button variant="outlined" sx={{ width: '150px', marginLeft: 'auto', marginRight: 'auto' }} onClick={() => window.open(data?.details_url)} color="primary">Details</Button>
            </Stack>
        </Card>
    );
}

export default DetailsCard;