import { useTheme } from "@emotion/react";
import { TextField, MenuItem } from "@mui/material";
import { useSelector } from "react-redux/es/hooks/useSelector";
import React from "react";
import { useDispatch } from "react-redux";
import { fetchSnapshotDates } from '../../services/projectSummaryServices';
import { useNavigate } from "react-router-dom";
import { taskTimelinessActions } from "../../store";

const SelectProjects = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const selectedProjectId = useSelector((state) => state.taskTimeliness.selectedProjectId);
    const projects = useSelector((state) => state.taskTimeliness.projects);
    const navigate = useNavigate();

    const flattenedProjects = projects.reduce((acc, collection) => {
        acc.push({ label: 'collection_name', ...collection });
        collection.projects.forEach((project) => {
            acc.push({ label: 'project_name', ...project });
        });
        return acc;
    }, []);

    const getSnapshotDates = async (project_uid) => {
        try {
            dispatch(taskTimelinessActions.setLoader({ value: true }));
            const response = await fetchSnapshotDates(project_uid);
            dispatch(taskTimelinessActions.setProjectSnapshotDates({ snapshots: response.data.snapshotDates }));
            dispatch(taskTimelinessActions.resetCompareToSnapshots());
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data fetched !!!' } }));
        }
        catch (error) {
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <TextField
            sx={{ width: '50%', svg: { color: theme.palette.svg.main } }}
            id="select project"
            label="Select Project"
            variant="outlined"
            select
            value={selectedProjectId === null ? '' : JSON.stringify(selectedProjectId)}
            SelectProps={{ MenuProps: { sx: { maxHeight: 500 } } }}
            InputProps={{
                // disableUnderline: true,
                style: {
                    fontWeight: 350
                }
            }}
            onChange={(e) => {
                const value = JSON.parse(e.target.value);
                dispatch(taskTimelinessActions.setSelectedProjectId({ id: value }));
                dispatch(taskTimelinessActions.setSelectedSnapshotDate({ selectedSnapshotDate: '' }));
                dispatch(taskTimelinessActions.setSelectedComparedToDate({ selectedComparedTo: '' }));
                dispatch(taskTimelinessActions.setTimelinessData({ timelinessData: [] }));
                dispatch(taskTimelinessActions.setDelayBlock({ delayBlock: null }));
                getSnapshotDates(value.project_uid);
                navigate(`/task-timeliness/${value.project_uid}`);
            }}
        >
            {flattenedProjects.map((item, index) => (
                <MenuItem
                    sx={
                        item.label === 'collection_name' ? { fontWeight: 500, padding: 1.5 } : { fontWeight: 350, padding: 0.8, paddingLeft: '20px' }
                    }
                    key={item.label === 'collection_name' ? item.collection_id + item.collection_name : item.id + item.project_name + item.project_uid}
                    value={item.label === 'collection_name' ? item.collection_id : JSON.stringify({ id: item.id, project_uid: item.project_uid })}
                    disabled={item.label === 'collection_name'}
                >
                    {item.label === 'collection_name' ? item.collection_name : item.project_name}
                </MenuItem>
            ))}
        </TextField>
    );
}

export default SelectProjects;