import { useTheme } from "@emotion/react";
import { TextField, MenuItem } from "@mui/material";
import { useSelector } from "react-redux/es/hooks/useSelector";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { projectSummaryActions } from '../../store/index';
import { fetchSnapshotDates, fetchProjectSummaryData, fetchProjectNotes, progressEffortWise, fetchAllProjects } from '../../services/projectSummaryServices';
import { useNavigate, useParams } from "react-router-dom";

const SelectProjects = () => {
    const summary = useRef(false);
    const theme = useTheme();
    const dispatch = useDispatch();
    const selectedProjectId = useSelector((state) => state.projectSummary.selectedProjectId);
    const projects = useSelector((state) => state.projectSummary.projects);
    const navigate = useNavigate();
    const params = useParams();

    const flattenedProjects = (projects) => {
        return projects.reduce((acc, collection) => {
            acc.push({ label: 'collection_name', ...collection });
            collection.projects.forEach((project) => {
                acc.push({ label: 'project_name', ...project });
            });
            return acc;
        }, []);
    }

    const getSnapshotDates = async (project_uid) => {
        try {
            dispatch(projectSummaryActions.setLoader({ value: true }));
            const allProjects = await fetchAllProjects();
            dispatch(projectSummaryActions.setProjects({ projects: allProjects.data.projects }));
            const flatProjects = flattenedProjects(allProjects.data.projects);
            let value = "";
            flatProjects.forEach((item) => {
                if (item?.project_uid === project_uid) {
                    value = item.label === 'collection_name' ? item.collection_id : JSON.stringify({ id: item.id, project_uid: item.project_uid });
                }
            });
            dispatch(projectSummaryActions.setSelectedProjectId({ id: JSON.parse(value) }));
            const response = await fetchSnapshotDates(project_uid);
            dispatch(projectSummaryActions.setProjectSnapshotDates({ snapshots: response.data.snapshotDates }));
            const currentSnapshots = [...response.data.snapshotDates];
            const comparedToSnapshots = currentSnapshots.slice(1);
            let selectedSnapshotDate = currentSnapshots.length > 0 ? currentSnapshots[0]?.snapshot_date : '';
            let selectedComparedTo = comparedToSnapshots.length > 0 ? comparedToSnapshots[0]?.snapshot_date : '';
            dispatch(projectSummaryActions.setSelectedSnapshotDate({ selectedSnapshotDate: selectedSnapshotDate }));
            dispatch(projectSummaryActions.setSelectedComparedToDate({ selectedComparedTo: selectedComparedTo }));
            dispatch(projectSummaryActions.setComparedToSnapshotDates());
            const response2 = await fetchProjectSummaryData(project_uid, selectedSnapshotDate, selectedComparedTo);
            dispatch(projectSummaryActions.setProjectSummary({ projectSummary: response2.data }));
            const response3 = await fetchProjectNotes(project_uid);
            dispatch(projectSummaryActions.setProjectNotes({ notes: response3.data.notes }));
            const response4 = await progressEffortWise(project_uid, selectedSnapshotDate);
            dispatch(projectSummaryActions.setProgressEffort({ effort: response4.data.progress }));
            dispatch(projectSummaryActions.setLoader({ value: false }));
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data fetched !!!' } }));
        }
        catch (error) {
            dispatch(projectSummaryActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (summary.current) return;
        summary.current = true;
        if (params.project_uid) {
            getSnapshotDates(params.project_uid);
        }
    });

    return (
        <TextField
            sx={{ width: '50%', svg: { color: theme.palette.svg.main } }}
            id="select project"
            label="Select Project"
            variant="outlined"
            select
            value={selectedProjectId === null ? '' : JSON.stringify(selectedProjectId)}
            SelectProps={{
                MenuProps: { sx: { maxHeight: 400 } },
            }}
            onChange={(e) => {
                const value = JSON.parse(e.target.value);
                dispatch(projectSummaryActions.setSelectedProjectId({ id: value }));
                getSnapshotDates(value.project_uid);
                dispatch(projectSummaryActions.setEffortProgressOff());
                navigate(`/project-summary/${value.project_uid}`);
            }}
        >
            {flattenedProjects(projects).map((item, index) => (
                <MenuItem
                    sx={
                        item.label === 'collection_name' ? { fontWeight: 500, padding: 1.5 } : { padding: 0.8, paddingLeft: '20px' }
                    }
                    key={item.label === 'collection_name' ? item.collection_id + item.collection_name : item.id + item.project_name + item.project_uid}
                    value={item.label === 'collection_name' ? item.collection_id : JSON.stringify({ id: item.id, project_uid: item.project_uid })}
                    disabled={item.label === 'collection_name'}
                >
                    {item.label === 'collection_name' ? item.collection_name : item.project_name}
                </MenuItem>
            ))}
        </TextField>
    );
}

export default SelectProjects;