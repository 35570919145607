import { Alert, Snackbar, CircularProgress, Backdrop, Tab, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { leaveActions } from "../../store";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useTheme } from "@emotion/react";
import AllLeaves from "./AllLeaves";
import MyLeaves from "./MyLeaves";
import HolidayList from "./HolidayList";

function Leave() {
    const leaveRef = useRef();
    const theme = useTheme();
    const snackbar = useSelector((state) => state.leave.snackbar);
    const loader = useSelector((state) => state.leave.loader);
    let manager = useSelector((state) => state.user.manager);
    const dispatch = useDispatch();

    const [value, setValue] = useState(manager ? '1' : '2');

    const handleChange = (event, newValue) => {
        setValue(newValue.toString());
    };

    const handleSnackbarClose = () => {
        dispatch(leaveActions.setSnackbar({ value: { open: false, type: 'success', message: '' } }));
    }

    const style = { backgroundColor: theme.palette.surface1.main, width: '100%', borderRadius: '10px', marginTop: 1, padding: 2, color: theme.palette.surface1.contrastText }

    useEffect(() => {
        if (leaveRef.current) return;
        leaveRef.current = true;
        dispatch(leaveActions.setSelectedStatusFilter({ selectedStatusFilter: 'All' }));
    });

    return (
        <>
            <Box>
                <TabContext value={value}>
                    <Box sx={{ borderColor: theme.palette.disabled.main }}>
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            indicatorColor="secondary"
                            textColor="secondary"
                        // variant="fullWidth"
                        >
                            {manager &&
                                <Tab label="All Leaves" value={'1'} sx={{ typography: 'subtitle', color: theme.palette.disabled.main }}
                                />
                            }
                            <Tab label="My Leaves" value={'2'} sx={{ typography: 'subtitle', color: theme.palette.disabled.main }} />
                            <Tab label="Holiday List" value={'3'} sx={{ typography: 'subtitle', color: theme.palette.disabled.main }} />
                        </TabList>
                    </Box>
                    {
                        manager && <TabPanel value={'1'}>
                            <AllLeaves style={style} />
                        </TabPanel>
                    }
                    <TabPanel value={'2'}>
                        <MyLeaves style={style} />
                    </TabPanel>
                    <TabPanel value={'3'}>
                        <HolidayList style={style} />
                    </TabPanel>
                </TabContext >
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert severity={snackbar.type} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar >
            <Backdrop
                sx={{
                    zIndex: (theme) =>
                        Math.max.apply(Math, Object.values(theme.zIndex)) - 500,
                }}
                open={loader}
            >
                <CircularProgress color="secondary" />
            </Backdrop>
        </>
    )
}

export default Leave;