import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Stack, Button, TextField } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { collectionsActions, projectSummaryActions, checkSnapshotActions, projectDetailsActions } from "../../store";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { checkSnapshot } from "../../services/projectSummaryServices";

const AddSnapshotModal = () => {
    const addSnapshotModal = useSelector((state) => state.collections.addSnapshotModal);
    const [url, setUrl] = useState('');
    const [checkSnapshotButton, setCheckSnapshotButton] = useState(false);
    const [reference, setReference] = useState(false);
    const [date, setDate] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
    };

    useEffect(() => {
        setUrl('');
        setCheckSnapshotButton(false);
        setReference(false);
        setDate('');
    }, [addSnapshotModal]);

    const openLoader = () => {
        if (location.pathname.startsWith('/project-summary')) {
            dispatch(projectSummaryActions.setLoader({ value: true }));
        }
        else if (location.pathname.startsWith('/collections/details')) {
            dispatch(projectDetailsActions.setLoader({ value: true }));
        }
        else {
            dispatch(collectionsActions.setLoader({ value: true }));
        }
    }

    const closeLoader = () => {
        if (location.pathname.startsWith('/project-summary')) {
            dispatch(projectSummaryActions.setLoader({ value: false }));
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'success', message: 'data fetched' } }));
        }
        else if (location.pathname.startsWith('/collections/details')) {
            dispatch(projectDetailsActions.setLoader({ value: false }));
            dispatch(projectDetailsActions.setSnackbar({ value: { open: true, type: 'success', message: 'data fetched' } }));
        }
        else {
            dispatch(collectionsActions.setLoader({ value: false }));
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'success', message: 'data fetched' } }));
        }
    }

    const closeLoaderError = (error) => {
        if (location.pathname.startsWith('/project-summary')) {
            dispatch(projectSummaryActions.setLoader({ value: false }));
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }))
        }
        else if (location.pathname.startsWith('/collections/details')) {
            dispatch(projectDetailsActions.setLoader({ value: false }));
            dispatch(projectDetailsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
        }
        else {
            dispatch(collectionsActions.setLoader({ value: false }));
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
        }
    }

    const checkSnapshotHandler = async () => {
        const data = {
            snapshot_date: date,
            snapshot_url: url
        };
        try {
            openLoader();
            const response = await checkSnapshot(data);
            dispatch(checkSnapshotActions.setCheckSnapshot({ checkSnapshot: response.data }));
            closeLoader();
            dispatch(collectionsActions.setAddSnapshotModalClose());
            navigate(`/upload-snapshot/${url.split('/').reverse()[0]}/${date}/${reference}`);
        }
        catch (error) {
            closeLoaderError(error)
            dispatch(checkSnapshotActions.resetCheckSnapshot());
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={addSnapshotModal}
            onClose={() => dispatch(collectionsActions.setAddSnapshotModalClose())}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Add Project Snapshot</Typography>
                    <TextField
                        FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                        helperText={url !== '' && !url.startsWith('https://app.instagantt.com/shared/') ? 'URL must start with https://app.instagantt.com/shared/' : <Stack direction='row' justifyContent="space-between">
                            <Typography component={'span'} variant="caption">{'Snapshot URL, starting with https://app.instagantt.com/shared/...'}</Typography>
                            <Typography component={'span'} variant="caption">{`${url.length}/100`}</Typography>
                        </Stack>}
                        id="snapshot-url"
                        label="URL"
                        variant="outlined"
                        required
                        error={url !== '' && !url.startsWith('https://app.instagantt.com/shared/')}
                        value={url}
                        onChange={(e) => {
                            if (e.target.value.length <= 100) {
                                setUrl(e.target.value);
                                setCheckSnapshotButton((e.target.value.length >= 3) && (e.target.value.startsWith('https://app.instagantt.com/shared/')) && date !== '');
                            }
                            else {
                                return null
                            }
                        }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{
                                svg: { color: theme.palette.svg.main },
                                backgroundColor: theme.palette.datePicker.main
                            }}
                            label="Select Date"
                            format="YYYY/MM/DD"
                            slotProps={{
                                field: {
                                    readOnly: true
                                }
                            }}
                            disableFuture={true}
                            onChange={(value) => {
                                setDate(moment(new Date(value.$d)).format("YYYY-MM-DD"));
                                setCheckSnapshotButton((url.length >= 3) && (url.startsWith('https://app.instagantt.com/shared/')) && moment(new Date(value.$d)).format("YYYY/MM/DD") !== '');
                            }}
                        />
                    </LocalizationProvider>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="subtitle" sx={{ width: '50%' }}>Is this a reference snapshot?</Typography>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue={false}
                            onChange={(e) => setReference(e.target.value === "true")}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={() => dispatch(collectionsActions.setAddSnapshotModalClose())}>Cancel</Button>
                        <Button disabled={!checkSnapshotButton} onClick={checkSnapshotHandler}>Check Snapshot</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );

}


export default AddSnapshotModal;