import { useTheme } from "@emotion/react";
import { Box, Stack, Typography, Button, Switch, } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Progress from "./Progress";
import moment from "moment";
import { projectSummaryActions } from "../../store";

const ProjectDetailData = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const projectSummary = useSelector((state) => state.projectSummary.projectSummary);
    const effortProgress = useSelector((state) => state.projectSummary.effortProgress);
    const effortProgressOn = useSelector((state) => state.projectSummary.effortProgressOn);

    const style = {
        backgroundColor: theme.palette.surface2.main, minheight: '300px', width: '100%', borderRadius: '10px',
        padding: 3, marginTop: 5, color: theme.palette.surface2.contrastText
    }

    const cardStyle = {
        backgroundColor: theme.palette.lighter.main,
        width: '25%', borderRadius: '10px', height: '250px', padding: 1.5, color: theme.palette.lighter.contrastText
    }

    const progressChangeHandler = async (e) => {
        e.target.checked ? dispatch(projectSummaryActions.setEffortProgressOn()) : dispatch(projectSummaryActions.setEffortProgressOff());
    }

    return (
        <Box sx={style}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Project Details</Typography>
                <Button sx={{ display: 'flex', columnGap: 1 }} onClick={() => {
                    window.open(projectSummary?.url, '_blank', 'noopener, noreferrer')
                }}>
                    <OpenInNewIcon />
                    <Typography variant="caption2">view Gantt</Typography>
                </Button>
            </Stack>
            <Box sx={{ borderRadius: '10px', marginTop: 3, width: '100%' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" columnGap={2}>
                    <Box sx={cardStyle}>
                        <Stack alignItems="center" rowGap={2}>
                            <Typography variant="h6">Progress</Typography>
                            <Stack direction="row" sx={{ textAlign: "center", width: '80%' }} alignItems="center">
                                <Typography sx={{ fontWeight: 300 }}>Time-based progress</Typography>
                                <Switch checked={effortProgressOn} onChange={progressChangeHandler} />
                                <Typography sx={{ fontWeight: 300 }}>Effort-based progress</Typography>
                            </Stack>
                            <Progress value={effortProgressOn ? effortProgress : projectSummary?.progress || 0} scolor={"#4CB9E7"} />
                        </Stack>
                    </Box>
                    <Box sx={cardStyle}>
                        <Stack alignItems="center" rowGap={10}>
                            <Typography variant="h6">Time Elapsed</Typography>
                            <Progress value={projectSummary?.timeElapsed < 100 ? projectSummary?.timeElapsed : 100} scolor={"#EE7214"} />
                        </Stack>
                    </Box>
                    <Box sx={cardStyle}>
                        <Stack alignItems="center" rowGap={10}>
                            <Typography variant="h6">Buffer Used</Typography>
                            <Progress value={projectSummary?.bufferUsed} scolor={"#4CB9E7"} />
                        </Stack>
                    </Box>
                    <Box sx={cardStyle}>
                        <Stack alignItems="center" rowGap={2}>
                            <Typography variant="h6">Expected End Date</Typography>
                            <RocketLaunchIcon style={{ fontSize: 50 }} />
                            <Typography variant="h5">{projectSummary?.expected_end?.days_left} days</Typography>
                            <Typography variant="body2">
                                {moment(projectSummary?.expected_end?.expected_end_date).format('dddd, DD-MMM-YYYY')}
                            </Typography>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
}

export default ProjectDetailData;