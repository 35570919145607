import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: false,
    snackbar: { open: false, type: 'success', message: '' },
    statusFilters: [{ name: 'All', value: 'All' }, { name: 'Pending', value: 'pending' }, { name: 'Approved', value: 'approved' }, { name: 'Declined', value: 'declined' }, { name: 'Cancelled or Withdrawn', value: 'withdrawn' }],
    selectedStatusFilter: 'All',
    selectedRadioFilter: 'Pending',
    appliedBy: [],
    selectedAppliedBy: null,
    othersLeave: [],
    filteredOthersLeave: [],
    myLeave: [],
    filteredMyLeave: [],
    pages: [5, 10, 50, 100],
    page: 0,
    rowsPerPage: 10,
    postLeaveModal: false,
    postUnpaidModal: false,
    deleteUnpaidModal: false,
    unpaidToDelete: null,
    leaveActionModal: false,
    selectedLeaveData: null,
    leaveBalance: {
        EL_balance_without_threshold: 0,
        SL_balance_without_threshold: 0,
        CO_balance: 0,
        unpaid_days: 0
    },
    compOffModal: false,
    applyLeaveModal: false,
    managers: [],
    editLeaveModal: false,
    dataInEdit: null,
    holidays: [],
}

const leaveSlice = createSlice({
    name: 'leave',
    initialState: initialState,
    reducers: {
        setLoader(state, action) {
            state.loader = action.payload.value;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload.value;
        },
        setSelectedStatusFilter(state, action) {
            state.selectedStatusFilter = action.payload.selectedStatusFilter;
        },
        setRadioFilter(state, action) {
            state.selectedRadioFilter = action.payload.selectedRadioFilter;
        },
        setAppliedBy(state, action) {
            state.appliedBy = action.payload.appliedBy;
        },
        setSelectedAppliedBy(state, action) {
            state.selectedAppliedBy = action.payload.selectedAppliedBy;
        },
        setOthersLeave(state, action) {
            state.othersLeave = action.payload.othersLeave.sort((a, b) => new Date(b.applied_on) - new Date(a.applied_on));
        },
        setFilteredOthersLeave(state, action) {
            state.filteredOthersLeave = action.payload.filteredOthersLeave;
        },
        setMyLeave(state, action) {
            state.myLeave = action.payload.myLeave.sort((a, b) => new Date(b.applied_on) - new Date(a.applied_on));
        },
        setFilteredMyLeave(state, action) {
            state.filteredMyLeave = action.payload.filteredMyLeave;
        },
        setPage(state, action) {
            state.page = action.payload.value;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload.value;
        },
        resetFilters(state, action) {
            state.page = 0;
            state.rowsPerPage = state.pages[0];
            state.filtersSelected = false;
            state.selectedStatusFilter = 'All';
            state.selectedAppliedBy = null;
            state.selectedRadioFilter = 'Pending';
        },
        setPostLeaveModalOpen(state, action) {
            state.postLeaveModal = true;
        },
        setPostLeaveModalClose(state, action) {
            state.postLeaveModal = false;
        },
        setunpaidModalOpen(state, action) {
            state.postUnpaidModal = true;
        },
        setunpaidModalClose(state, action) {
            state.postUnpaidModal = false;
        },
        setdeleteUnpaidModalOpen(state, action) {
            state.deleteUnpaidModal = true;
        },
        setdeleteUnpaidModalClose(state, action) {
            state.deleteUnpaidModal = false;
            state.unpaidToDelete = null;
        },
        setUnpaidToDelete(state, action) {
            state.unpaidToDelete = action.payload.unpaidToDelete;
        },
        setleaveActionModalOpen(state, action) {
            state.leaveActionModal = true;
        },
        setleaveActionModalClose(state, action) {
            state.leaveActionModal = false;
            state.selectedLeaveData = null;
        },
        setSelectedLeaveData(state, action) {
            state.selectedLeaveData = action.payload.selectedLeaveData;
        },
        setLeaveBalance(state, action) {
            state.leaveBalance = action.payload.leaveBalance;
        },
        setCompOffModalOpen(state, action) {
            state.compOffModal = true;
        },
        setCompOffModalClose(state, action) {
            state.compOffModal = false;
        },
        setApplyLeaveModalOpen(state, action) {
            state.applyLeaveModal = true;
        },
        setApplyLeaveModalClose(state, action) {
            state.applyLeaveModal = false;
        },
        setManagers(state, action) {
            state.managers = action.payload.managers;
        },
        setEditLeaveModalOpen(state, action) {
            state.editLeaveModal = true;
        },
        setEditLeaveModalClose(state, action) {
            state.editLeaveModal = false;
        },
        setdataInEdit(state, action) {
            state.dataInEdit = action.payload.dataInEdit;
        },
        setHolidays(state, action) {
            state.holidays = action.payload.holidays;
        }
    }
});

export default leaveSlice;