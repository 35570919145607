import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
    loader: false,
    snackbar: { open: false, type: 'success', message: '' },
    gitUsername: '',
    figmaToken: '',
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    avatar: '',
    fullname: '',
    userType: '',
    manager: false,
    employee: false,
    teamLead: false,
    access_token: null,
    emails: [],
    phones: [],
    recoveryPhone: '',
};

const loggedInUserSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setLoader(state, action) {
            state.loader = action.payload.value;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload.value;
        },
        login(state, action) {
            state.userFirstName = action.payload.userFirstName;
            state.userLastName = action.payload.userLastName;
            state.userEmail = action.payload.userEmail;
            state.avatar = action.payload.avatar;
            state.fullname = action.payload.fullname;
            // state.userType = action.payload.userEmail === 'himanshurawat@thelattice.in' ? 'Manager' : action.payload.userType;
            // state.manager = action.payload.userEmail === 'himanshurawat@thelattice.in' ? true : action.payload.manager;
            // state.employee = action.payload.userEmail === 'himanshurawat@thelattice.in' ? false : action.payload.employee;
            state.userType = action.payload.userType;
            state.manager = action.payload.manager;
            state.employee = action.payload.employee;
            state.teamLead = action.payload.teamLead;
            state.access_token = action.payload.access_token;
            state.emails = action.payload.emails;
            state.phones = action.payload.phones;
            state.recoveryPhone = action.payload.recoveryPhone;
        },
        logout(state, action) {
            console.log("working", state);
            state.userFirstName = '';
            state.userLastName = '';
            state.userEmail = '';
            state.avatar = '';
            state.fullname = '';
            state.userType = '';
            state.manager = false;
            state.employee = false;
            state.teamLead = false;
            state.access_token = null;
            state.emails = [];
            state.phones = [];
            state.recoveryPhone = '';
            state.gitUsername = '';
            localStorage.removeItem('token');
        },
        setGitUsername(state, action) {
            state.gitUsername = action.payload.gitUsername;
        },
        setFigmaToken(state, action) {
            state.figmaToken = action.payload.figmaToken;
        }
    }
});

export default loggedInUserSlice;