import axios from "axios";
let baseUrl = process.env.REACT_APP_DEV_BASE_URL;

const fetchDefaultAllocations = async () => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/allocation`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }))
    ]);
    return response;
}

const fetchAllocationsData = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/allocation`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: data
        }))
    ]);
    return response;
}

const fetchAllocationCollections = async (startDate, duration) => {
    let token = localStorage.getItem('token');
    let query = '';
    query = startDate ? query + 'startDate=' + startDate : query + '';
    query = duration ? query + '&duration=' + duration : query + '';
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/allocation-collections?${query}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        }))
    ]);
    return response;
}

const fetchAllocationProjects = async (startDate, duration, collection_id) => {
    let token = localStorage.getItem('token');
    let query = '';
    query = startDate ? query + 'startDate=' + startDate : query + '';
    query = duration ? query + '&duration=' + duration : query + '';
    query = collection_id ? query + '&collection_id=' + collection_id : query + '';
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/allocation-projects?${query}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        }))
    ]);
    return response;
}

const createDefaultWorksheet = async (spreadsheet_url) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/createWorksheet?spreadsheet_url=${spreadsheet_url}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        }))
    ]);
    return response;
}

const createFilteredWorksheet = async (spreadsheet_url, data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/createWorksheet?spreadsheet_url=${spreadsheet_url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: data
        }))
    ]);
    return response;
}

export {
    fetchDefaultAllocations,
    fetchAllocationsData,
    fetchAllocationProjects,
    fetchAllocationCollections,
    createDefaultWorksheet,
    createFilteredWorksheet
};