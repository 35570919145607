import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { NavLink } from "react-router-dom";
import { List, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import TimerIcon from '@mui/icons-material/Timer';
import PersonIcon from '@mui/icons-material/Person';
import GitHubIcon from '@mui/icons-material/GitHub';
import WorkIcon from '@mui/icons-material/Work';
import img from '../../Assets/feeta-wordmark-2 1.png';
import Tooltip from '@mui/material/Tooltip';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { useDispatch, useSelector } from 'react-redux';
import { drawerActions } from '../../store';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import EventBusyIcon from '@mui/icons-material/EventBusy';

const drawerWidth = 300;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: theme.spacing(0, 1),
    height: '100px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const AppDrawer = () => {
    const theme = useTheme();
    const open = useSelector((state) => state.drawer.open);
    const dispatch = useDispatch();
    const activeTab = { color: theme.palette.active.contrastText, backgroundColor: theme.palette.active.main };
    const inactiveTab = { color: theme.palette.primary.contrastText };
    let manager = useSelector((state) => state.user.manager);

    const handleDrawerClose = () => {
        dispatch(drawerActions.closeDrawer());
    };

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader sx={{ bgcolor: theme.palette.primary.main }}>
                <img src={img} alt='Feeta' loading='lazy' style={{ height: '30px' }} />
                <IconButton onClick={handleDrawerClose}>
                    <MenuIcon style={{ color: theme.palette.primary.contrastText }} />
                </IconButton>
            </DrawerHeader>
            <Divider sx={{ backgroundColor: "#FFFFFF", height: 2 }} variant="middle" />
            <List sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>

                {/* {manager && <ListItem key={'Admin'} disablePadding sx={{ display: 'block' }} component={NavLink} style={({ isActive }) => isActive ? activeTab : inactiveTab} to='/admin'>
                    <ListItemButton
                        sx={{
                            minHeight: 70,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <Tooltip title={!open && 'Admin'} placement='right' arrow>
                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                <AdminPanelSettingsIcon style={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={'Admin'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>} */}


                <ListItem key={'Collections'} disablePadding sx={{ display: 'block' }} component={NavLink} style={({ isActive }) => isActive ? activeTab : inactiveTab} to='/collections'>
                    <ListItemButton
                        sx={{
                            minHeight: 70,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <Tooltip title={!open && 'Collections'} placement='right' arrow>
                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                <FormatListBulletedIcon style={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={'Collections'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>


                <ListItem key={'Project Summary'} disablePadding sx={{ display: 'block' }} component={NavLink} style={({ isActive }) => isActive ? activeTab : inactiveTab} to='/project-summary'>
                    <ListItemButton
                        sx={{
                            minHeight: 70,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <Tooltip title={!open && 'Project Summary'} placement='right' arrow>
                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                <WorkIcon style={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={'Project Summary'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>


                <ListItem key={'Task Timeliness'} disablePadding sx={{ display: 'block' }} component={NavLink} style={({ isActive }) => isActive ? activeTab : inactiveTab} to='/task-timeliness'>
                    <ListItemButton
                        sx={{
                            minHeight: 70,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <Tooltip title={!open && 'Task Timeliness'} placement='right' arrow>
                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                <TimerIcon style={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={'Task Timeliness'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>


                <ListItem key={'Contributor Details'} disablePadding sx={{ display: 'block' }} component={NavLink} style={({ isActive }) => isActive ? activeTab : inactiveTab} to='/contributor-details'>
                    <ListItemButton
                        sx={{
                            minHeight: 70,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <Tooltip title={!open && 'Contributor Details'} placement='right' arrow>
                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                <PersonIcon style={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={'Contributor Details'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>


                <ListItem key={'Github'} disablePadding sx={{ display: 'block' }} component={NavLink} style={({ isActive }) => isActive ? activeTab : inactiveTab} to='/github'>
                    <ListItemButton
                        sx={{
                            minHeight: 70,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <Tooltip title={!open && 'Github'} placement='right' arrow>
                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                <GitHubIcon style={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={'Github'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Allocation'} disablePadding sx={{ display: 'block' }} component={NavLink} style={({ isActive }) => isActive ? activeTab : inactiveTab} to='/allocation'>
                    <ListItemButton
                        sx={{
                            minHeight: 70,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <Tooltip title={!open && 'Allocation'} placement='right' arrow>
                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                <AccountTreeIcon style={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={'Allocation'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Leave'} disablePadding sx={{ display: 'block' }} component={NavLink} style={({ isActive }) => isActive ? activeTab : inactiveTab} to='/leave'>
                    <ListItemButton
                        sx={{
                            minHeight: 70,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <Tooltip title={!open && 'Leave'} placement='right' arrow>
                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                <EventBusyIcon style={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={'Leave'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'Self-directedness'} disablePadding sx={{ display: 'block' }} component={NavLink} style={({ isActive }) => isActive ? activeTab : inactiveTab} to='/Self-directedness'>
                    <ListItemButton
                        sx={{
                            minHeight: 70,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <Tooltip title={!open && 'Self-directedness'} placement='right' arrow>
                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                <SelfImprovementIcon style={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={'Self-directedness'} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Drawer>
    );
}

export default AppDrawer;