import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: false,
    snackbar: { open: false, type: 'success', message: '' },
    projects: [],
    selectedProjectId: null,
    projectSnapshotDates: [],
    comparedToSnapshots: [],
    selectedSnapshotDate: '',
    selectedComparedTo: '',
    timelinessData: [],
    filteredTimelinessData: [],
    delayBlock: null,
    filter1: true,
    filter2: true,
    pages: [5, 10, 20],
    page: 0,
    rowsPerPage: 10,
    correctedDelayModal: false,
    selectedTask: {
        taskId: null,
        delay: '',
        comment: '',
        upvote: null,
    }
}

const taskTimelinessSlice = createSlice({
    name: 'taskTimeliness',
    initialState: initialState,
    reducers: {
        setLoader(state, action) {
            state.loader = action.payload.value;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload.value;
        },
        setSelectedProjectId(state, action) {
            state.selectedProjectId = action.payload.id;
        },
        setProjects(state, action) {
            state.projects = action.payload.projects;
        },
        setProjectSnapshotDates(state, action) {
            state.projectSnapshotDates = action.payload.snapshots
        },
        setSelectedSnapshotDate(state, action) {
            state.selectedSnapshotDate = action.payload.selectedSnapshotDate;
            let index = 0;
            state.projectSnapshotDates.forEach((obj, i) => {
                if (obj.snapshot_date === state.selectedSnapshotDate) {
                    index = i;
                }
            });
            state.comparedToSnapshots = state.projectSnapshotDates.slice(index + 1);
            let compareToExists = false;
            state.comparedToSnapshots.forEach((date) => {
                if (date.snapshot_date === state.selectedComparedTo) {
                    compareToExists = true;
                }
            });
            if (!compareToExists) {
                state.selectedComparedTo = '';
            }
        },
        resetCompareToSnapshots(state, action) {
            state.comparedToSnapshots = [];
        },
        setSelectedComparedToDate(state, action) {
            state.selectedComparedTo = action.payload.selectedComparedTo;
        },
        setTimelinessData(state, action) {
            state.timelinessData = action.payload.timelinessData;
            state.filteredTimelinessData = action.payload.timelinessData;
        },
        setDelayBlock(state, action) {
            state.delayBlock = action.payload.delayBlock;
        },
        setFilteredTimelinessData(state, action) {
            state.filteredTimelinessData = action.payload.filteredTimelinessData
        },
        setPage(state, action) {
            state.page = action.payload.value;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload.value;
        },
        setFilter1(state, action) {
            state.filter1 = action.payload.value;
        },
        setFilter2(state, action) {
            state.filter2 = action.payload.value;
        },
        resetFilters(state, action) {
            state.page = 0;
            state.rowsPerPage = state.pages[1];
            state.filter1 = true;
            state.filter2 = true;
        },
        setCorrectedDelayModalOpen(state, action) {
            state.correctedDelayModal = true;
        },
        setCorrectedDelayModalClose(state, action) {
            state.correctedDelayModal = false;
        },
        setSelectedTask(state, action) {
            state.selectedTask = action.payload.selectedTask;
        }
    }
});

export default taskTimelinessSlice;