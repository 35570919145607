import { useTheme } from "@emotion/react";
import { TextField, MenuItem } from "@mui/material";
import { useSelector } from "react-redux/es/hooks/useSelector";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { githubActions } from "../../store";
import { fetchIssuesData } from '../../services/githubServices';

const SelectContributor = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const selectedProjectId = useSelector((state) => state.github.selectedProjectId);
    const contributors = useSelector((state) => state.github.contributors);
    const selectedContributor = useSelector((state) => state.github.selectedContributor);
    const months = useSelector((state) => state.github.months);
    const navigate = useNavigate();

    const contributorChangeHandler = async (user_id) => {
        try {
            dispatch(githubActions.setLoader({ value: true }));
            const issuesResponse = await fetchIssuesData(user_id, selectedProjectId.project_uid, months[0].value);
            dispatch(githubActions.setIssues({ issues: issuesResponse?.data?.data }));
            dispatch(githubActions.setLoader({ value: false }));
            dispatch(githubActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data Fetched' } }));
        }
        catch (error) {
            dispatch(githubActions.setIssues({ issues: null }));
            dispatch(githubActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(githubActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <TextField
            sx={{ width: '20%', svg: { color: theme.palette.svg.main } }}
            id="contributor"
            label="Select Contributor"
            variant="outlined"
            select
            value={selectedContributor}
            SelectProps={{ MenuProps: { sx: { maxHeight: 500 } } }}
            InputProps={{ style: { fontWeight: 350 } }}
            onChange={(e) => {
                dispatch(githubActions.setMonth({ month: months[0].value }));
                dispatch(githubActions.setSelectedContributor({ selectedContributor: e.target.value }));
                contributorChangeHandler(e.target.value);
            }}
        >
            {contributors.map((contributor, index) => (
                <MenuItem
                    sx={{ fontWeight: 350, padding: 1, paddingLeft: '20px' }}
                    key={contributor.user_id + contributor.user_email}
                    value={contributor.user_id}
                >
                    {contributor.user_name}
                </MenuItem>
            ))}
        </TextField>
    );
}

export default SelectContributor;