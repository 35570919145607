import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
    loader: false,
    snackbar: { open: false, type: 'success', message: '' },
    feedback: [],
    filteredFeedback: [],
    contributors: [],
    questions: [],
    selectedContributor: '',
    response1: null,
    response2: null,
    response3: null,
    fromDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    pages: [10, 50, 100],
    page: 0,
    rowsPerPage: 10,
    feedbackModal: false,
    feedbackToView: '',
}

const selfDirectednessSlice = createSlice({
    name: 'selfDirectedness',
    initialState: initialState,
    reducers: {
        setLoader(state, action) {
            state.loader = action.payload.value;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload.value;
        },
        setFeedback(state, action) {
            state.feedback = action.payload.feedback;
            state.filteredFeedback = action.payload.feedback;
        },
        setFilteredFeedback(state, action){
            state.filteredFeedback = action.payload.filteredFeedback;
        },
        setContributors(state, action) {
            state.contributors = action.payload.contributors;
        },
        setQuestions(state, action) {
            state.questions = action.payload.questions;
            if (action.payload.questions.length === 0) {
                state.response1 = null;
                state.response2 = null;
                state.response3 = null;
            }
            else {
                action.payload.questions.forEach((question) => {
                    const option = question.options.filter((option) => option.option_number === 1);
                    switch (question.ques_id) {
                        case 1: state.response1 = {
                            question_id: question.ques_id,
                            option_id: option[0].option_id
                        }
                            break;
                        case 2: state.response2 = {
                            question_id: question.ques_id,
                            option_id: option[0].option_id
                        }
                            break;
                        case 3: state.response3 = {
                            question_id: question.ques_id,
                            option_id: option[0].option_id
                        }
                            break;
                    }
                });
            }
        },
        setSelectedContributor(state, action) {
            state.selectedContributor = action.payload.selectedContributor;
        },
        setResponse1(state, action) {
            state.response1 = action.payload.response1;
        },
        setResponse2(state, action) {
            state.response2 = action.payload.response2;
        },
        setResponse3(state, action) {
            state.response3 = action.payload.response3;
        },
        setFromDate(state, action) {
            state.fromDate = action.payload.fromDate;
        },
        setToDate(state, action) {
            state.toDate = action.payload.toDate;
        },
        resetDates(state, action) {
            state.toDate = moment().format('YYYY-MM-DD');
            state.fromDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
        },
        setPage(state, action) {
            state.page = action.payload.value;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload.value;
        },
        setFeedbackModalOpen(state, action) {
            state.feedbackModal = true;
        },
        setFeedbackModalClose(state, action) {
            state.feedbackModal = false;
            state.feedbackToView = '';
        },
        setFeedbackToView(state, action) {
            state.feedbackToView = action.payload.feedbackToView;
        }
    }
});

export default selfDirectednessSlice;