import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    collections: [],
    contributors: {
        collection_name: '',
        contributors: []
    },
    anchorCollectionName: false,
    menuAnchor: null,
    anchorProjectName: false,
    collectionModal: false,
    addProjectModal: false,
    addCollectionModal: false,
    viewContributorsModal: false,
    renameCollectionModal: false,
    removeCollectionModal: false,
    changeCollectionModal: false,
    editProjectModal: false,
    addSnapshotModal: false,
    removeFromCollectionModal: false,
    collectionMenuSelectedCollectionId: null,
    projectsMenuSelectedProjectId: null,
    collectionMenuSelectedCollectionName: '',
    snapshotExist: false,
    loader: false,
    snackbar: { open: false, type: 'success', message: '' },
}

const collectionSlice = createSlice({
    name: 'collections',
    initialState: initialState,
    reducers: {
        setLoader(state, action) {
            state.loader = action.payload.value;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload.value;
        },
        setCollections(state, action) {
            let uncollected = action.payload.collections.filter((collection) => collection.collection_id === 1);
            let collections = action.payload.collections.filter((collection) => collection.collection_id !== 1);
            state.collections = [...collections, ...uncollected];
        },
        setContributors(state, action) {
            state.contributors = action.payload.contributors;
        },
        handleCloseCollectionNameMenu(state, action) {
            state.anchorCollectionName = false;
            state.menuAnchor = null;
        },
        handleOpenCollectionNameMenu(state, action) {
            state.menuAnchor = action.payload.currentTarget;
            state.anchorCollectionName = true;
        },
        handleCloseProjectNameMenu(state, action) {
            state.anchorProjectName = false;
            state.menuAnchor = null;
        },
        handleOpenProjectNameMenu(state, action) {
            state.menuAnchor = action.payload.currentTarget;
            state.anchorProjectName = true;
        },
        setCollectionModalOpen(state, action) {
            state.collectionModal = action.payload.id;
        },
        setCollectionModalClose(state, action) {
            state.collectionModal = false;
        },
        setAddProjectModalOpen(state, action) {
            state.addProjectModal = true;
        },
        setAddProjectModalClose(state, action) {
            state.addProjectModal = false;
        },
        setAddCollectionModalOpen(state, action) {
            state.addCollectionModal = true;
        },
        setAddCollectionModalClose(state, action) {
            state.addCollectionModal = false;
        },
        setViewContributorsOpen(state, action) {
            state.viewContributorsModal = true;
        },
        setViewContributorsClose(state, action) {
            state.viewContributorsModal = false;
        },
        setSelectedCollectionIDinCollectionMenu(state, action) {
            state.collectionMenuSelectedCollectionId = action.payload.collection_id;
        },
        setSelectedCollectionNameinCollectionMenu(state, action) {
            state.collectionMenuSelectedCollectionName = action.payload.collection_name;
        },
        setRenameCollectionModalOpen(state, action) {
            state.renameCollectionModal = true;
        },
        setRenameCollectionModalClose(state, action) {
            state.renameCollectionModal = false;
        },
        setRemoveCollectionModalOpen(state, action) {
            state.removeCollectionModal = true;
        },
        setRemoveCollectionModalClose(state, action) {
            state.removeCollectionModal = false;
        },
        setChangeCollectionModalOpen(state, action) {
            state.changeCollectionModal = true;
        },
        setChangeCollectionModalClose(state, action) {
            state.changeCollectionModal = false;
        },
        setEditProjectModalOpen(state, action) {
            state.editProjectModal = true;
        },
        setEditProjectModalClose(state, action) {
            state.editProjectModal = false;
        },
        setAddSnapshotModalOpen(state, action) {
            state.addSnapshotModal = true;
        },
        setAddSnapshotModalClose(state, action) {
            state.addSnapshotModal = false;
        },
        setRemoveFromCollectionModalOpen(state, action) {
            state.removeFromCollectionModal = true;
        },
        setRemoveFromCollectionModalClose(state, action) {
            state.removeFromCollectionModal = false;
        },
        setSelectedProjectIDinProjectMenu(state, action) {
            state.projectsMenuSelectedProjectId = action.payload.project_id;
        },
        setSnapshotExist(state, action){
            state.snapshotExist = action.payload.snapshotExist;
        }
    }
});

export default collectionSlice;