import { useTheme } from "@emotion/react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Chip, Typography, TableSortLabel, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { leaveActions } from "../../store";
import moment from "moment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import { useState } from "react";
import { paginationResult } from '../../Utils/TableColumnSort';
import EditIcon from '@mui/icons-material/Edit';
let baseUrl = process.env.REACT_APP_DEV_BASE_URL;

const MyLeavesTable = () => {
    const theme = useTheme();
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();
    const dispatch = useDispatch();

    const pages = useSelector((state) => state.leave.pages);
    const page = useSelector((state) => state.leave.page);
    const rowsPerPage = useSelector((state) => state.leave.rowsPerPage);
    const filteredMyLeave = useSelector((state) => state.leave.filteredMyLeave);

    const columns = ['Applied on', 'Deducted from / added To', 'Type', 'Start', 'End', 'Working days', 'Notes & Links', 'Approver', 'status', ''];
    const fields = ['applied_on', 'type', 'leave_type', 'from', 'to', 'working_days', 'notes', 'approver', 'status', ''];

    const handleChangePage = (event, newPage) => {
        dispatch(leaveActions.setPage({ value: newPage }));
    }

    const handleChangeRowsPerPage = (event) => {
        dispatch(leaveActions.setRowsPerPage({ value: parseInt(event.target.value) }));
        dispatch(leaveActions.setPage({ value: 0 }));
    }

    const handleSortRequest = (cellId) => {
        if (!order) {
            setOrder("asc")
            setOrderBy(cellId);
        }
        else if (order === "asc") {
            setOrder("desc");
            setOrderBy(cellId);
        }
        else {
            setOrder();
            setOrderBy();
        }
    }


    return (
        <>
            <TableContainer sx={{
                marginTop: 2,
                backgroundColor: theme.palette.lighter.main,
                borderRadius: '10px',
                minheight: '500px',

            }}>
                <Table aria-label="my leaves table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((column, i) => {
                                    return (
                                        <TableCell key={column} sx={{ backgroundColor: theme.palette.light.main }}>
                                            <TableSortLabel
                                                sx={{
                                                    '&.MuiTableSortLabel-root': {
                                                        color: theme.palette.light.contrastText,
                                                        '& .MuiTableSortLabel-icon': {
                                                            color: theme.palette.light.contrastText
                                                        }
                                                    },
                                                }}
                                                active={orderBy === fields[i]}
                                                direction={orderBy === fields[i] ? order : "asc"}
                                                onClick={() => { handleSortRequest(fields[i]) }}>
                                                {column}
                                            </TableSortLabel>
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            paginationResult(filteredMyLeave, order, orderBy, page, rowsPerPage)?.map((leave, i) => {
                                return (
                                    <TableRow key={leave.leave_id}>
                                        <TableCell>{moment(leave.applied_on).format('DD-MMM-YY')}</TableCell>
                                        <TableCell>{leave.type}</TableCell>
                                        <TableCell>{leave?.leave_type === "CO" ? 'Comp-off Request' : leave?.leave_type?.charAt(0).toUpperCase() + leave?.leave_type?.slice(1) + ' leave' || ''}</TableCell>
                                        <TableCell>{moment(leave.from).format('DD-MMM-YY')}</TableCell>
                                        <TableCell>{moment(leave.to).format('DD-MMM-YY')}</TableCell>
                                        <TableCell>{leave.working_days}</TableCell>
                                        <TableCell
                                            sx={{ color: "#EE7214", cursor: leave.file ? 'zoom-in' : 'pointer' }}
                                            onClick={() => {
                                                if (leave.file) {
                                                    window.open(baseUrl + '/public/attachment/' + leave.file);
                                                }
                                            }}
                                        >
                                            {leave?.type !== 'UL' && <Tooltip
                                                arrow
                                                title={leave.notes && <Typography variant="caption">{leave.notes}</Typography>}
                                            >{leave?.notes || leave?.file ? 'LINK' : '--'}
                                            </Tooltip>
                                            }
                                            {leave?.type === 'UL' && <Tooltip
                                                arrow
                                                title={leave.manager_notes && <Typography variant="caption">{leave.manager_notes}</Typography>}
                                            >{leave?.manager_notes ? 'LINK' : '--'}
                                            </Tooltip>
                                            }
                                        </TableCell>
                                        <TableCell>{leave.applied_to_name}</TableCell>
                                        {leave.type !== 'UL' && leave.status === "pending" &&
                                            <TableCell>
                                                <Chip icon={<PendingIcon />}
                                                    label="Pending"
                                                    color="warning"
                                                    sx={{ width: '115px' }}
                                                />
                                            </TableCell>
                                        }
                                        {leave.type !== 'UL' && leave.status === "approved" &&
                                            <TableCell>
                                                <Chip icon={<CheckCircleIcon />}
                                                    label="Approved"
                                                    color="success"
                                                    sx={{ width: '115px' }}
                                                />
                                            </TableCell>
                                        }
                                        {leave.type !== 'UL' && leave.status === "declined" &&
                                            <TableCell>
                                                <Chip icon={<CancelIcon />}
                                                    label="Declined"
                                                    color="error"
                                                    sx={{ width: '115px' }}
                                                />
                                            </TableCell>
                                        }
                                        {leave.type !== 'UL' && leave.status === "withdrawn" &&
                                            <TableCell>
                                                <Chip icon={<CancelIcon color="#FFF" />}
                                                    label="Withdrawn"
                                                    sx={{ width: '115px', color: "#FFF", backgroundColor: "#0096A7", ":hover": { color: "#FFF", backgroundColor: "#0096A7" } }}
                                                />
                                            </TableCell>
                                        }
                                        {leave.type === 'UL' && leave.original_status === "approved" &&
                                            <TableCell>
                                                <Chip icon={<CheckCircleIcon />}
                                                    label="Approved"
                                                    color="success"
                                                    sx={{ width: '115px' }}
                                                />
                                            </TableCell>
                                        }
                                        {leave.type === 'UL' && leave.original_status === "withdrawn" &&
                                            <TableCell>
                                                <Chip icon={<CancelIcon color="#FFF" />}
                                                    label="Withdrawn"
                                                    sx={{ width: '115px', color: "#FFF", backgroundColor: "#0096A7", ":hover": { color: "#FFF", backgroundColor: "#0096A7" } }}
                                                />
                                            </TableCell>
                                        }
                                        <TableCell>
                                            {
                                                ((leave.status === 'pending' && leave.leave_type !== 'CO') || (leave.leave_type === 'CO' && (leave.status === 'pending' || leave.status === 'approved'))) &&
                                                <EditIcon
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        dispatch(leaveActions.setdataInEdit({ dataInEdit: leave }));
                                                        dispatch(leaveActions.setSelectedLeaveData({ selectedLeaveData: leave }));
                                                        dispatch(leaveActions.setEditLeaveModalOpen());
                                                    }}
                                                />
                                            }
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                sx={{
                    '& .MuiIconButton-root': {
                        color: theme.palette.surface.contrastText,
                    },
                    '& .Mui-disabled': {
                        color: theme.palette.disabled.main,
                    },
                    '& .MuiTablePagination-toolbar': {
                        color: theme.palette.surface.contrastText
                    }
                }}
                rowsPerPageOptions={pages}
                component="div"
                count={filteredMyLeave?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton={true}
                showLastButton={true}
            />
        </>
    );
}

export default MyLeavesTable;