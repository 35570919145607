import axios from "axios";
let baseUrl = process.env.REACT_APP_DEV_BASE_URL;
let token = localStorage.getItem('token');

const fetchTimelinessData = async (project_uid, snapshot_date, compared_to, filter1, filter2) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/performance/?project_id=${project_uid}&snapshot_date=${snapshot_date}&baseline_date=${compared_to}&filter=${filter1}&hide_future_tasks=${filter2}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        )
    ]);
    return response;
}

const updateVoteRequest = async (data) => {
    let token = localStorage.getItem('token');
    const [response] = await Promise.all([
        (await axios(`${baseUrl}/api/correct-delay`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: data
        })
        )
    ]);
    return response;
}

export {
    fetchTimelinessData,
    updateVoteRequest,
};