import { Stack, Button } from "@mui/material";
import React, { useEffect, useRef } from "react";
import AllocationLoader from "./AllocationLoader";
import AllocationDropdowns from "./AllocationDropdowns";
import { allocationActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { fetchDefaultAllocations } from '../../services/allocationServices';
import EmptyPage from '../EmptyPage';
import moment from "moment";
import ShankeyChart from "./ShankeyChart";
import { useNavigate } from "react-router-dom";
import SpreadsheetModal from "./SpreadsheetModal";

function Allocation() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fetchAllocationRef = useRef(false);
    const result = useSelector((state) => state.allocation.result);
    const contributors = useSelector((state) => state.allocation.contributors);

    const selectedCollections = useSelector((state) => state.allocation.selectedCollections);
    const selectedProjects = useSelector((state) => state.allocation.selectedProjects);

    const fetchAllocation = async () => {
        try {
            dispatch(allocationActions.setLoader({ value: true }));
            const response = await fetchDefaultAllocations();
            dispatch(allocationActions.setCollections({ collections: response.data.all_collections }));
            dispatch(allocationActions.setProjects({ projects: response.data.all_projects }));
            dispatch(allocationActions.setContributors({ contributors: response.data.user_names }));
            dispatch(allocationActions.setResult({ result: response.data.result }));
            dispatch(allocationActions.setLoader({ value: false }));
            dispatch(allocationActions.setSnackbar({ value: { open: true, type: 'success', message: `${response.data.result.length === 0 ? 'No data for current selection' : 'Data fetched !!!'}` } }));
        }
        catch (error) {
            dispatch(allocationActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(allocationActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const checkAllSelected = (contributors) => {
        let allSelected = true;
        for (const contributor of contributors) {
            if (!contributor.selected) {
                allSelected = false;
                break;
            }
        }
        dispatch(allocationActions.setAllContributorSelected({ allContributorSelected: allSelected }));
    }

    useEffect(() => {
        if (fetchAllocationRef.current) return;
        fetchAllocationRef.current = true;
        dispatch(allocationActions.setStartDate({ startDate: moment().format('YYYY-MM-DD') }));
        dispatch(allocationActions.setDuration({ duration: 4 }));
        dispatch(allocationActions.setSelectedCollection({ selectedCollection: -1 }));
        dispatch(allocationActions.setContributors({ contributors: [] }));
        dispatch(allocationActions.setProjects({ projects: [] }));
        dispatch(allocationActions.setResult({ result: [] }));
        fetchAllocation();
    });

    return (
        <Stack rowGap={2}>
            <AllocationDropdowns checkAllSelected={checkAllSelected} />
            {result.length === 0 && contributors.length === 0 && <EmptyPage text='' />}
            {(result.length !== 0 || contributors.length !== 0) && <ShankeyChart checkAllSelected={checkAllSelected} />}
            {(result.length !== 0 || contributors.length !== 0) && selectedCollections.length !== 0 && selectedProjects.length !== 0 && <Button
                sx={{ width: '200px' }}
                variant="contained"
                color="secondary"
                onClick={() => dispatch(allocationActions.setSpreadsheetModalOpen())}
            >Download data</Button>}
            <SpreadsheetModal />
            <AllocationLoader />
        </Stack >
    );
}

export default Allocation;