import React from "react";
import { TableContainer, TableCell, Table, TableHead, TableRow, TableBody } from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";

const ErrorTable = () => {
    const theme = useTheme();
    const checkSnapshot = useSelector((state) => state.checkSnapshot.checkSnapshot);
    const error_colums = ['Task Name', 'Parent Task', 'Assignees', 'Type'];

    return (
        (checkSnapshot.errors.hanging.length !== 0) &&
        <TableContainer sx={{
            borderRadius: '10px',
            maxHeight: '500px',
            "&::-webkit-scrollbar": { width: 2 },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#A50203',
                borderRadius: '10px'
            }
        }}>
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        {
                            error_colums.map((column) => {
                                return (
                                    <TableCell key={column} sx={{ backgroundColor: theme.palette.light.main }}>
                                        {column}
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        checkSnapshot.warnings.overdue.map((task, i) => {
                            return (
                                <TableRow key={task.id + 'error-check'}>
                                    <TableCell>{task.name}</TableCell>
                                    <TableCell>{task.parent}</TableCell>
                                    <TableCell>{task.users.filter((user) => user?.name).map((user) => user.name).join(', ') || '--'}</TableCell>
                                    <TableCell>Task must have a predecessor</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ErrorTable;