import { useTheme } from "@emotion/react";
import { Box, Stack, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, FormControlLabel, Switch, Tooltip, Typography, TableSortLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { taskTimelinessActions } from "../../store";
import moment from "moment";
import { fetchTimelinessData } from '../../services/timelinessServices';
import UpvoteIcons from "./UpvoteIcons";
import EditIcon from '@mui/icons-material/Edit';
import CorrectDelayModal from "./CorrectDelayModal";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { paginationResult } from '../../Utils/TableColumnSort';

const TimelinessTable = () => {
    const theme = useTheme();
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pages = useSelector((state) => state.taskTimeliness.pages);
    const page = useSelector((state) => state.taskTimeliness.page);
    const rowsPerPage = useSelector((state) => state.taskTimeliness.rowsPerPage);
    const filter1 = useSelector((state) => state.taskTimeliness.filter1);
    const filter2 = useSelector((state) => state.taskTimeliness.filter2);

    const timelinessData = useSelector((state) => state.taskTimeliness.timelinessData);
    const filteredTimelinessData = useSelector((state) => state.taskTimeliness.filteredTimelinessData);
    const selectedProjectId = useSelector((state) => state.taskTimeliness.selectedProjectId);
    const selectedSnapshotDate = useSelector((state) => state.taskTimeliness.selectedSnapshotDate);
    const selectedComparedTo = useSelector((state) => state.taskTimeliness.selectedComparedTo);
    let manager = useSelector((state) => state.user.manager);

    const style = {
        backgroundColor: theme.palette.surface1.main, color: theme.palette.surface1.contrastText, minheight: '70vh', width: '100%', borderRadius: '10px',
        padding: 3
    }
    const columns = ['Task Name', 'Current Assignees', 'Current End Date', 'Baseline End Date', 'AE - BE', 'Pred. Delay', 'On CP?', 'Net Delay', 'Task Type', 'Corrected Delay', 'Note', 'Vote', ''];
    const fields = ['task_title', 'assignees', 'end_date', 'base_end_date', 'AEsubBE', 'predec_delay', 'on_cp', 'net_delay', 'task_type', 'corrected_delay', 'corrected_delay_comment', 'upvote', ''];

    const handleChangePage = (event, newPage) => {
        dispatch(taskTimelinessActions.setPage({ value: newPage }));
    }

    const handleChangeRowsPerPage = (event) => {
        dispatch(taskTimelinessActions.setRowsPerPage({ value: parseInt(event.target.value) }));
        dispatch(taskTimelinessActions.setPage({ value: 0 }));
    }

    const handleSortRequest = (cellId) => {
        if (!order) {
            setOrder("asc")
            setOrderBy(cellId);
        }
        else if (order === "asc") {
            setOrder("desc");
            setOrderBy(cellId);
        }
        else {
            setOrder();
            setOrderBy();
        }
    }

    const getTimelinessDataHandler = async (filter1, filter2) => {
        try {
            dispatch(taskTimelinessActions.setPage({ value: 0 }));
            dispatch(taskTimelinessActions.setLoader({ value: true }));
            const response = await fetchTimelinessData(selectedProjectId.project_uid, selectedSnapshotDate, selectedComparedTo, filter1, filter2);
            dispatch(taskTimelinessActions.setTimelinessData({ timelinessData: response.data.delayArray }));
            dispatch(taskTimelinessActions.setDelayBlock({ delayBlock: response.data.delay_block }));
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data fetched !!!' } }));
        }
        catch (error) {
            dispatch(taskTimelinessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(taskTimelinessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const correctDelayModalHandler = (taskId, delay, comment, upvote) => {
        dispatch(taskTimelinessActions.setSelectedTask({ selectedTask: { taskId: taskId, delay: (delay === null || delay === '') ? '' : delay, comment: comment || '', upvote } }));
        dispatch(taskTimelinessActions.setCorrectedDelayModalOpen());
    }

    return (
        <Box sx={{ ...style, marginTop: 1 }}>
            <Stack direction="row" alignItems="center" columnGap={3}>
                <TextField id="search-in-timeliness" label="Search" variant="filled"
                    InputProps={{ style: { backgroundColor: theme.palette.searchInput.main, color: theme.palette.searchInput.contrastText } }}
                    sx={{ width: '60%' }}
                    onChange={(e) => {
                        dispatch(taskTimelinessActions.setPage({ value: 0 }));
                        const seachedText = e.target.value.toLowerCase();
                        const filteredTasks = timelinessData.filter((task) => {
                            return (task.task_title.toLowerCase().includes(seachedText) || task.assignees.join(', ').toLowerCase().includes(seachedText))
                        });
                        dispatch(taskTimelinessActions.setFilteredTimelinessData({ filteredTimelinessData: filteredTasks }));
                    }}
                />
                <FormControlLabel control={<Switch checked={filter1} onChange={(e) => {
                    dispatch(taskTimelinessActions.setFilter1({ value: e.target.checked }));
                    getTimelinessDataHandler(e.target.checked, filter2);
                }} />} label="Show changed tasks" />
                <FormControlLabel control={<Switch checked={filter2} onChange={(e) => {
                    dispatch(taskTimelinessActions.setFilter2({ value: e.target.checked }));
                    getTimelinessDataHandler(filter1, e.target.checked);
                }} />} label="Hide future tasks" />
            </Stack>
            <Box sx={{ backgroundColor: theme.palette.surface.main, borderRadius: '10px', padding: 0.3, marginTop: 2 }}>
                <TableContainer sx={{
                    backgroundColor: theme.palette.lighter.main,
                    borderRadius: '10px',
                    minheight: '500px',
                    "&::-webkit-scrollbar": { width: 0 },
                }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    columns.map((column, i) => {
                                        return (
                                            <TableCell key={column} sx={{ backgroundColor: theme.palette.light.main }}>
                                                <TableSortLabel
                                                    sx={{
                                                        '&.MuiTableSortLabel-root': {
                                                            color: theme.palette.light.contrastText,
                                                            '& .MuiTableSortLabel-icon': {
                                                                color: theme.palette.light.contrastText
                                                            }
                                                        },
                                                    }}
                                                    active={orderBy === fields[i]}
                                                    direction={orderBy === fields[i] ? order : "asc"}
                                                    onClick={() => { handleSortRequest(fields[i]) }}>
                                                    {column}
                                                </TableSortLabel>
                                            </TableCell>
                                        )
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                paginationResult(filteredTimelinessData, order, orderBy, page, rowsPerPage).map((task, i) => {
                                    return (
                                        <TableRow key={task.uid}>
                                            <TableCell sx={{ cursor: 'pointer' }} onClick={() => {
                                                task.task_url && window.open(task.task_url);
                                            }}>{task.task_title}</TableCell>
                                            <TableCell sx={{ color: "#EE7214" }}>{task.assignees.join(', ')}</TableCell>
                                            <TableCell>{moment(task.end_date).format('DD-MMM')}</TableCell>
                                            <TableCell>{task.base_end_date === "NA" ? "NA" : moment(task.base_end_date).format('DD-MMM')}</TableCell>
                                            <TableCell>{task.AEsubBE}</TableCell>
                                            <TableCell>{task.predec_delay}</TableCell>
                                            <TableCell>{task.on_cp ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>{task.net_delay}</TableCell>
                                            <TableCell>{task.task_type}</TableCell>
                                            <TableCell>{(task.corrected_delay === '' || task.corrected_delay === null) ? '--' : task.corrected_delay}</TableCell>
                                            <TableCell>
                                                {task.corrected_delay_comment ?
                                                    <Tooltip title={
                                                        <Typography variant="caption">{task.corrected_delay_comment}</Typography>}
                                                        arrow>
                                                        <MoreHorizIcon />
                                                    </Tooltip> : ''
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" alignItems="center" columnGap={1}>
                                                    <UpvoteIcons upvote={task.upvote} taskId={task.task_id} delay={task.corrected_delay} comment={task.corrected_delay_comment} />
                                                </Stack>
                                            </TableCell>
                                            {manager && <TableCell>
                                                <EditIcon sx={{ cursor: 'pointer' }} onClick={() => correctDelayModalHandler(task.task_id, task.corrected_delay, task.corrected_delay_comment, task.upvote)} />
                                            </TableCell>
                                            }
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    sx={{
                        '& .MuiIconButton-root': {
                            color: theme.palette.surface.contrastText,
                        },
                        '& .Mui-disabled': {
                            color: theme.palette.disabled.main,
                        },
                        '& .MuiTablePagination-toolbar': {
                            color: theme.palette.surface.contrastText
                        }
                    }}
                    rowsPerPageOptions={pages}
                    component="div"
                    count={filteredTimelinessData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton={true}
                    showLastButton={true}
                />
            </Box>
            <CorrectDelayModal />
        </Box>
    );
}

export default TimelinessTable;