import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: false,
    snackbar: { open: false, type: 'success', message: '' },
    checkSnapshot: {
        errors: {
            hanging: [],
            time: []
        },
        warnings: {
            assignee: [],
            overdue: [],
            estimate_actual_hours: [],
            description: []
        },
        id: ""
    },
    selectedProject: {
        project_uid: null,
        id: null,
    },
    navigate: '/collections'
}

const checkSnapshotSlice = createSlice({
    name: 'checkSnapshot',
    initialState: initialState,
    reducers: {
        setLoader(state, action) {
            state.loader = action.payload.value;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload.value;
        },
        setCheckSnapshot(state, action) {
            state.checkSnapshot = action.payload.checkSnapshot;
        },
        resetCheckSnapshot(state, action) {
            state.checkSnapshot = {
                errors: {
                    hanging: [],
                    time: []
                },
                warnings: {
                    assignee: [],
                    overdue: [],
                    estimate_actual_hours: [],
                    description: []
                },
                id: ""
            }
        },
        setSelectedProject(state, action) {
            state.selectedProject = action.payload.selectedProject
        },
        setNavigate(state, action) {
            state.navigate = action.payload.navigate;
        },
    }
});

export default checkSnapshotSlice;