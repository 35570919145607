import React, { useEffect, useRef, useState } from "react";
import { Modal, Box, Typography, Stack, Button, TextField, Divider } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { leaveActions } from "../../store";
import { fetchMyLeaveData, withdrawPendingLeave, withdrawApprovedLeave, editLeave } from "../../services/leaveServices";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { getWorkingDays, lastDayOfFinancialYear, firstDayOfFinancialYear, disableWeekends, formatLeaveType } from "../../Utils/leaveHelpers";

const EditLeaveModal = ({ style, dateStyle, textFieldStyle }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const editModalRef = useRef(false);
    const loggedInUser = useSelector((state) => state.user.userEmail);
    const selectedLeaveData = useSelector((state) => state.leave.selectedLeaveData);
    const editLeaveModal = useSelector((state) => state.leave.editLeaveModal);
    const dataInEdit = useSelector((state) => state.leave.dataInEdit);

    const [file, setFile] = useState(null);
    const [saveButton, setSaveButton] = useState(false);
    const inputFile = useRef(null);


    const resetData = () => {
        setSaveButton(false);
        dispatch(leaveActions.setdataInEdit({ dataInEdit: null }));
    }

    useEffect(() => {
        if (editModalRef.current) return;
        editModalRef.current = true;
        resetData();
    });

    const withdrawLeaves = async () => {
        try {
            dispatch(leaveActions.setLoader({ value: true }));
            let leave_id = selectedLeaveData?.leave_id;
            const response = selectedLeaveData?.status === 'pending' ? await withdrawPendingLeave(leave_id) : await withdrawApprovedLeave(leave_id);
            const myLeave = await fetchMyLeaveData();
            dispatch(leaveActions.setMyLeave({ myLeave: myLeave.data.myleaves }));
            dispatch(leaveActions.setFilteredMyLeave({ filteredMyLeave: myLeave.data.myleaves }));
            dispatch(leaveActions.setLeaveBalance({ leaveBalance: myLeave.data.leaveBalance }));
            dispatch(leaveActions.setLoader({ value: false }));
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'success', message: response?.data?.msg || 'leave withdrawn !!' } }));
            dispatch(leaveActions.setEditLeaveModalClose());
            resetData();
            dispatch(leaveActions.resetFilters());
        }
        catch (error) {
            dispatch(leaveActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const updateLeave = async () => {
        try {
            dispatch(leaveActions.setLoader({ value: true }));
            const response = await editLeave(dataInEdit, file);
            const myLeave = await fetchMyLeaveData();
            dispatch(leaveActions.setMyLeave({ myLeave: myLeave.data.myleaves }));
            dispatch(leaveActions.setFilteredMyLeave({ filteredMyLeave: myLeave.data.myleaves }));
            dispatch(leaveActions.setLeaveBalance({ leaveBalance: myLeave.data.leaveBalance }));
            dispatch(leaveActions.setLoader({ value: false }));
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'success', message: response?.data?.msg || 'leave edited !!' } }));
            dispatch(leaveActions.setEditLeaveModalClose());
            resetData();
            dispatch(leaveActions.resetFilters());
        }
        catch (error) {
            dispatch(leaveActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={editLeaveModal}
            onClose={() => {
                dispatch(leaveActions.setEditLeaveModalClose());
                resetData();
            }}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">
                        {(selectedLeaveData?.leave_type === 'CO' ? `Withdraw ${selectedLeaveData?.status} comp-off` : 'Edit Leave')}
                    </Typography>
                    <Divider />

                    <Stack spacing={-1}>
                        <Typography variant="overline" sx={{ color: theme.palette.highlight.main }}>Manager</Typography>
                        <Typography variant="body">{selectedLeaveData?.applied_to_name}</Typography>
                    </Stack>

                    {selectedLeaveData?.leave_type === 'CO' &&
                        <>
                            <Stack spacing={-1}>
                                <Typography variant="overline" sx={{ color: theme.palette.highlight.main }}>Comp-off Dates</Typography>
                                <Typography variant="body">
                                    {moment(selectedLeaveData?.from).format('DD-MMM-YYYY')}  to  {moment(selectedLeaveData?.to).format('DD-MMM-YYYY')}
                                </Typography>
                            </Stack>

                            <Stack spacing={-1}>
                                <Typography variant="overline" sx={{ color: theme.palette.highlight.main }}>Notes</Typography>
                                <Typography variant="body">{selectedLeaveData?.notes}</Typography>
                            </Stack>

                            <Stack direction="row" justifyContent="flex-end">
                                <Button variant="contained" onClick={withdrawLeaves}>
                                    Withdraw comp-off
                                </Button>
                            </Stack>
                        </>
                    }

                    {
                        selectedLeaveData?.leave_type !== 'CO' &&
                        <>
                            <Stack rowGap={1}>
                                <Typography variant="overline" sx={{ color: '#f57c00' }}>Leave Type & Date</Typography>
                                <Stack spacing={-1}>
                                    <Typography variant="overline" sx={{ color: theme.palette.highlight.main }}>Leave type</Typography>
                                    <Typography variant="body">{formatLeaveType(selectedLeaveData?.leave_type)}</Typography>
                                </Stack>

                                {(moment(selectedLeaveData?.from) <= moment()) &&
                                    <Stack spacing={-1}>
                                        <Typography variant="overline" sx={{ color: theme.palette.highlight.main }}>Leave dates</Typography>
                                        <Typography variant="body">
                                            {moment(selectedLeaveData?.from).format('DD-MMM-YYYY')}  to  {moment(selectedLeaveData?.to).format('DD-MMM-YYYY')}
                                        </Typography>
                                    </Stack>
                                }
                                {
                                    (moment(selectedLeaveData?.from) > moment()) &&
                                    <Stack direction="row" columnGap={1} sx={{ width: '100%' }} alignItems="center">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                sx={dateStyle}
                                                label="Leave Date, From"
                                                format="DD-MMM-YYYY"
                                                defaultValue={''}
                                                value={dayjs(dataInEdit?.from)}
                                                required
                                                slotProps={{ field: { readOnly: true }, textField: textFieldStyle }}
                                                minDate={dataInEdit?.leave_type === 'sick' ? dayjs(firstDayOfFinancialYear(moment().format('YYYY-MM-DD'))) : dayjs(moment().add(15, 'days').format('YYYY-MM-DD'))}
                                                maxDate={dataInEdit?.leave_type === 'sick' ? dayjs(moment().format('YYYY-MM-DD')) : dayjs(lastDayOfFinancialYear(moment().format('YYYY-MM-DD')))}
                                                disabled={dataInEdit?.leave_type === ''}
                                                shouldDisableDate={disableWeekends}
                                                views={['day']}
                                                onChange={(value) => {
                                                    let updatedData = { ...dataInEdit };
                                                    updatedData.to = '';
                                                    updatedData.from = moment(new Date(value.$d)).format("YYYY-MM-DD");
                                                    setSaveButton(false);
                                                    dispatch(leaveActions.setdataInEdit({ dataInEdit: updatedData }));
                                                }}
                                            />
                                        </LocalizationProvider>

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                sx={dateStyle}
                                                label="Leave Date, To"
                                                format="DD-MMM-YYYY"
                                                defaultValue={''}
                                                value={dayjs(dataInEdit?.to)}
                                                required
                                                slotProps={{ field: { readOnly: true }, textField: textFieldStyle }}
                                                minDate={dayjs(moment(dataInEdit?.from).format('YYYY-MM-DD'))}
                                                maxDate={dataInEdit?.leave_type === 'sick' ? dayjs(moment().format('YYYY-MM-DD')) : dayjs(lastDayOfFinancialYear(moment().format('YYYY-MM-DD')))}
                                                disabled={dataInEdit?.from === ''}
                                                views={['day']}
                                                shouldDisableDate={disableWeekends}
                                                onChange={(value) => {
                                                    setSaveButton((dataInEdit?.from) && (moment(new Date(value.$d)).format("YYYY-MM-DD")));
                                                    let updatedData = { ...dataInEdit };
                                                    updatedData.to = moment(new Date(value.$d)).format("YYYY-MM-DD");
                                                    dispatch(leaveActions.setdataInEdit({ dataInEdit: updatedData }));
                                                }}
                                            />
                                        </LocalizationProvider>
                                        {dataInEdit?.from && dataInEdit?.to && <Typography variant="body" sx={{ fontWeight: 350, color: theme.palette.highlight.main }}>{getWorkingDays(dataInEdit?.from, dataInEdit?.to) + ' working days'}</Typography>}
                                    </Stack>
                                }

                            </Stack>

                            <Stack>
                                <Typography variant="overline" sx={{ color: '#f57c00' }}>NOTES & ATTACHMENT</Typography>
                                <TextField
                                    FormHelperTextProps={{ sx: { display: 'flex', justifyContent: 'flex-end' } }}
                                    helperText={dataInEdit?.notes.length < 3 && dataInEdit?.notes.length > 0 ? 'min 3 and max 100 chars' : `${dataInEdit?.notes.length}/100`}
                                    error={dataInEdit?.notes.length < 3 && dataInEdit?.notes.length > 0}
                                    id="notes"
                                    label="Notes"
                                    variant="outlined"
                                    value={dataInEdit?.notes}
                                    multiline
                                    rows={2}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 100) {
                                            let updatedData = { ...dataInEdit };
                                            updatedData.notes = e.target.value;
                                            dispatch(leaveActions.setdataInEdit({ dataInEdit: updatedData }));
                                        }
                                    }}
                                />
                                {!dataInEdit?.file &&
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        sx={{ width: '40%' }}
                                        size="large"
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            inputFile.current.click();
                                        }}
                                    >
                                        upload file
                                        <input
                                            style={{ display: 'none' }}
                                            ref={inputFile}
                                            type="file"
                                            accept=".pdf,.jpg,.jpeg,.png"
                                            onChange={(event) => {
                                                const fileObj = event?.target?.files[0]
                                                if (!fileObj) return;
                                                console.log(fileObj);
                                                setFile(fileObj);
                                                let updatedData = { ...dataInEdit };
                                                updatedData.file = fileObj.name;
                                                dispatch(leaveActions.setdataInEdit({ dataInEdit: updatedData }));
                                            }}
                                        />
                                    </Button>
                                }
                                {!dataInEdit?.file &&
                                    <Typography
                                        variant="caption"
                                        sx={{ color: theme.palette.highlight.main, padding: 1 }}
                                    >
                                        Upload a single file of up to 1 MB. Only PDF, JPG, JPEG or PNG format allowed.
                                    </Typography>
                                }
                                {dataInEdit?.file &&
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body" sx={{ color: '#1565c0' }}>{dataInEdit?.file}</Typography>
                                        <CloseIcon sx={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                let updatedData = { ...dataInEdit };
                                                setFile(null);
                                                updatedData.file = '';
                                                dispatch(leaveActions.setdataInEdit({ dataInEdit: updatedData }))
                                            }}
                                        />
                                    </Stack>
                                }
                            </Stack>

                            <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                                {(moment(selectedLeaveData?.from) > moment()) &&
                                    <Button variant="contained" onClick={withdrawLeaves}>
                                        Withdraw leave
                                    </Button>
                                }
                                <Button variant="contained" onClick={updateLeave}>
                                    Update leave
                                </Button>
                            </Stack>
                        </>
                    }


                </Stack>
            </Box>
        </Modal>
    )
}

export default EditLeaveModal;