import { useTheme } from "@emotion/react";
import { Box, Stack, Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { projectSummaryActions } from "../../store";
import { fetchTaskDetails } from "../../services/projectSummaryServices";

const OverdueAndDelayTasks = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const params = useParams();
    const project_uid = params.project_uid;
    const style = {
        backgroundColor: theme.palette.surface2.main, height: '400px', width: '50%', borderRadius: '10px',
        padding: 3, marginTop: 3, color: theme.palette.surface2.contrastText
    }

    const projectSummary = useSelector((state) => state.projectSummary.projectSummary);
    const selectedProjectId = useSelector((state) => state.projectSummary.selectedProjectId);
    const overdueDelay = projectSummary?.overdue_tasks_data ? projectSummary?.overdue_tasks_data : [];
    const columns = ['status', 'Task', 'Deadline', 'Assignee'];
    const selectedSnapshotDate = useSelector((state) => state.projectSummary.selectedSnapshotDate);

    const getTaskDetails = async () => {
        try {
            dispatch(projectSummaryActions.setFilterSelected({ value: false }));
            dispatch(projectSummaryActions.setLoader({ value: true }));
            const response = await fetchTaskDetails(selectedProjectId.project_uid, selectedSnapshotDate);
            dispatch(projectSummaryActions.setTaskDetails({ taskDetails: response.data.task_details }));
            dispatch(projectSummaryActions.setfilteredTaskDetails({ filteredTaskDetails: response.data.task_details }));
            dispatch(projectSummaryActions.setLoader({ value: false }));
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data fetched !!!' } }));
        }
        catch (error) {
            dispatch(projectSummaryActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Box sx={style}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Overdue & Delayed Tasks</Typography>
                <Button onClick={() => {
                    getTaskDetails();
                    navigate(`/project-summary/task-details/${project_uid}/${selectedSnapshotDate}`)
                }}>
                    <Typography variant="caption2">View all Tasks</Typography>
                </Button>
            </Stack>
            <TableContainer sx={{
                backgroundColor: theme.palette.lighter.main,
                borderRadius: '10px',
                marginTop: 2,
                height: '300px',
                "&::-webkit-scrollbar": { width: 0 }
            }}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                columns.map((column) => {
                                    return (
                                        <TableCell key={column} sx={{ backgroundColor: theme.palette.light.main, color: theme.palette.light.contrastText }}>
                                            {column}
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            overdueDelay.map((task) => {
                                return (
                                    <TableRow key={task.task_id}>
                                        <TableCell>{task.status}</TableCell>
                                        <TableCell>{task.task_title}</TableCell>
                                        <TableCell>{moment(task.end_date).format('DD-MMM')}</TableCell>
                                        <TableCell>{task.assignee_names.join(', ')}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default OverdueAndDelayTasks;