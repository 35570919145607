import { Box, Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import SelfDirectednessLoader from './SelfDirectednessLoader';
import EmptyPage from '../EmptyPage';
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { selfDirectednessActions } from "../../store";
import ContributorDropdown from "./ContributorDropdown";
import DatesDropdown from "./DatesDropdown";
import Questions from "./Questions";
import { useNavigate } from "react-router-dom";

const FeedbackCreation = () => {
    const createFeedbackRef = useRef(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();

    const questions = useSelector((state) => state.selfDirectedness.questions);
    let manager = useSelector((state) => state.user.manager);
    if (!manager) {
        navigate('/error');
    }
    const style = { backgroundColor: theme.palette.surface1.main, width: '100%', borderRadius: '10px', marginTop: 1, padding: 2, color: theme.palette.surface1.contrastText };

    useEffect(() => {
        if (createFeedbackRef.current) return;
        createFeedbackRef.current = true;
        dispatch(selfDirectednessActions.setQuestions({ questions: [] }));
        dispatch(selfDirectednessActions.resetDates());
    });

    return (
        <>
            <Stack rowGap={1}>
                <Box sx={style}>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={5}>
                        <ContributorDropdown />
                        <DatesDropdown />
                    </Stack>
                </Box>
                {questions.length === 0 && <EmptyPage text="select a contributor to create feedback" />}
                {questions.length !== 0 && <Questions />}
            </Stack>
            <SelfDirectednessLoader />
        </>
    );
}

export default FeedbackCreation;