import { useTheme } from "@emotion/react";
import { Box, Stack, TextField, MenuItem, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { leaveActions } from "../../store";
import BalanceCard from "./BalanceCard";
import { useRef, useEffect } from "react";
import { fetchMyLeaveData, fetchManager } from '../../services/leaveServices';
import { useNavigate } from "react-router-dom";
import MyLeavesTable from "./MyLeavesTable";
import EmptyPage from "../EmptyPage";
import CompOffModal from './CompOffModal';
import ApplyLeaveModal from './ApplyLeaveModal';
import EditLeaveModal from "./EditLeaveModal";

const MyLeaves = ({ style }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const myLeaveRef = useRef();

    const statusFilters = useSelector((state) => state.leave.statusFilters);
    const selectedStatusFilter = useSelector((state) => state.leave.selectedStatusFilter);
    const leaveBalance = useSelector((state) => state.leave.leaveBalance);
    const filteredMyLeave = useSelector((state) => state.leave.filteredMyLeave);
    const myLeave = useSelector((state) => state.leave.myLeave);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.card.main,
        color: theme.palette.card.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
    };

    const dateStyle = {
        width: '35%',
        svg: { color: theme.palette.svg.main },
        color: theme.palette.datePicker.main,
        input: { color: theme.palette.lighter.contrastText1 }
    }

    const textFieldStyle = {
        InputProps: { style: { backgroundColor: theme.palette.searchInput.main, fontWeight: 350 } },
        variant: "filled"
    }

    const loadMyLeaves = async () => {
        try {
            dispatch(leaveActions.setLoader({ value: true }));
            const myLeave = await fetchMyLeaveData();
            dispatch(leaveActions.setMyLeave({ myLeave: myLeave.data.myleaves }));
            dispatch(leaveActions.setFilteredMyLeave({ filteredMyLeave: myLeave.data.myleaves }));
            dispatch(leaveActions.setLeaveBalance({ leaveBalance: myLeave.data.leaveBalance }));
            const managers = await fetchManager();
            dispatch(leaveActions.setManagers({ managers: managers.data.employees }));
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'success', message: 'data fetched' } }));
            dispatch(leaveActions.setLoader({ value: false }));
        }
        catch (error) {
            dispatch(leaveActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (myLeaveRef.current) return;
        myLeaveRef.current = true;
        dispatch(leaveActions.resetFilters());
        loadMyLeaves();
    });

    return (
        <>
            <Stack direction="row" sx={{ width: '60%' }} columnGap={2}>
                <BalanceCard bgColor="#E0F7FA" color="#0096A7" text="Earned Leave" balance={leaveBalance.EL_balance_without_threshold} />
                <BalanceCard bgColor="#FFE0B2" color="#E64F01" text="Sick Leave" balance={leaveBalance.SL_balance_without_threshold} />
                <BalanceCard bgColor="#E3F2FD" color="#1976D2" text="Comp Off" balance={leaveBalance.CO_balance} />
                {leaveBalance.unpaid_days !== 0 && <BalanceCard bgColor="#FFDAD6" color="#410002" text="Unpaid Leave" balance={leaveBalance.unpaid_days} />}
            </Stack>
            <Box sx={style}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ width: '50%' }} columnGap={2}>
                        <TextField id="search-in-my-leaves" label="Search" variant="filled"
                            InputProps={{ style: { backgroundColor: theme.palette.searchInput.main, color: theme.palette.searchInput.contrastText } }}
                            sx={{ width: '70%' }}
                            onChange={(e) => {
                                dispatch(leaveActions.setPage({ value: 0 }));
                                const seachedText = e.target.value.toLowerCase();
                                let filteredLeaves = selectedStatusFilter !== 'All' ? myLeave.filter((leave) => {
                                    return (leave.status === selectedStatusFilter);
                                }) : myLeave;

                                filteredLeaves = filteredLeaves.filter((leave) => {
                                    return (
                                        leave.applied_on.toLowerCase().includes(seachedText) ||
                                        leave.leave_type?.toLowerCase().includes(seachedText) ||
                                        leave.applied_to_name.toLowerCase().includes(seachedText) ||
                                        leave.from.toLowerCase().includes(seachedText) ||
                                        leave.to.toLowerCase().includes(seachedText)
                                    )
                                });
                                dispatch(leaveActions.setFilteredMyLeave({ filteredMyLeave: filteredLeaves }));
                            }}
                        />

                        <TextField id="status-filter" label="Filter by Status" required variant="filled" select
                            value={selectedStatusFilter}
                            SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }} sx={{ width: '30%', svg: { color: theme.palette.svg.main } }}
                            InputProps={{ style: { backgroundColor: '#FFFFFF' } }}
                            onChange={(e) => {
                                dispatch(leaveActions.setPage({ value: 0 }));
                                dispatch(leaveActions.setSelectedStatusFilter({ selectedStatusFilter: e.target.value }));
                                if (e.target.value === 'All') {
                                    dispatch(leaveActions.setFilteredMyLeave({ filteredMyLeave: myLeave }));
                                }
                                else {
                                    dispatch(leaveActions.setFilteredMyLeave({ filteredMyLeave: myLeave.filter((leave) => leave.status === e.target.value) }));
                                }
                            }}
                        >
                            {
                                statusFilters.map((status, i) => {
                                    return (<MenuItem sx={{ fontWeight: 300, padding: 1.5 }} key={status.name} value={status.value}>
                                        <Typography sx={{ fontWeight: 350 }}>{status.name}</Typography>
                                    </MenuItem>)
                                })
                            }
                        </TextField>
                    </Stack>

                    <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ width: '50%' }} columnGap={2}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="large"
                            onClick={() => dispatch(leaveActions.setCompOffModalOpen())}
                        >
                            Request Comp-off
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => dispatch(leaveActions.setApplyLeaveModalOpen())}
                        >
                            Apply for leave
                        </Button>
                    </Stack>
                </Stack>
            </Box >

            {filteredMyLeave.length !== 0 && <MyLeavesTable />}
            {filteredMyLeave.length === 0 && <EmptyPage />}
            <ApplyLeaveModal style={modalStyle} dateStyle={dateStyle} textFieldStyle={textFieldStyle} />
            <CompOffModal style={modalStyle} dateStyle={dateStyle} textFieldStyle={textFieldStyle} />
            <EditLeaveModal style={modalStyle} dateStyle={dateStyle} textFieldStyle={textFieldStyle} />
        </>
    );
}

export default MyLeaves;