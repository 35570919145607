import { useTheme } from "@emotion/react";
import { Box, Stack, Typography, Button } from "@mui/material";
import { projectSummaryActions } from '../../store/index';
import { useDispatch, useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from "moment";
import { deleteNote, fetchProjectNotes } from '../../services/projectSummaryServices';
import { useNavigate } from "react-router-dom";

const Notes = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectNotes = useSelector((state) => state.projectSummary.projectNotes);
    let manager = useSelector((state) => state.user.manager);
    const selectedProjectId = useSelector((state) => state.projectSummary.selectedProjectId);
    const style = {
        backgroundColor: theme.palette.surface2.main,
        color: theme.palette.surface2.contrastText,
        height: '350px', width: '50%', borderRadius: '10px',
        padding: 3, marginTop: 3
    }

    const onNoteDeleteHandler = async (e) => {
        const noteId = e.currentTarget.dataset.value;
        const project_uid = selectedProjectId.project_uid;
        try {
            dispatch(projectSummaryActions.setLoader({ value: true }));
            await deleteNote(project_uid, noteId);
            const response = await fetchProjectNotes(project_uid);
            dispatch(projectSummaryActions.setProjectNotes({ notes: response.data.notes }));
            dispatch(projectSummaryActions.setLoader({ value: false }));
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'success', message: 'Note Deleted !!!' } }));
        }
        catch (error) {
            dispatch(projectSummaryActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const onNoteEditHandler = async () => {
        console.log("Inside Note Edit Handler");
    }

    return (
        <Box sx={style}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Notes</Typography>
                {manager &&
                    <Button variant="contained" color="secondary" onClick={() => dispatch(projectSummaryActions.setNotesModalOpen())}>
                        <AddIcon />
                        <Typography variant="caption2">Add Notes</Typography>
                    </Button>}
            </Stack>
            <Stack marginTop={3} rowGap={1.5} sx={{
                "&::-webkit-scrollbar": { width: 5 }, overflowY: 'auto', height: '250px', "&::-webkit-scrollbar-track": {
                    backgroundColor: theme.palette.lighter.main
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.light.main,
                    borderRadius: 2
                },
                paddingRight: 2
            }}>
                {
                    projectNotes.map((note) => {
                        return (

                            <Stack key={note.id} direction="row" alignItems="center" justifyContent="space-between">
                                <Stack>
                                    <Typography variant="caption" sx={{ color: theme.palette.highlight.main }}>{moment(note.createdAt).format('DD-MMM-YY, hh:mm a')}</Typography>
                                    <Typography variant="body1">{note.note}</Typography>
                                </Stack>
                                {manager && <Stack direction="row" columnGap={3} alignItems="center">
                                    <EditIcon sx={{ cursor: 'pointer' }} disabled data-value={note.id} onClick={onNoteEditHandler} />
                                    <DeleteIcon sx={{ cursor: 'pointer' }} data-value={note.id} onClick={onNoteDeleteHandler} />
                                </Stack>}
                            </Stack>
                        )
                    })
                }
            </Stack>
        </Box>
    );
}

export default Notes;