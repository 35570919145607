import React from "react";
import { TableContainer, TableCell, Table, TableHead, TableRow, TableBody } from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";

const WarningTable = () => {
    const theme = useTheme();
    const checkSnapshot = useSelector((state) => state.checkSnapshot.checkSnapshot);
    const warning_colums = ['Task Name', 'Parent Task', 'Assignees', 'Type'];

    return (
        (checkSnapshot.warnings.estimate_actual_hours.length !== 0 || checkSnapshot.warnings.description.length !== 0 || checkSnapshot.warnings.assignee.length !== 0 || checkSnapshot.warnings.overdue.length !== 0) &&
        <TableContainer sx={{
            borderRadius: '10px',
            maxHeight: '500px',
            "&::-webkit-scrollbar": { width: 2 },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#feb640',
                borderRadius: '10px'
            },
        }}>
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        {
                            warning_colums.map((column) => {
                                return (
                                    <TableCell key={column} sx={{ backgroundColor: theme.palette.light.main }}>
                                        {column}
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        checkSnapshot.warnings.assignee.map((task, i) => {
                            return (
                                <TableRow key={task.id + 'assignee-check'}>
                                    <TableCell>{task.name}</TableCell>
                                    <TableCell>{task.parent}</TableCell>
                                    <TableCell>--</TableCell>
                                    <TableCell>Task must have have an assignee</TableCell>
                                </TableRow>
                            )
                        })
                    }
                    {
                        checkSnapshot.warnings.overdue.map((task, i) => {
                            return (
                                <TableRow key={task.id + 'overdue-check'}>
                                    <TableCell>{task.name}</TableCell>
                                    <TableCell>{task.parent}</TableCell>
                                    <TableCell>{task.users.filter((user) => user?.name).map((user) => user.name).join(', ') || '--'}</TableCell>
                                    <TableCell>Task is overdue</TableCell>
                                </TableRow>
                            )
                        })
                    }
                    {
                        checkSnapshot.warnings.estimate_actual_hours.map((task, i) => {
                            return (
                                <TableRow key={task.id + 'EH_AH-check'}>
                                    <TableCell>{task.name}</TableCell>
                                    <TableCell>{task.parent}</TableCell>
                                    <TableCell>{task.users.filter((user) => user?.name).map((user) => user.name).join(', ') || '--'}</TableCell>
                                    <TableCell>Task must have estimate hours and actual hours</TableCell>
                                </TableRow>
                            )
                        })
                    }
                    {
                        checkSnapshot.warnings.description.map((task, i) => {
                            return (
                                <TableRow key={task.id + 'description-check'}>
                                    <TableCell>{task.name}</TableCell>
                                    <TableCell>{task.parent}</TableCell>
                                    <TableCell>{task.users.filter((user) => user?.name).map((user) => user.name).join(', ') || '--'}</TableCell>
                                    <TableCell>Task must have a description atleast 5 chars long</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default WarningTable;