import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
    loader: false,
    snackbar: { open: false, type: 'success', message: '' },
    collections: [],
    projects: [],
    contributors: [],
    result: [],
    startDate: moment().format('YYYY-MM-DD'),
    duration: 4,
    selectedCollections: [],
    selectedProjects: [],
    selectedCollection: -1,
    allContributorSelected: true,
    spreadsheetModal: false,
}

const allocationSlice = createSlice({
    name: 'allocation',
    initialState: initialState,
    reducers: {
        setLoader(state, action) {
            state.loader = action.payload.value;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload.value;
        },
        setCollections(state, action) {
            state.collections = [{ collection_id: -1, collection_name: 'All' }, ...action.payload.collections];
            state.selectedCollections = action.payload.collections.map((collection) => collection.collection_id);
        },
        setProjects(state, action) {
            state.projects = action.payload.projects;
            state.selectedProjects = action.payload.projects.map((project) => project.id);
        },
        setContributors(state, action) {
            state.contributors = action.payload.contributors;
            console.log(action.payload.contributors);
        },
        setResult(state, action) {
            state.result = action.payload.result;
        },
        setStartDate(state, action) {
            state.startDate = action.payload.startDate;
        },
        setDuration(state, action) {
            state.duration = action.payload.duration;
        },
        setSelectedProjects(state, action) {
            state.selectedProjects = action.payload.selectedProjects;
        },
        setSelectedCollections(state, action) {
            if (action.payload.selectedCollections === -1) {
                state.selectedCollections = state.collections.filter((collection) => collection.collection_id !== -1).map((collection) => collection.collection_id);
            }
            else {
                state.selectedCollections = action.payload.selectedCollections;
            }
        },
        setSelectedCollection(state, action) {
            state.selectedCollection = action.payload.selectedCollection;
        },
        setAllContributorSelected(state, action) {
            state.allContributorSelected = action.payload.allContributorSelected;
        },
        setSpreadsheetModalOpen(state, action){
            state.spreadsheetModal = true;
        },
        setSpreadsheetModalClose(state, action){
            state.spreadsheetModal = false;
        }
    }
});

export default allocationSlice;