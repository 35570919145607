import { Button, Card, Stack, Snackbar, Alert, Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { collectionsActions } from '../../store/index';
import CollectionCard from "./CollectionCard";
import CollectionMenus from "./CollectionMenus";
import AddProjectModal from "./AddProjectModal";
import AddCollectionModal from "./AddCollectionModal";
import ViewContributorsModal from "./ViewContributorsModal";
import { fetchCollectionsData } from "../../services/collectionServices";
import RenameCollectionModal from "./RenameCollectionModal";
import RemoveCollectionModal from "./RemoveCollectionModal";
import ChangeCollectionModal from "./ChangeCollectionModal";
import RemoveFromCollectionModal from "./RemoveFromCollectionModal";
import AddSnapshotModal from "./AddSnapshotModal";
import EditProjectModal from "./EditProjectModal";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

function Collections() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const open = useSelector((state) => state.drawer.open);
    const collections = useSelector((state) => state.collections.collections);
    const loader = useSelector((state) => state.collections.loader);
    const snackbar = useSelector((state) => state.collections.snackbar);
    const collectionsFetchedRef = useRef(false);
    const navigate = useNavigate();
    let manager = useSelector((state) => state.user.manager);
    const fetchCollections = async () => {
        try {
            dispatch(collectionsActions.setLoader({ value: true }));
            const response = await fetchCollectionsData();
            dispatch(collectionsActions.setLoader({ value: false }));
            dispatch(collectionsActions.setCollections({ collections: response.data.collections }));
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data Fetched' } }));
        }
        catch (error) {
            dispatch(collectionsActions.setLoader({ value: false }));
            console.log(error.response);
            if (error?.response?.status === 401) {
                dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'error', message: "Unauthorized" } }));
                localStorage.removeItem('token');
                navigate('/login');
            }
            else {
                dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            }
        }
    }


    useEffect(() => {
        if (collectionsFetchedRef.current) return;
        collectionsFetchedRef.current = true;
        fetchCollections();
    }, [collections]);

    const handleSnackbarClose = () => {
        dispatch(collectionsActions.setSnackbar({ value: { open: false, type: 'success', message: '' } }));
    }

    let cardStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: open ? '25vw' : '28vw',
        transitionDuration: '0.3s',
        minHeight: '326px',
        backgroundColor: theme.palette.card.main,
        color: theme.palette.card.contrastText,
        borderRadius: '20px'
    }

    return (
        <>
            <Stack>
                {
                    manager &&
                    <Stack direction="row" spacing={3} justifyContent="flex-end" marginBottom={10} marginTop={2} marginRight={open ? '' : '40px'}>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => dispatch(collectionsActions.setAddProjectModalOpen())}
                        >
                            ADD PROJECT
                        </Button>
                        <Button
                            variant="contained"
                            color='secondary'
                            size="large"
                            onClick={() => dispatch(collectionsActions.setAddCollectionModalOpen())}
                        >
                            ADD COLLECTION
                        </Button>
                    </Stack>
                }
                <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap justifyContent="flex-start" rowGap={5} columnGap={7} marginLeft={open ? '' : '25px'} marginTop={manager ? 0 : 10}>
                    {collections.map((collection) => {
                        return (
                            <Card elevation={0} sx={cardStyle} key={collection.collection_id}>
                                <CollectionCard data={collection} />
                            </Card>)
                    })}
                </Stack >
            </Stack >
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert severity={snackbar.type} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <CollectionMenus type="collectionName" />
            <CollectionMenus type="projectName" />
            <AddProjectModal />
            <AddCollectionModal />
            <ViewContributorsModal />
            <Backdrop
                // sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                sx={{
                    zIndex: (theme) =>
                        Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
                }}
                open={loader}
            >
                <CircularProgress color="secondary" />
            </Backdrop>
            <RenameCollectionModal />
            <RemoveCollectionModal />
            <ChangeCollectionModal />
            <RemoveFromCollectionModal />
            <AddSnapshotModal />
            <EditProjectModal />
        </>
    );
}

export default Collections;