import { useTheme } from "@emotion/react";
import { Box, Stack, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Chip, Button, Typography, TableSortLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { contributorDetailActions } from "../../store";
import moment from "moment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ErrorIcon from '@mui/icons-material/Error';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useState } from "react";
import { paginationResult } from '../../Utils/TableColumnSort';

const ContributorDetailsTable = () => {
    const theme = useTheme();
    const [order, setOrder] = useState();
    const [orderBy, setOrderBy] = useState();
    const dispatch = useDispatch();
    const contributorData = useSelector((state) => state.contributorDetail.contributorData);
    const filteredContributorData = useSelector((state) => state.contributorDetail.filteredContributorData);
    const pages = useSelector((state) => state.contributorDetail.pages);
    const page = useSelector((state) => state.contributorDetail.page);
    const rowsPerPage = useSelector((state) => state.contributorDetail.rowsPerPage);
    const filtersSelected = useSelector((state) => state.contributorDetail.filtersSelected);

    const columns = ['Project', 'Task Name', 'Start Date', 'End Date', 'Task Type', 'Status'];
    const fields = ['project_name', 'task_title', 'start_date', 'end_date', 'task_type', 'task_status'];

    const handleChangePage = (event, newPage) => {
        dispatch(contributorDetailActions.setPage({ value: newPage }));
    }

    const handleChangeRowsPerPage = (event) => {
        dispatch(contributorDetailActions.setRowsPerPage({ value: parseInt(event.target.value) }));
        dispatch(contributorDetailActions.setPage({ value: 0 }));
    }

    const handleSortRequest = (cellId) => {
        if (!order) {
            setOrder("asc")
            setOrderBy(cellId);
        }
        else if (order === "asc") {
            setOrder("desc");
            setOrderBy(cellId);
        }
        else {
            setOrder();
            setOrderBy();
        }
    }

    return (
        <Stack rowGap={2}>
            <Stack direction="row" justifyContent="space-between">
                <TextField id="search-in-timeliness" label="Search" variant="filled"
                    InputProps={{ style: { backgroundColor: theme.palette.searchInput.main, color: theme.palette.searchInput.contrastText } }}
                    sx={{ width: '60%' }}
                    onChange={(e) => {
                        dispatch(contributorDetailActions.setPage({ value: 0 }));
                        const seachedText = e.target.value.toLowerCase();
                        const filteredTasks = contributorData.filter((task) => {
                            return (task.task_title.toLowerCase().includes(seachedText))
                        });
                        dispatch(contributorDetailActions.setFilteredContributorData({ filteredContributorData: filteredTasks }));
                    }}
                />
                <Button variant={filtersSelected ? "contained" : "outlined"} color="secondary" size="medium" onClick={() => {
                    dispatch(contributorDetailActions.setTaskFilterModalOpen());
                }}>
                    <Stack direction="row" justifyContent="space-between" columnGap={1} alignItems="center">
                        <FilterListIcon style={{ fontSize: 25, marginLeft: 0 }} />
                        <Typography variant="subtitle1">Filters</Typography>
                    </Stack>
                </Button>
            </Stack>
            <Box style={{ backgroundColor: theme.palette.surface.main, color: theme.palette.surface.contrastText, padding: '1%', borderRadius: '10px' }}>
                <TableContainer sx={{
                    backgroundColor: theme.palette.lighter.main,
                    borderRadius: '10px',
                    minheight: '500px',
                    "&::-webkit-scrollbar": { width: 0 }
                }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    columns.map((column, i) => {
                                        return (
                                            <TableCell key={column} sx={{ backgroundColor: theme.palette.light.main }}>
                                                <TableSortLabel
                                                    sx={{
                                                        '&.MuiTableSortLabel-root': {
                                                            color: theme.palette.light.contrastText,
                                                            '& .MuiTableSortLabel-icon': {
                                                                color: theme.palette.light.contrastText
                                                            }
                                                        },
                                                    }}
                                                    active={orderBy === fields[i]}
                                                    direction={orderBy === fields[i] ? order : "asc"}
                                                    onClick={() => { handleSortRequest(fields[i]) }}>
                                                    {column}
                                                </TableSortLabel>
                                            </TableCell>
                                        )
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                paginationResult(filteredContributorData, order, orderBy, page, rowsPerPage).map((task, i) => {
                                    return (
                                        <TableRow key={task.uid}>
                                            <TableCell sx={{ color: "#EE7214" }}>{task.project_name}</TableCell>
                                            <TableCell>{task.task_title}</TableCell>
                                            <TableCell>{moment(task.start_date).format('DD-MMM')}</TableCell>
                                            <TableCell>{moment(task.end_date).format('DD-MMM')}</TableCell>
                                            <TableCell>{task.task_type}</TableCell>
                                            {task.task_status === "LATE" &&
                                                <TableCell>
                                                    <Chip icon={<WarningRoundedIcon />}
                                                        label="LATE"
                                                        color="warning"
                                                        sx={{ width: '110px' }}
                                                    />
                                                </TableCell>
                                            }
                                            {task.task_status === "ON_TIME" &&
                                                <TableCell>
                                                    <Chip icon={<CheckCircleIcon />}
                                                        label="ON TIME"
                                                        color="success"
                                                        sx={{ width: '110px' }}
                                                    />
                                                </TableCell>
                                            }
                                            {task.task_status === "OVERDUE" &&
                                                <TableCell>
                                                    <Chip icon={<ErrorIcon />}
                                                        label="OVERDUE"
                                                        color="error"
                                                        sx={{ width: '110px' }}
                                                    />
                                                </TableCell>
                                            }
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    sx={{
                        color: theme.palette.surface.contrastText,
                        '& .MuiIconButton-root': {
                            color: theme.palette.surface.contrastText,
                        },
                        '& .Mui-disabled': {
                            color: theme.palette.disabled.main,
                        },
                        '& .MuiTablePagination-toolbar': {
                            color: theme.palette.surface.contrastText
                        }
                    }}
                    rowsPerPageOptions={pages}
                    component="div"
                    count={filteredContributorData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton={true}
                    showLastButton={true}
                />
            </Box>
        </Stack>
    );
}

export default ContributorDetailsTable;