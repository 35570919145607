import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: false,
    snackbar: { open: false, type: 'success', message: '' },
    projectDetails: [],
    editRoleModal: false,
    deleteProjectModal: false,
    deleteSnapshotModal: false,
    projectCompletionModal: false,
    deleteSnapshotId: null,
}

const projectDetailSlice = createSlice({
    name: 'projectDetails',
    initialState: initialState,
    reducers: {
        setLoader(state, action) {
            state.loader = action.payload.value;
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload.value;
        },
        setProjectDetails(state, action) {
            state.projectDetails = action.payload.projectDetails
        },
        setDeleteProjectModalOpen(state, action) {
            state.deleteProjectModal = true;
        },
        setDeleteProjectModalClose(state, action) {
            state.deleteProjectModal = false;
        },
        setEditRoleModalOpen(state, action) {
            state.editRoleModal = true;
        },
        setEditRoleModalClose(state, action) {
            state.editRoleModal = false;
        },
        setProjectCompletionModalOpen(state, action) {
            state.projectCompletionModal = true;
        },
        setProjectCompletionModalClose(state, action) {
            state.projectCompletionModal = false;
        },
        setDeleteSnapshotModalOpen(state, action) {
            state.deleteSnapshotModal = true;
            state.deleteSnapshotId = action.payload.snapshotId;
            console.log(action.payload.snapshotId);
        },
        setDeleteSnapshotModalClose(state, action) {
            state.deleteSnapshotModal = false;
            state.deleteSnapshotId = null;
        },
    }
});

export default projectDetailSlice;