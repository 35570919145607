import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Stack, TextField, MenuItem, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { collectionsActions } from "../../store";
import { changeCollection, fetchCollectionsData } from "../../services/collectionServices";
import { useNavigate } from "react-router-dom";

const ChangeCollectionModal = () => {
    const changeCollectionModal = useSelector((state) => state.collections.changeCollectionModal);
    let collections = useSelector((state) => state.collections.collections);
    const selectedCollectionId = useSelector((state) => state.collections.collectionMenuSelectedCollectionId);
    const selectedProjectId = useSelector((state) => state.collections.projectsMenuSelectedProjectId);
    const [selectedCollection, setSelectedCollection] = useState('');
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
    };
    collections = collections.filter((collection) => collection.collection_id !== 1 && collection.collection_id !== selectedCollectionId);

    useEffect(() => {
        setSelectedCollection('');
    }, [changeCollectionModal]);

    const changeCollectionHandler = async (selectedProjectId, selectedCollection) => {
        try {
            dispatch(collectionsActions.setLoader({ value: true }));
            await changeCollection(selectedProjectId, selectedCollection);
            const response = await fetchCollectionsData();
            dispatch(collectionsActions.setCollections({ collections: response.data.collections }));
            dispatch(collectionsActions.setLoader({ value: false }));
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'success', message: 'Collection changed successfully !!!' } }));
            dispatch(dispatch(collectionsActions.setChangeCollectionModalClose()));

        }
        catch (error) {
            dispatch(collectionsActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(collectionsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={changeCollectionModal}
            onClose={() => dispatch(collectionsActions.setChangeCollectionModalClose())}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Change Collection</Typography>
                    <TextField
                        id="change-collection"
                        label="Select Collection"
                        variant="outlined"
                        select
                        required
                        defaultValue=''
                        SelectProps={{ MenuProps: { sx: { maxHeight: 300 } } }}
                        onChange={(e) => setSelectedCollection(e.target.value)}
                    >
                        {
                            collections.map((collection) => {
                                return (
                                    <MenuItem
                                        sx={{ fontWeight: 300, padding: 1.5 }}
                                        key={collection.collection_id}
                                        value={collection.collection_id}
                                    >
                                        {collection.collection_name}
                                    </MenuItem>
                                )
                            })
                        }
                    </TextField>
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={() => dispatch(collectionsActions.setChangeCollectionModalClose())}>Cancel</Button>
                        <Button disabled={!selectedCollection} onClick={() => changeCollectionHandler(selectedProjectId, selectedCollection)}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default ChangeCollectionModal;