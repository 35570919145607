import { useTheme } from "@emotion/react";
import SnapshotDates from "./SnapshotDates";
import ProjectDetailData from "./ProjectDetailData";
import Milestones from "./Milestones";
import OverdueAndDelayTasks from "./OverdueAndDelayTasks";
import Schedule from "./Schedule";
import Notes from "./Notes";
import SelectProjects from "./SelectProjects";
import { Typography, Box, Stack, Button } from "@mui/material";
import { projectSummaryActions, collectionsActions, checkSnapshotActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import fileDownload from 'js-file-download';
import { downloadProjectSummaryLogs } from '../../services/projectSummaryServices';
import GetAppIcon from '@mui/icons-material/GetApp';
import AddNotesModal from "./AddNotesModal";
import ProjectSummaryLoader from "./ProjectSummaryLoader";
import AddIcon from '@mui/icons-material/Add';
import AddSnapshotModal from '../Collections/AddSnapshotModal';
import { useNavigate } from "react-router-dom";

const ProjectSummaryData = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const selectedProjectId = useSelector((state) => state.projectSummary.selectedProjectId);
    const projects = useSelector((state) => state.projectSummary.projects);
    let manager = useSelector((state) => state.user.manager);

    const style = {
        backgroundColor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        minheight: '70vh', width: '100%', borderRadius: '10px',
        padding: 5
    }

    const downloadLogHandler = async () => {
        try {
            dispatch(projectSummaryActions.setLoader({ value: true }));
            const response = await downloadProjectSummaryLogs(selectedProjectId.id);
            let fileName = '';
            projects.forEach((project) => {
                project.projects.forEach((prjct) => {
                    if (prjct.id === selectedProjectId.id) {
                        fileName = prjct.project_name + ' Logs';
                    }
                });
            });
            fileDownload(response.data, `${fileName}.xlsx`);
            dispatch(projectSummaryActions.setLoader({ value: false }));
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'success', message: 'Logs downloaded!!!' } }));
        }
        catch (error) {
            dispatch(projectSummaryActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectSummaryActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const addSnapshotHandler = async () => {
        dispatch(collectionsActions.setAddSnapshotModalOpen());
        dispatch(checkSnapshotActions.setSelectedProject({ selectedProject: selectedProjectId }));
        dispatch(checkSnapshotActions.setNavigate({ navigate: `/project-summary/${selectedProjectId.project_uid}` }));
    }

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={3}>
                <SelectProjects />
                <Stack direction="row" gap={2}>
                    {
                        manager &&
                        <Button variant="contained" size="large" onClick={addSnapshotHandler}>
                            <Stack direction="row" justifyContent="space-between" columnGap={1} alignItems="center">
                                <AddIcon style={{ fontSize: 25, marginLeft: 0 }} />
                                <Typography variant="subtitle1">Add Snapshot</Typography>
                            </Stack>
                        </Button>
                    }
                    <Button variant="contained" size="large" onClick={downloadLogHandler}>
                        <Stack direction="row" justifyContent="space-between" columnGap={1} alignItems="center">
                            <GetAppIcon style={{ fontSize: 25, marginLeft: 0 }} />
                            <Typography variant="subtitle1">Log</Typography>
                        </Stack>
                    </Button>
                </Stack>
            </Stack>
            <Box sx={style}>
                <SnapshotDates />
                <ProjectDetailData />
                <Stack direction="row" columnGap={2}>
                    <Milestones />
                    <OverdueAndDelayTasks />
                </Stack>
                <Stack direction="row" columnGap={2}>
                    <Schedule />
                    <Notes />
                </Stack>
            </Box>
            <ProjectSummaryLoader />
            <AddNotesModal />
            <AddSnapshotModal />
        </Box>
    );
}

export default ProjectSummaryData;