import { useTheme } from "@emotion/react";
import { Box, Stack, Button, Typography, RadioGroup, FormControlLabel, TextField, Radio } from "@mui/material";
import React, { useState } from "react";
import { selfDirectednessActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postFeedback } from "../../services/selfDirectednessService";

const Questions = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [score1, setScore1] = useState(0);
    const [score2, setScore2] = useState(0);
    const [score3, setScore3] = useState(0);
    const [comment, setComment] = useState('');

    const questions = useSelector((state) => state.selfDirectedness.questions);
    const contributors = useSelector((state) => state.selfDirectedness.contributors);
    const selectedContributor = useSelector((state) => state.selfDirectedness.selectedContributor);
    const response1 = useSelector((state) => state.selfDirectedness.response1);
    const response2 = useSelector((state) => state.selfDirectedness.response2);
    const response3 = useSelector((state) => state.selfDirectedness.response3);
    const fromDate = useSelector((state) => state.selfDirectedness.fromDate);
    const toDate = useSelector((state) => state.selfDirectedness.toDate);

    const style = { backgroundColor: theme.palette.surface1.main, width: '100%', borderRadius: '10px', marginTop: 1, padding: 4, color: theme.palette.surface1.contrastText };

    const fetchHeading = (ques_id) => {
        switch (ques_id) {
            case 1: return '1: Initiative';
            case 2: return '2: Conceptual ability';
            case 3: return '3: Self-discipline';
        }
    }

    const fetchUsername = (email) => {
        const user = contributors.filter((contributor) => contributor.user_email === email);
        let name = user.length > 0 ? user[0]?.user_name || '' : '';
        return name;
    }

    const fetchDefaultOption = (options) => {
        const [defaultOption] = options.filter((option) => option.option_number === 1);
        return defaultOption?.option_id;
    }

    const fetchScore = (ques_id) => {
        switch (ques_id) {
            case 1: return score1;
            case 2: return score2;
            case 3: return score3;
        }
    }

    const findScore = (option_number) => {
        switch (option_number) {
            case 1: return 0;
            case 2: return 1;
            case 3: return 3;
            case 4: return 5;
        }
    }

    const updateScore = (ques_id, option_id, options) => {
        const selectedOption = options.filter((option) => option.option_id == option_id);
        let option_number = findScore(selectedOption[0].option_number);
        switch (ques_id) {
            case 1: setScore1(option_number);
                break;
            case 2: setScore2(option_number);
                break;
            case 3: setScore3(option_number);
                break;
        }
    }

    const updateResponse = (ques_id, option_id) => {
        const response = {
            question_id: ques_id,
            option_id: Number(option_id)
        };
        switch (ques_id) {
            case 1: dispatch(selfDirectednessActions.setResponse1({ response1: response }));
                break;
            case 2: dispatch(selfDirectednessActions.setResponse2({ response2: response }));
                break;
            case 3: dispatch(selfDirectednessActions.setResponse3({ response3: response }));
                break;
        }
    }

    const createFeedback = async () => {
        try {
            let data = {
                user_email: selectedContributor,
                from: fromDate,
                to: toDate,
                comments: comment,
                response: [response1, response2, response3],
            }
            dispatch(selfDirectednessActions.setLoader({ value: true }));
            await postFeedback(data);
            dispatch(selfDirectednessActions.setLoader({ value: false }));
            dispatch(selfDirectednessActions.setSnackbar({ value: { open: true, type: 'success', message: 'Response saved. Responses can be edited or deleted within 30 days.' } }));
            navigate('/Self-directedness');
        }
        catch (error) {
            dispatch(selfDirectednessActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(selfDirectednessActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }


    return (
        <Box sx={style}>
            <Stack rowGap={4}>
                {
                    questions.map((question) => {
                        return (
                            <Stack rowGap={2} key={question.ques_id}>
                                <Typography variant="h6">{fetchHeading(question.ques_id)}</Typography>
                                <Stack direction="row" justifyContent="flex-start" alignItems="center" columnGap={5}>
                                    <Typography variant="body">{question.question} {fetchUsername(selectedContributor)} ?</Typography>
                                    <Typography variant="body" sx={{ color: theme.palette.highlight.main }}>score : {fetchScore(question.ques_id)}</Typography>
                                </Stack>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    sx={{ color: theme.palette.highlight.main, width: '100%', gap: 5 }}
                                    defaultValue={() => fetchDefaultOption(question.options)}
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        updateScore(question.ques_id, e.target.value, question.options);
                                        updateResponse(question.ques_id, e.target.value);
                                    }}
                                >
                                    {
                                        question.options.map((option) => {
                                            return (
                                                <FormControlLabel value={option.option_id} control={<Radio />} label={option.option} key={option.option_number} sx={{ width: '20%' }} />
                                            )
                                        })
                                    }
                                </RadioGroup>
                            </Stack>
                        )
                    })
                }
                <Stack rowGap={2} key={'thisIsComment'}>
                    <Typography variant="h6">4: Comment</Typography>
                    <TextField
                        FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                        helperText={<Stack direction="row" justifyContent={comment.length < 3 && comment !== '' ? "space-between" : "flex-end"}>
                            {comment.length < 3 && comment !== '' && <Typography variant="caption">min 3 and max 2000 chars</Typography>}
                            <Typography variant="caption">{comment.length}/2000</Typography>
                        </Stack>}
                        error={comment.length < 3 && comment !== ''}
                        id="notes-box"
                        label="Enter your response ...."
                        multiline
                        rows={5}
                        value={comment}
                        onChange={(e) => {
                            if (e.target.value.length <= 2000) {
                                setComment(e.target.value);
                            }
                            else {
                                return null;
                            }
                        }}
                        sx={{ width: '50%' }}
                    />
                </Stack>
                <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                    <Button onClick={() => { navigate('/Self-directedness') }} variant="contained"
                    >Cancel</Button>
                    <Button disabled={!response1 || !response2 || !response3} variant="contained" onClick={() => createFeedback()}>Save</Button>
                </Stack>
            </Stack>
        </Box>
    );
}


export default Questions;