import React from "react";
import { Modal, Box, Typography, Stack } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { collectionsActions } from "../../store";


const ViewContributorsModal = () => {
    const viewContributorsModal = useSelector((state) => state.collections.viewContributorsModal);
    const dispatch = useDispatch();
    const contributors = useSelector((state) => state.collections.contributors);
    const theme = useTheme();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: theme.palette.card.main,
        color: theme.palette.card.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto'
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={viewContributorsModal}
            onClose={() => dispatch(collectionsActions.setViewContributorsClose())}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Stack>
                        <Typography variant="h5" sx={{ fontWeight: 600 }}>{contributors.collection_name}</Typography>
                        <Typography variant="caption">{contributors.contributors.length} contributors</Typography>
                    </Stack>
                    <Stack>
                        {
                            contributors.contributors.map((contributor) => {
                                return (
                                    <Typography
                                        variant="subtitle"
                                        key={contributor.user_id}
                                        sx={{ fontWeight: 300 }}
                                    >
                                        {contributor.user_name}
                                    </Typography>
                                )
                            })
                        }
                    </Stack>
                </Stack>
            </Box>
        </Modal>

    );
}

export default ViewContributorsModal;