import { useTheme } from "@emotion/react";
import { Modal, Box, Typography, Stack, Avatar, Chip, Badge } from "@mui/material";

const ProfileModal = ({ profileOpen, handleProfileClose, userData }) => {
    const theme = useTheme();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.lighter.main,
        color: theme.palette.lighter.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };
    return (
        <Modal
            open={profileOpen}
            onClose={handleProfileClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack rowGap={2}>
                    <Stack direction="row" sx={{ display: 'flex', alignItems: 'end', gap: 2 }}>
                        <Badge badgeContent={userData.userType} color="active">
                            <Avatar alt={userData.fullname} src={userData.avatar} content="no-referrer" sx={{ width: 90, height: 90 }} />
                        </Badge>
                        <Stack>
                            <Typography variant="subtitle1" >{userData.fullname}</Typography>
                            <Typography variant="subtitle2">{userData.userEmail}</Typography>
                        </Stack>
                    </Stack>

                    <Stack>
                        <Typography variant="h6">Emails</Typography>
                        <Stack direction="row" rowGap={1} columnGap={1} sx={{ display: "flex", flexWrap: "wrap" }}>
                            {userData?.emails?.map((email) =>
                                // Object.keys(email).length > 1
                                // &&
                                <Chip
                                    key={email?.address}
                                    label={email?.address}
                                    size="medium"
                                    // variant="outlined"
                                    color={email?.hasOwnProperty('primary') ? 'primary' : 'secondary'}
                                />
                            )}
                        </Stack>
                    </Stack>
                    <Stack>
                        <Typography variant="h6">Contact</Typography>
                        <Stack direction="row" rowGap={1} columnGap={1} sx={{ display: "flex", flexWrap: "wrap" }}>
                            {userData?.phones?.map((phone) =>
                                <Chip
                                    key={phone.value}
                                    label={phone.value}
                                    size="medium"
                                    // variant="outlined"
                                    color={phone.hasOwnProperty('primary') ? 'primary' : 'secondary'}
                                />
                            )}
                            {
                                userData?.recoveryPhone && <Chip
                                    key={userData?.recoveryPhone}
                                    label={userData?.recoveryPhone}
                                    size="medium"
                                    // variant="outlined"
                                    color='primary'
                                />
                            }
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </Modal>);
}

export default ProfileModal;