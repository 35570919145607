import { TextField, Typography, MenuItem, Stack } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { contributorDetailActions } from "../../store";
import { fetchContributorData } from '../../services/contributorDetailServices'
import { useNavigate } from "react-router-dom";


const SnapshotDateDropdown = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedContributor = useSelector((state) => state.contributorDetail.selectedContributor);
    const selectedProject = useSelector((state) => state.contributorDetail.selectedProject);

    const projectSnapshotDates = useSelector((state) => state.contributorDetail.projectSnapshotDates);
    const comparedToSnapshots = useSelector((state) => state.contributorDetail.comparedToSnapshots);
    const selectedSnapshotDate = useSelector((state) => state.contributorDetail.selectedSnapshotDate);
    const selectedComparedTo = useSelector((state) => state.contributorDetail.selectedComparedTo);

    const compareToDateChangeHandler = async (compare_to) => {
        try {
            const data = {
                project_uid: selectedProject?.project_uid,
                contributor_id: selectedContributor?.user_id,
                selectedSnapshotDate,
                selectedComparedTo: compare_to
            };
            dispatch(contributorDetailActions.setLoader({ value: true }));
            const response = await fetchContributorData(data);
            dispatch(contributorDetailActions.resetFilters());
            dispatch(contributorDetailActions.setContributorData({ contributorData: response.data.contributorData }));
            dispatch(contributorDetailActions.setLoader({ value: false }));
            dispatch(contributorDetailActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data fetched !!!' } }));
        }
        catch (error) {
            dispatch(contributorDetailActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(contributorDetailActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <>
            <TextField id="snapshot-date" label="SNAPSHOT DATE" variant="filled" select
                disabled={!selectedContributor || !selectedProject}
                // defaultValue={''}
                value={selectedSnapshotDate}
                SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }} sx={{ width: '22%', svg: { color: theme.palette.svg.main } }}
                onChange={(e) => {
                    dispatch(contributorDetailActions.setSelectedSnapshotDate({ selectedSnapshotDate: e.target.value }));
                    dispatch(contributorDetailActions.setSelectedComparedToDate({ selectedComparedTo: '' }));
                    dispatch(contributorDetailActions.setComparedToSnapshotDates());
                }}
            >
                {
                    projectSnapshotDates.map((snapshot, i) => {
                        return (<MenuItem
                            sx={{ padding: 1.5 }}
                            key={snapshot.snapshot_date}
                            value={snapshot.snapshot_date}
                        >
                            <Stack width="100%" direction="row" justifyContent="space-between" alignItems="end">
                                <Typography sx={{ fontWeight: 350 }}>{snapshot.snapshot_date}</Typography>
                                <Typography sx={{ color: theme.palette.highlight.main }} variant="caption">{snapshot.reference ? 'Reference' : ''}</Typography>
                            </Stack>
                        </MenuItem>)
                    })
                }
            </TextField>
            <TextField id="compared-to" label="COMPARE TO" variant="filled" select
                disabled={!selectedContributor || !selectedProject}
                // defaultValue={''}
                value={selectedComparedTo}
                SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }} sx={{ width: '22%', svg: { color: theme.palette.svg.main } }}
                onChange={(e) => {
                    console.log(e.target.value);
                    dispatch(contributorDetailActions.setSelectedComparedToDate({ selectedComparedTo: e.target.value }));
                    compareToDateChangeHandler(e.target.value);
                }}
            >
                {
                    comparedToSnapshots.map((snapshot, i) => {
                        return (<MenuItem
                            sx={{ padding: 1.5 }}
                            key={snapshot.snapshot_date}
                            value={snapshot.snapshot_date}
                        >
                            <Stack width="100%" direction="row" justifyContent="space-between" alignItems="end">
                                <Typography sx={{ fontWeight: 350 }}>{snapshot.snapshot_date}</Typography>
                                <Typography sx={{ color: theme.palette.highlight.main }} variant="caption">{snapshot.reference ? 'Reference' : ''}</Typography>
                            </Stack>
                        </MenuItem>)
                    })
                }
            </TextField>
        </>
    );
}

export default SnapshotDateDropdown;