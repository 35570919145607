import { useTheme } from "@emotion/react";
import { TextField, MenuItem } from "@mui/material";
import { useSelector } from "react-redux/es/hooks/useSelector";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { githubActions } from "../../store";
import { fetchContributorsInProject, fetchIssuesData } from '../../services/githubServices';

const SelectProjects = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const selectedProjectId = useSelector((state) => state.github.selectedProjectId);
    const projects = useSelector((state) => state.github.projects);
    const loggedInUserEmail = useSelector((state) => state.user.userEmail);
    const selectedMonth = useSelector((state) => state.github.selectedMonth);
    const months = useSelector((state) => state.github.months);
    const navigate = useNavigate();

    const flattenedProjects = projects.reduce((acc, collection) => {
        acc.push({ label: 'collection_name', ...collection });
        collection.projects.forEach((project) => {
            acc.push({ label: 'project_name', ...project });
        });
        return acc;
    }, []);

    const fetchGitData = async (project_id) => {
        try {
            dispatch(githubActions.setLoader({ value: true }));
            const contributorResponse = await fetchContributorsInProject(project_id.id);
            dispatch(githubActions.setContributors({ contributors: contributorResponse.data.contributors }));
            let contributor = contributorResponse.data.contributors.filter((contributor) => contributor.user_email == loggedInUserEmail);
            contributor = contributor[0] || contributorResponse.data.contributors[0];
            dispatch(githubActions.setSelectedContributor({ selectedContributor: contributor.user_id }));
            const issuesResponse = await fetchIssuesData(contributor?.user_id, project_id.project_uid, selectedMonth);
            dispatch(githubActions.setIssues({ issues: issuesResponse?.data?.data }));
            dispatch(githubActions.setLoader({ value: false }));
            dispatch(githubActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data Fetched' } }));
        }
        catch (error) {
            dispatch(githubActions.setIssues({ issues: null }));
            dispatch(githubActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(githubActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <TextField
            sx={{ width: '40%', svg: { color: theme.palette.svg.main } }}
            id="select project"
            label="Select Project"
            variant="outlined"
            select
            value={selectedProjectId === null ? '' : JSON.stringify(selectedProjectId)}
            SelectProps={{ MenuProps: { sx: { maxHeight: 500 } } }}
            InputProps={{
                // disableUnderline: true,
                style: {
                    fontWeight: 350
                }
            }}
            onChange={(e) => {
                const value = JSON.parse(e.target.value);
                dispatch(githubActions.setSelectedProjectId({ id: value }));
                dispatch(githubActions.setMonth({ month: months[0].value }))
                fetchGitData(value);
            }}
        >
            {flattenedProjects.map((item, index) => (
                <MenuItem
                    sx={
                        item.label === 'collection_name' ? { fontWeight: 500, padding: 1.5 } : { fontWeight: 350, padding: 0.8, paddingLeft: '20px' }
                    }
                    key={item.label === 'collection_name' ? item.collection_id + item.collection_name : item.id + item.project_name + item.project_uid}
                    value={item.label === 'collection_name' ? item.collection_id : JSON.stringify({ id: item.id, project_uid: item.project_uid })}
                    disabled={item.label === 'collection_name'}
                >
                    {item.label === 'collection_name' ? item.collection_name : item.project_name}
                </MenuItem>
            ))}
        </TextField>
    );
}

export default SelectProjects;