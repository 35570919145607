import React, { useState } from "react";
import { Modal, Box, Typography, Stack, Button, TextField } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { createFilteredWorksheet } from "../../services/allocationServices";
import { allocationActions } from "../../store";

const SpreadsheetModal = () => {
    const theme = useTheme();
    const navigate = useTheme();
    const dispatch = useDispatch();
    const [link, setLink] = useState('');
    const [saveButton, setSaveButton] = useState(false);

    const spreadsheetModal = useSelector((state) => state.allocation.spreadsheetModal);
    const startDate = useSelector((state) => state.allocation.startDate);
    const duration = useSelector((state) => state.allocation.duration);
    const selectedCollections = useSelector((state) => state.allocation.selectedCollections);
    const selectedProjects = useSelector((state) => state.allocation.selectedProjects);
    const urlFormat = 'https://docs.google.com/spreadsheets/d/';

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        boxShadow: 24,
        p: 4,
        borderRadius: '5px',
    };

    const createWorksheet = async (data) => {
        try {
            dispatch(allocationActions.setLoader({ value: true }));
            const response = await createFilteredWorksheet(link, data);
            dispatch(allocationActions.setLoader({ value: false }));
            dispatch(allocationActions.setSnackbar({ value: { open: true, type: 'success', message: response?.data?.msg || 'spreadsheet created !!' } }));
        }
        catch (error) {
            dispatch(allocationActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(allocationActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const reset = () => {
        setLink('');
        setSaveButton();
    }

    const saveDataHandler = async () => {
        let data = {
            startDate: startDate,
            duration: duration,
            requested_collections: selectedCollections,
            requested_projects: selectedProjects
        }
        await createWorksheet(data);
        reset();
        dispatch(allocationActions.setSpreadsheetModalClose());
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={spreadsheetModal}
            onClose={() => dispatch(allocationActions.setSpreadsheetModalClose())}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Add spreadsheet link</Typography>
                    <TextField
                        FormHelperTextProps={{ sx: { marginLeft: 0 } }}
                        helperText={
                            (link !== '' && !link.startsWith(urlFormat)) ? `${urlFormat}...` : <Stack direction='row' justifyContent="space-between">
                                <Typography variant="caption">{`Link to spreadsheet starting with ${urlFormat}...`}</Typography>
                                <Typography variant="caption">{`${link.length}/500`}</Typography>
                            </Stack>}
                        error={link !== '' && !link.startsWith(urlFormat)}
                        id="link-name"
                        label="link"
                        variant="outlined"
                        required
                        value={link}
                        onChange={(e) => {
                            if (e.target.value.length <= 500) {
                                setLink(e.target.value);
                                setSaveButton((e.target.value.length >= 3) && (e.target.value.startsWith(urlFormat)));
                            }
                        }}
                    />
                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button onClick={() => {
                            dispatch(allocationActions.setSpreadsheetModalClose());
                            reset();
                        }}>Cancel</Button>
                        <Button disabled={!saveButton} onClick={saveDataHandler}>Save</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

export default SpreadsheetModal;