import React, { useRef, useState } from "react";
import { Modal, Box, Typography, Stack, Button, TextField, MenuItem, Autocomplete, Divider, Chip } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { leaveActions } from "../../store";
import { fetchLeaveBalance, postLeave, fetchOthersLeave } from "../../services/leaveServices";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import moment from "moment";
import { getWorkingDays, lastDayOfFinancialYear, firstDayOfFinancialYear, disableWeekends } from "../../Utils/leaveHelpers";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';


const PostLeaveModal = ({ style, dateStyle, textFieldStyle }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [leaveType, setLeaveType] = useState('');
    const [saveButton, setSaveButton] = useState(false);
    const [el, setEL] = useState('');
    const [sl, setSL] = useState('');
    const [co, setCO] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [notes, setNotes] = useState('');
    const inputFile = useRef(null);
    const [file, setFile] = useState(null);


    const postLeaveModal = useSelector((state) => state.leave.postLeaveModal);
    const employees = useSelector((state) => state.leave.appliedBy);
    let fullname = useSelector((state) => state.user.fullname);
    const loggedInUser = useSelector((state) => state.user.userEmail);

    const resetData = () => {
        setSelectedEmployee(null);
        setLeaveType('');
        setEL('');
        setSL('');
        setCO('');
        setSaveButton(false);
        setFromDate('');
        setToDate('');
        setFile(null);
    }

    const getBalance = async (email) => {
        try {
            dispatch(leaveActions.setLoader({ value: true }));
            const response = await fetchLeaveBalance(email);
            setEL(response.data.leaveBalance[0].EL_balance_without_threshold);
            setSL(response.data.leaveBalance[0].SL_balance_without_threshold);
            setCO(response.data.leaveBalance[0].CO_balance);
            dispatch(leaveActions.setLoader({ value: false }));
        }
        catch (error) {
            dispatch(leaveActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    const submitPostLeave = async () => {
        try {
            const data = {
                manager: loggedInUser,
                from: fromDate,
                to: toDate,
                type: leaveType,
                notes: notes,
                employee: selectedEmployee.email,
                attachment: file,
            }
            dispatch(leaveActions.setLoader({ value: true }));
            const response = await postLeave(data);
            const othersLeave = await fetchOthersLeave();
            dispatch(leaveActions.setOthersLeave({ othersLeave: othersLeave.data.allLeaves }));
            dispatch(leaveActions.setFilteredOthersLeave({ filteredOthersLeave: othersLeave?.data?.allLeaves.filter((leave) => leave.status === "pending") }));
            dispatch(leaveActions.setLoader({ value: false }));
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'success', message: response?.data?.msg || 'leave submitted !!' } }));
            dispatch(leaveActions.setPostLeaveModalClose());
            resetData();
        }
        catch (error) {
            dispatch(leaveActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(leaveActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={postLeaveModal}
            onClose={() => {
                dispatch(leaveActions.setPostLeaveModalClose());
                resetData();
            }}
        >
            <Box sx={style}>
                <Stack rowGap={3}>
                    <Typography variant="h5">Post Leave Transaction</Typography>
                    <Divider />
                    <Autocomplete
                        id="employee-selection"
                        options={employees}
                        getOptionLabel={(user) => user.name}
                        style={{ width: '50%' }}
                        sx={{ svg: { color: theme.palette.svg.main } }}
                        autoHighlight={false}
                        renderInput={(params) => (
                            <TextField {...params}
                                label="Employee" variant="filled"
                                style={{ backgroundColor: theme.palette.searchInput.main }}
                                value={selectedEmployee === null ? '' : selectedEmployee}
                            />)}
                        renderOption={
                            (props, user) => (
                                <MenuItem
                                    {...props}
                                    style={{ fontWeight: 300, padding: '0.8rem' }}
                                    key={user.email + user.name + user.emp_id}
                                    value={JSON.stringify({
                                        emp_id: user.emp_id,
                                        email: user.email,
                                        name: user.name
                                    })}
                                    disabled={loggedInUser === user.email}
                                >
                                    <Typography sx={{ fontWeight: 350 }}>{user.name}</Typography>
                                </MenuItem>
                            )}
                        onChange={(e, newValue) => {
                            console.log(newValue);
                            setSelectedEmployee(newValue);
                            if (newValue) {
                                getBalance(newValue.email);
                            }
                            else {
                                resetData();
                            }
                        }}
                    />

                    <TextField
                        id="manager"
                        label="Manager"
                        variant="outlined"
                        disabled
                        defaultValue={fullname}
                        sx={{ width: '50%' }}
                    />

                    <Stack rowGap={1}>
                        <Typography variant="overline" sx={{ color: '#f57c00' }}>Leave Type & Date</Typography>
                        <Stack direction="row" gap={1}>
                            <Chip
                                disabled={!selectedEmployee}
                                label="Earned Leave"
                                onClick={(e) => {
                                    setToDate('');
                                    setFromDate('');
                                    setLeaveType('earned');
                                    setSaveButton(false);
                                }
                                }
                                sx={{ cursor: 'pointer', fontSize: '14px', fontWeight: 350 }}
                                color={leaveType === 'earned' ? 'primary' : 'default'}
                            />
                            <Chip
                                disabled={!selectedEmployee}
                                label="Sick Leave"
                                onClick={() => {
                                    setToDate('');
                                    setFromDate('');
                                    setLeaveType('sick');
                                    setSaveButton(false);
                                }}
                                sx={{ cursor: 'pointer', fontSize: '14px', fontWeight: 350 }}
                                color={leaveType === 'sick' ? 'primary' : 'default'}
                            />
                        </Stack>
                    </Stack>

                    <Stack direction="row" columnGap={1} sx={{ width: '100%' }} alignItems="center">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={dateStyle}
                                label="Leave Date, From"
                                format="DD-MMM-YYYY"
                                defaultValue={''}
                                value={dayjs(fromDate)}
                                required
                                slotProps={{
                                    field: {
                                        readOnly: true
                                    },
                                    textField: textFieldStyle
                                }}
                                minDate={dayjs(firstDayOfFinancialYear(moment().format('YYYY-MM-DD')))}
                                maxDate={leaveType === 'sick' ? dayjs(moment().format('YYYY-MM-DD')) : dayjs(lastDayOfFinancialYear(moment().format('YYYY-MM-DD')))}
                                disabled={leaveType === ''}
                                shouldDisableDate={disableWeekends}
                                views={['day']}
                                onChange={(value) => {
                                    setToDate('');
                                    setSaveButton(false);
                                    setFromDate(moment(new Date(value.$d)).format("YYYY-MM-DD"));
                                }}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={dateStyle}
                                label="Leave Date, To"
                                format="DD-MMM-YYYY"
                                defaultValue={''}
                                value={dayjs(toDate)}
                                required
                                slotProps={{
                                    field: {
                                        readOnly: true
                                    },
                                    textField: textFieldStyle
                                }}
                                minDate={dayjs(moment(fromDate).format('YYYY-MM-DD'))}
                                maxDate={leaveType === 'sick' ? dayjs(moment().format('YYYY-MM-DD')) : dayjs(lastDayOfFinancialYear(moment().format('YYYY-MM-DD')))}
                                disabled={fromDate === ''}
                                views={['day']}
                                shouldDisableDate={disableWeekends}
                                onChange={(value) => {
                                    setSaveButton((selectedEmployee) && (leaveType) && (fromDate) && (moment(new Date(value.$d)).format("YYYY-MM-DD")));
                                    setToDate(moment(new Date(value.$d)).format("YYYY-MM-DD"));
                                }}
                            />
                        </LocalizationProvider>
                        {fromDate && toDate && <Typography variant="body" sx={{ fontWeight: 350, color: theme.palette.highlight.main }}>{getWorkingDays(fromDate, toDate) + ' working days'}</Typography>}
                    </Stack>

                    {selectedEmployee &&
                        <Stack direction="row" justifyItems="center" justifyContent="flex-start" columnGap={3}>
                            <Typography variant="caption" sx={{ color: theme.palette.highlight.main }}>{leaveType === 'sick' ? `Available SL balance: ${sl} days` : `Available EL balance: ${el} days`}</Typography>
                            <Typography variant="caption" sx={{ color: theme.palette.highlight.main }}>Available Comp-off balance: {co} days</Typography>
                        </Stack>
                    }

                    <Stack>
                        <Typography variant="overline" sx={{ color: '#f57c00' }}>NOTES & ATTACHMENT</Typography>
                        <TextField
                            FormHelperTextProps={{ sx: { display: 'flex', justifyContent: 'flex-end' } }}
                            helperText={notes.length < 3 && notes.length > 0 ? 'min 3 and max 100 chars' : `${notes.length}/100`}
                            error={notes.length < 3 && notes.length > 0}
                            id="notes"
                            label="Notes"
                            variant="outlined"
                            value={notes}
                            multiline
                            rows={2}
                            onChange={(e) => {
                                if (e.target.value.length <= 100) {
                                    setNotes(e.target.value);
                                }
                            }}
                        />
                        {!file &&
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={{ width: '40%' }}
                                size="large"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    inputFile.current.click();
                                }}
                            >
                                upload file
                                <input
                                    style={{ display: 'none' }}
                                    ref={inputFile}
                                    type="file"
                                    accept=".pdf,.jpg,.jpeg,.png"
                                    onChange={(event) => {
                                        const fileObj = event?.target?.files[0]
                                        if (!fileObj) return;
                                        console.log(fileObj);
                                        console.log(fileObj.name);
                                        setFile(fileObj);
                                    }}
                                />
                            </Button>
                        }
                        {!file &&
                            <Typography
                                variant="caption"
                                sx={{ color: theme.palette.highlight.main, padding: 1 }}
                            >
                                Upload a single file of up to 1 MB. Only PDF, JPG, JPEG or PNG format allowed.
                            </Typography>
                        }
                        {file &&
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="body" sx={{ color: '#1565c0' }}>{file.name}</Typography>
                                <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setFile(null)} />
                            </Stack>
                        }
                    </Stack>

                    <Stack direction="row" justifyContent="flex-end" columnGap={3}>
                        <Button
                            onClick={() => {
                                dispatch(leaveActions.setPostLeaveModalClose());
                                resetData();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button disabled={!saveButton} onClick={submitPostLeave}>Submit</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );

}

export default PostLeaveModal;