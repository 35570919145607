import { Box, Stack, Typography, Snackbar, Alert, Backdrop, CircularProgress, Button, Card, Switch, FormControlLabel, Checkbox, Chip, Tooltip } from "@mui/material";
import { projectDetailsData, updateSnapshotReference } from "../../../services/collectionServices";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { collectionsActions, projectDetailsActions } from "../../../store";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import AddSnapshotModal from "../AddSnapshotModal";
import moment from "moment";
import DeleteProjectModal from "./DeleteProjectModal";
import EditRoleModal from "./EditRoleModal";
import ProjectCompletionModal from "./ProjectCompletionModal";
import DeleteSnapshotModal from "./DeleteSnapshotModal";

const ProjectDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let params = useParams();
    const theme = useTheme();
    const open = useSelector((state) => state.drawer.open);
    const projectDetails = useSelector((state) => state.projectDetails.projectDetails);
    const loader = useSelector((state) => state.projectDetails.loader);
    const snackbar = useSelector((state) => state.projectDetails.snackbar);
    const project_id = params.project_id;
    const projectDetailsFetchedRef = useRef(false);
    const projectCompletionModal = useSelector((state) => state.projectDetails.projectCompletionModal);
    let manager = useSelector((state) => state.user.manager);

    const fetchProjectDetails = async () => {
        try {
            dispatch(projectDetailsActions.setLoader({ value: true }));
            const response = await projectDetailsData(project_id);
            dispatch(projectDetailsActions.setProjectDetails({ projectDetails: response.data }));
            dispatch(projectDetailsActions.setLoader({ value: false }));
            dispatch(projectDetailsActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data Fetched' } }));
        }
        catch (error) {
            dispatch(projectDetailsActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectDetailsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (projectDetailsFetchedRef.current) return;
        projectDetailsFetchedRef.current = true;
        if (!manager) {
            navigate('/error');
        }
        fetchProjectDetails();
    });

    const handleSnackbarClose = () => {
        dispatch(projectDetailsActions.setSnackbar({ value: { open: false, type: 'success', message: '' } }));
    }

    const style = {
        transitionDuration: '0.3s',
        backgroundColor: theme.palette.surface1.main,
        color: theme.palette.surface1.contrastText,
        // borderRadius: '20px',
        overflowY: 'auto',
    }

    const referenceUpdateHandler = async (snapshot_id, checked) => {
        try {
            dispatch(projectDetailsActions.setLoader({ value: true }));
            await updateSnapshotReference(snapshot_id, checked);
            const response = await projectDetailsData(project_id);
            dispatch(projectDetailsActions.setProjectDetails({ projectDetails: response.data }));
            dispatch(projectDetailsActions.setLoader({ value: false }));
            dispatch(projectDetailsActions.setSnackbar({ value: { open: true, type: 'success', message: 'Reference Updated !!!' } }));
        }
        catch (error) {
            dispatch(projectDetailsActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(projectDetailsActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <>
            {projectDetails.status === 1 && <>
                <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={10} marginTop={2}>
                        <Stack>
                            <Typography
                                variant="subtitle"
                                sx={{ cursor: 'pointer' }}

                            >
                                Collection: {projectDetails.collection_name}
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{ fontWeight: 600 }}
                            >
                                {projectDetails.project_name}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={3}
                            marginRight={open ? '' : '40px'}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={() => { navigate('/collections') }}
                            >
                                Go to Dashboard
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={() => {
                                    dispatch(projectDetailsActions.setDeleteProjectModalOpen());
                                }}
                            >
                                Delete Project
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={() => {
                                    dispatch(collectionsActions.setAddSnapshotModalOpen());
                                }}
                            >
                                Add Snapshot
                            </Button>
                        </Stack>
                    </Stack>
                    <Stack direction="row" columnGap={3}>
                        <Card elevation={0} sx={{ ...style, width: '60%', minHeight: '500px', maxHeight: '500px' }}>
                            <Box margin={2}>
                                <Stack rowGap={3}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6" sx={{ color: theme.palette.active.main }}>{projectDetails.collection_name}</Typography>
                                        <Button size="large" color="active" onClick={() => {
                                            dispatch(projectDetailsActions.setEditRoleModalOpen())
                                        }}>Edit Roles</Button>
                                    </Stack>
                                    <Stack rowGap={1}>
                                        {
                                            projectDetails?.contributors?.map((contributor) => {
                                                return (
                                                    <Stack direction="row" columnGap={1} key={contributor.user_id} alignItems="center">
                                                        <Typography sx={{ fontWeight: 300 }}>{contributor.user_name}</Typography>
                                                        {contributor.PM && <Chip label={"PM"} color="active" />}
                                                        {contributor.FD === 1 && <Chip label={"FD"} color="warning" />}
                                                        {contributor.BD === 1 && <Chip label={"BD"} color="warning" />}
                                                        {contributor.QA === 1 && <Chip label={"QA"} color="success" />}
                                                        {contributor.UX === 1 && <Chip label={"UX"} color="success" />}
                                                    </Stack>
                                                )
                                            })
                                        }
                                    </Stack>
                                </Stack>
                            </Box>
                        </Card>
                        <Card elevation={0} sx={{ ...style, width: '60%', minHeight: '500px', maxHeight: '500px' }}>
                            <Box margin={2}>
                                <Stack rowGap={3}>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center">
                                        <Typography variant="h6" sx={{ color: theme.palette.active.main }}>Snapshots</Typography>
                                    </Stack>
                                    <Stack rowGap={1}>
                                        {
                                            projectDetails?.resultSnapshots?.map((snapshot, i) => {
                                                return (
                                                    <Stack
                                                        direction="row"
                                                        key={snapshot.snapshot_date + `${i}`}
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <Typography sx={{ fontWeight: 300, width: '50%' }}>
                                                            {moment(snapshot.snapshot_date).format('DD-MMM-YYYY')}
                                                        </Typography>
                                                        {snapshot.status === "available" &&
                                                            <FormControlLabel
                                                                value={snapshot.reference === null ? '' : snapshot.reference}
                                                                control={<Checkbox
                                                                    size="small"
                                                                    checked={snapshot.reference === null ? false : Boolean(snapshot.reference)}
                                                                    onChange={(e) => {
                                                                        referenceUpdateHandler(snapshot.snapshot_id, e.target.checked);
                                                                    }}
                                                                />}
                                                                label={<Typography sx={{ fontWeight: 300 }}>Reference</Typography>}
                                                                labelPlacement="start"
                                                            />}

                                                        {(snapshot.status === "available" && <DeleteIcon onClick={() => dispatch(projectDetailsActions.setDeleteSnapshotModalOpen({ snapshotId: snapshot.snapshot_id }))} sx={{ cursor: 'pointer' }} />) || <Chip label={snapshot.status || 'missing'} color="error" />}
                                                    </Stack>
                                                )
                                            })
                                        }
                                    </Stack>
                                </Stack>
                            </Box>
                        </Card>
                        <Stack sx={{ width: '40%' }} rowGap={3}>
                            <Card elevation={0} sx={{ ...style, height: '180px' }}>
                                <Box sx={{ margin: 2 }}>
                                    <Stack>
                                        <Stack direction="row" justifyContent="flex-end">
                                            <Tooltip arrow placement="left"
                                                title={
                                                    <Stack sx={{ padding: 1 }}>
                                                        <Typography variant="caption">
                                                            If a project does not have a snapshot in a given week, then this metric drops. A week starts on Mondays and finishes on Sundays. Hence, this metric refreshes each Monday morning at 12:00:01 AM.
                                                        </Typography><br></br>
                                                        <Typography variant="subtitle1">
                                                            Metric calculation logic:
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            Suppose today is Wednesday. This metric will not consider the snapshot uploaded on Monday, because the week is ongoing. However, if snapshots in prior weeks are missing, then the metric will be below 100%.
                                                        </Typography>
                                                        <br></br>
                                                        <Typography variant="caption">
                                                            The system expects a final snapshot the week after the project is complete.
                                                        </Typography>
                                                    </Stack>
                                                }
                                            >
                                                <InfoIcon
                                                    color="active"
                                                    sx={{ width: '25px', height: '25px', cursor: 'pointer' }}
                                                />
                                            </Tooltip>
                                        </Stack>
                                        <Typography variant="h3" sx={{ fontWeight: 500 }}>{Math.trunc(projectDetails.snapshot_coverage)}%</Typography>
                                        <Typography variant="subtitle" sx={{ fontWeight: 300 }}>Snapshot Coverage</Typography>
                                    </Stack>
                                </Box>
                            </Card>
                            {!projectDetails.project_end && <Stack direction="row" spacing={1} alignItems="center">
                                <Typography sx={{ fontWeight: 400 }}>Project Ongoing</Typography>
                                <Switch checked={projectCompletionModal}
                                    onChange={(e) => dispatch(projectDetailsActions.setProjectCompletionModalOpen())}
                                />
                                <Typography sx={{ fontWeight: 40 }}>Project Over</Typography>
                            </Stack>}
                            {
                                projectDetails.project_end &&
                                <Typography sx={{ fontWeight: 350 }}>
                                    Project ended on {moment(projectDetails.project_end).format('DD-MMM-YYYY')}
                                </Typography>
                            }
                        </Stack>
                    </Stack>
                </Box>

                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert severity={snackbar.type} sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
                <Backdrop
                    // sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    sx={{
                        zIndex: (theme) =>
                            Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
                    }}
                    open={loader}
                >
                    <CircularProgress color="secondary" />
                </Backdrop>
                <AddSnapshotModal />
                <DeleteProjectModal />
                <EditRoleModal />
                <ProjectCompletionModal />
                <DeleteSnapshotModal />
            </>
            }
        </>
    );
}

export default ProjectDetails;