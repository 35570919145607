import { useTheme } from "@emotion/react";
import { MenuItem, Typography, TextField } from "@mui/material";
import { fetchIssuesData } from '../../services/githubServices';
import { useDispatch, useSelector } from "react-redux";
import { githubActions } from "../../store";
import { useNavigate } from "react-router-dom";

const Months = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedProjectId = useSelector((state) => state.github.selectedProjectId);
    const selectedContributor = useSelector((state) => state.github.selectedContributor);
    const months = useSelector((state) => state.github.months);
    const selectedMonth = useSelector((state) => state.github.selectedMonth);;

    const getIssues = async (month) => {
        try {
            dispatch(githubActions.setLoader({ value: true }));
            const issuesResponse = await fetchIssuesData(selectedContributor, selectedProjectId.project_uid, month);
            dispatch(githubActions.setIssues({ issues: issuesResponse?.data?.data }));
            dispatch(githubActions.setLoader({ value: false }));
            dispatch(githubActions.setSnackbar({ value: { open: true, type: 'success', message: 'Data Fetched' } }));
        }
        catch (error) {
            dispatch(githubActions.setIssues({ issues: null }));
            dispatch(githubActions.setLoader({ value: false }));
            console.log(error.response);
            dispatch(githubActions.setSnackbar({ value: { open: true, type: 'error', message: error?.response?.data?.msg || error?.response?.statusText || error.toString() } }));
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        }
    }

    return (
        <TextField id="month" label="month" required variant="filled" select
            value={selectedMonth}
            SelectProps={{ MenuProps: { sx: { maxHeight: 400 } } }} sx={{ width: '20%', svg: { color: theme.palette.svg.main } }}
            InputProps={{ style: { backgroundColor: '#FFFFFF' } }}
            onChange={(e) => {
                dispatch(githubActions.setMonth({ month: e.target.value }));
                getIssues(e.target.value)
            }}
        >
            {
                months.map((month, i) => {
                    return (<MenuItem
                        sx={{ fontWeight: 300, padding: 1.5 }}
                        key={month.key}
                        value={month.value}
                    >
                        <Typography sx={{ fontWeight: 350 }}>{month.month_name}</Typography>
                    </MenuItem>)
                })
            }
        </TextField>
    );
}

export default Months;